import React from "react";
import {
  Clock,
  AlertCircle,
  CheckCircle2,
  CalendarClock,
  ChevronRight
} from "lucide-react";

const isValidArray = arr => Array.isArray(arr) && arr.length > 0;
const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const safeString = str => isValidString(str) ? str : '';
const safeReplace = str => isValidString(str) ? str.replace('_', ' ') : '';

const RecommendationSection = ({ title, items, icon: Icon, urgencyColor }) => {
  if (!isValidArray(items) || !Icon || !isValidString(title)) return null;

  const safeColor = isValidString(urgencyColor) ? urgencyColor : 'bg-base-200';

  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2">
        <div className={`p-2 rounded-lg ${safeColor}`}>
          <Icon className="w-4 h-4" />
        </div>
        <h3 className="font-semibold">{safeString(title)}</h3>
      </div>
      <div className="grid gap-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="group flex items-start gap-3 p-3 rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors"
          >
            <ChevronRight className="w-4 h-4 mt-0.5 text-base-content/60 group-hover:text-primary transition-colors" />
            <span className="text-sm leading-relaxed">{safeString(item)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ActionsResultRecommendations = ({ recommendations }) => {
  if (!recommendations || typeof recommendations !== 'object') {
    return null;
  }

  const sections = [
    {
      key: 'immediate',
      title: 'Immediate Actions',
      icon: AlertCircle,
      urgencyColor: 'bg-error/10',
      description: 'Tasks that require immediate attention'
    },
    {
      key: 'short_term',
      title: 'Short-term Tasks',
      icon: Clock,
      urgencyColor: 'bg-warning/10',
      description: 'Actions to be completed in the near future'
    },
    {
      key: 'long_term',
      title: 'Long-term Goals',
      icon: CalendarClock,
      urgencyColor: 'bg-success/10',
      description: 'Strategic objectives for future implementation'
    }
  ];

  const hasRecommendations = sections.some(
    section => isValidArray(recommendations[section.key])
  );

  if (!hasRecommendations) return null;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-8">
        <div className="flex items-center gap-2">
          <CheckCircle2 className="w-5 h-5 text-primary" />
          <h2 className="text-lg font-semibold">Recommendations</h2>
        </div>

        <div className="grid gap-8">
          {sections.map((section) => {
            const sectionItems = recommendations[section.key];
            return isValidArray(sectionItems) && (
              <div key={section.key} className="space-y-2">
                <RecommendationSection
                  title={section.title}
                  items={sectionItems}
                  icon={section.icon}
                  urgencyColor={section.urgencyColor}
                />
                <p className="text-sm text-base-content/60 ml-10">
                  {safeString(section.description)}
                </p>
              </div>
            );
          })}
        </div>

        <div className="pt-4 border-t border-base-200">
          <div className="flex gap-4 flex-wrap">
            {sections.map(section => {
              const sectionItems = recommendations[section.key];
              return isValidArray(sectionItems) && (
                <div
                  key={section.key}
                  className={`flex items-center gap-2 px-3 py-1.5 rounded-lg ${section.urgencyColor}`}
                >
                  <section.icon className="w-4 h-4" />
                  <span className="text-sm font-medium">
                    {sectionItems.length} {safeReplace(section.key)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsResultRecommendations;
