import React from 'react';
import { Bot, Server, Cpu } from 'lucide-react';

const AI_AGENTS = {
  call_groq_llama_70b_text: {
    company: 'Groq',
    model: 'LLAMA 70B TEXT'
  },
  call_groq_llama_8b_8k: {
    company: 'Groq',
    model: 'LLAMA 8B 8K'
  },
  call_groq_llama_70b_text_specdec: {
    company: 'Groq',
    model: 'LLAMA 70B TEXT SPECDEC'
  },
  call_groq_gemma_google_9b: {
    company: 'Groq',
    model: 'GEMMA GOOGLE 9B'
  },
  call_groq_mistral_8x_7b: {
    company: 'Groq',
    model: 'MISTRAL 8X 7B'
  },
  call_cerebras_70b: {
    company: 'Cerebras',
    model: '70B'
  },
  call_cerebras_8b: {
    company: 'Cerebras',
    model: '8B'
  },
  call_openai_gp4_mini: {
    company: 'OpenAI',
    model: 'GPT-4 MINI'
  },
  call_deepinfra_qwen_2_5_72b: {
    company: 'DeepInfra',
    model: 'QWEN 2.5 72B'
  },
  call_deepinfra_llama_3_1_8b: {
    company: 'DeepInfra',
    model: 'LLAMA 3.1 8B'
  },
  call_deepinfra_mistral_8_7b: {
    company: 'DeepInfra',
    model: 'MISTRAL 8.7B'
  },
  call_gemini_pro_1_5: {
    company: 'Gemini',
    model: 'PRO 1.5'
  },
  call_mistral_large: {
    company: 'Mistral',
    model: 'LARGE'
  },
  call_mistral_small: {
    company: 'Mistral',
    model: 'SMALL'
  },
  call_mistral_nemo: {
    company: 'Mistral',
    model: 'NEMO'
  },
  call_openai_gp4: {
    company: 'OpenAI',
    model: 'GPT-4'
  },
  call_hyperbolic_qwen_32b_instruct: {
    company: 'Hyperbolic',
    model: 'QWEN 32B INSTRUCT'
  },
  call_hyperbolic_qwen_72b_instruct: {
    company: 'Hyperbolic',
    model: 'QWEN 72B INSTRUCT'
  },
  call_hyperbolic_llama_3_70b_instruct: {
    company: 'Hyperbolic',
    model: 'LLAMA 3.70B INSTRUCT'
  },
  call_hyperbolic_llama_3_1_70b_instruct: {
    company: 'Hyperbolic',
    model: 'LLAMA 3.1.70B INSTRUCT'
  },
  call_hyperbolic_llama_3_1_8b_instruct: {
    company: 'Hyperbolic',
    model: 'LLAMA 3.1.8B INSTRUCT'
  }
};

const getCompanyColor = (company) => {
  const colors = {
    'Groq': 'text-primary',
    'Cerebras': 'text-secondary',
    'OpenAI': 'text-success',
    'DeepInfra': 'text-warning',
    'Gemini': 'text-info',
    'Mistral': 'text-accent',
    'Hyperbolic': 'text-info'
  };
  return colors[company] || 'text-neutral';
};

const AIAgentCard = ({ agent_name }) => {
  const agentInfo = AI_AGENTS[agent_name];

  if (!agentInfo) {
    return (
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <div className="text-error">{agent_name}</div>
        </div>
      </div>
    );
  }

  const { company, model } = agentInfo;
  const companyColor = getCompanyColor(company);

  return (
    <div className="card w-fit bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-200 border border-primary">
      <div className="card-body">
        <div className="flex items-start gap-2">
          <Bot className="w-8 h-8 text-base-content opacity-70" />
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <Server className={`w-4 h-4 ${companyColor}`} />
              <h3 className={`font-bold ${companyColor}`}>{company}</h3>
            </div>
            <div className="flex items-center mt-2 gap-2">
              <Cpu className="w-4 h-4 text-base-content opacity-70" />
              <p className="text-base-content">{model}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIAgentCard;
