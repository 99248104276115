import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { X } from 'lucide-react';
import utils from '../../../utils'

const FullTextSearch = ({ onSearch, results, project }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const total = results?.length || 0;
  const isInvalid = searchTerm.length > 0 && searchTerm.length < 3;
  const currentUser = useAuth();
  const { formatDate } = utils;
  const handleOnSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  }

  const handleReset = () => {
    setSearchTerm('');
    onSearch('');
  }

  const highlightText = (text, highlight) => {
    if (!highlight.trim() || highlight.length < 3) {
      return text;
    }

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="bg-yellow-400 underline underline-offset-1">{part}</span>
      ) : (
        part
      )
    );
  }

  return (
    <div className="space-y-4">
      <div className="card bg-base-100 shadow-xl p-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search for anything"
            className={`input input-bordered w-full pr-10 ${isInvalid ? 'input-error' : ''}`}
            value={searchTerm}
            onChange={handleOnSearch}
          />
          {searchTerm && (
            <button
              className="btn btn-xs btn-error absolute right-3 top-1/2 -translate-y-1/2"
              onClick={handleReset}>
              Cancel
              <X className="h-4 w-4" />
            </button>
          )}
        </div>
        <div className="text-right mt-4">
          <span className="text-base-content/70">Total: {total}</span>
        </div>
      </div>

      {results && results?.length > 0 && (
        <div className="overflow-x-auto">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>View</th>
                <th>Content</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {results?.map((result) => (
                <tr key={result.id}>
                  <td>
                    <a
                      href={`/users/${currentUser?.id}/projects/${project?.id}/contents/${result?.searchable_id}`}
                      className="link link-primary"
                    >
                      View
                    </a>
                  </td>
                  <td className="max-w-xl">
                    <div className="text-sm">
                      {highlightText(result.content, searchTerm)}
                    </div>
                  </td>
                  <td>{formatDate(result?.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default FullTextSearch;
