import React from "react";
import {
  BarChart2, Building2, Users, MapPin, Calendar, Tag, Phone, ChartPie, Info
} from "lucide-react";

const CategoryIcon = ({ category }) => {
  const icons = {
    organizations: Building2,
    people: Users,
    locations: MapPin,
    dates: Calendar,
    identifiers: Tag,
    contact_info: Phone
  };
  const Icon = icons[category] || Info;
  return <Icon className="w-4 h-4" />;
};

const ConfidenceBar = ({ value, label }) => {
  if (!value && value !== 0) return (
    <div className="space-y-1">
      <div className="flex justify-between text-sm">
        <span className="text-base-content/60">{label}</span>
        <span className="text-base-content/40">No data</span>
      </div>
      <div className="h-2 bg-base-200 rounded-full" />
    </div>
  );

  const getConfidenceColor = (score) => {
    if (score >= 0.9) return "bg-success";
    if (score >= 0.7) return "bg-warning";
    return "bg-error";
  };

  return (
    <div className="space-y-1">
      <div className="flex justify-between text-sm">
        <span className="text-base-content/60">{label}</span>
        <span className="font-medium">{(value * 100).toFixed(0)}%</span>
      </div>
      <div className="h-2 bg-base-200 rounded-full overflow-hidden">
        <div
          className={`h-full rounded-full transition-all ${getConfidenceColor(value)}`}
          style={{ width: `${Math.max(0, Math.min(100, value * 100))}%` }}
        />
      </div>
    </div>
  );
};

const CategoryStat = ({ category, count = 0, total = 0, confidence }) => {
  if (typeof count !== 'number' || typeof total !== 'number') return null;

  const percentage = total > 0 ? (count / total) * 100 : 0;
  const safePercentage = Math.max(0, Math.min(100, percentage));

  return (
    <div className="p-3 rounded-lg bg-base-200/50">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2">
          <div className="p-1.5 rounded-md bg-primary/10">
            <CategoryIcon category={category} />
          </div>
          <span className="text-sm font-medium capitalize">
            {category.replace(/_/g, ' ')}
          </span>
        </div>
        <span className="text-xs px-2 py-1 rounded-full bg-primary/10">
          {count} found
        </span>
      </div>
      <div className="space-y-2">
        <div className="space-y-1">
          <div className="flex justify-between text-xs">
            <span className="text-base-content/60">Distribution</span>
            <span>{safePercentage.toFixed(1)}%</span>
          </div>
          <div className="h-1.5 bg-base-200 rounded-full overflow-hidden">
            <div
              className="h-full bg-primary rounded-full transition-all"
              style={{ width: `${safePercentage}%` }}
            />
          </div>
        </div>
        <div className="text-xs">
          <ConfidenceBar
            value={confidence}
            label="Confidence"
          />
        </div>
      </div>
    </div>
  );
};

const EntitiesResultMetadata = ({ metadata }) => {
  if (!metadata?.entity_counts?.total || !metadata?.confidence_summary) return null;

  const categories = [
    'organizations',
    'people',
    'locations',
    'dates',
    'identifiers',
    'contact_info'
  ];

  const { entity_counts, confidence_summary } = metadata;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <ChartPie className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Entity Statistics</h2>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4 p-4 rounded-lg bg-base-200/50">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <Tag className="w-4 h-4" />
              <span className="font-medium">Total Entities</span>
            </div>
            <div className="text-3xl font-bold">
              {entity_counts.total}
              <span className="text-base font-normal text-base-content/60 ml-2">found</span>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <BarChart2 className="w-4 h-4" />
              <span className="font-medium">Average Confidence</span>
            </div>
            <ConfidenceBar
              value={confidence_summary.average}
              label="Overall"
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {categories.map(category => (
            <CategoryStat
              key={category}
              category={category}
              count={entity_counts.by_category[category] || 0}
              total={entity_counts.total}
              confidence={confidence_summary.by_category[category]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EntitiesResultMetadata;
