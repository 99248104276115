import React, { useRef, useEffect } from 'react';
import { CircleChevronDown } from 'lucide-react';
import SelectionActions from './SelectionActions';
import { useTextSelection } from '../contexts/TextSelectionContext';

const GlobalTextSelectionPopup = ({ project, onAction }) => {
  const { text, position, isVisible, isDropdownOpen, toggleDropdown } = useTextSelection();
  const dropdownRef = useRef(null);
  const actions = SelectionActions(project);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (isDropdownOpen) toggleDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDropdownOpen, toggleDropdown]);

  if (!isVisible) return null;

  const handleAction = (e, action) => {
    if (text.trim() === '') return;
    if (text.length <= 100) return;
    e.stopPropagation();
    action(text);
    toggleDropdown();
    onAction(action, text);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (text.trim() === '') return;
    if (text.length <= 50) return;
    toggleDropdown();
  };

  const getDropdownStyles = () => {
    const viewportHeight = window.innerHeight;
    const dropdownHeight = 450;

    const spaceBelow = viewportHeight - position.y;
    const spaceAbove = position.y;

    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      return {
        bottom: '100%',
        marginBottom: '0.5rem',
        left: '100%',
        marginLeft: '0.5rem',
        maxHeight: `${Math.min(spaceAbove - 20, dropdownHeight)}px`
      };
    }

    return {
      top: '0',
      left: '100%',
      marginLeft: '0.5rem',
      maxHeight: `${Math.min(spaceBelow - 20, dropdownHeight)}px`
    };
  };

  return (
    <div
      ref={dropdownRef}
      className="fixed z-[9999]"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        disabled={text.length <= 50}
        onClick={handleButtonClick}
        className={`bg-white shadow-lg rounded-lg p-2
             ${text.length <= 50 ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50'}
             transition-colors duration-200`}
        aria-label="Text Analysis Options"
      >
        <div className="tooltip tooltip-right" data-tip={text.length <= 50
          ? "Please select more than 50 characters"
          : `Analyze ${text.length} characters`}
        >
          <CircleChevronDown className={`w-5 h-5 ${text.length <= 50 ? 'text-gray-400' : 'text-blue-600'}`} />
        </div>
      </button >

      {
        isDropdownOpen && (
          <div
            className="absolute w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-auto"
            style={getDropdownStyles()}
            onClick={(e) => e.stopPropagation()}
            role="menu"
            aria-orientation="vertical"
          >
            <div className="py-1">
              {actions.map(({ id, Icon, label, color, ariaLabel, action, disabled }) => (
                <button
                  key={id}
                  onClick={(e) => handleAction(e, action)}
                  className={`w-full text-left px-4 py-2 text-sm flex items-center gap-2 transition-colors duration-150
                          ${disabled
                      ? 'cursor-not-allowed opacity-50 bg-gray-100'
                      : 'text-gray-700 hover:bg-gray-50 cursor-pointer'}`}
                  role="menuitem"
                  aria-label={ariaLabel}
                  disabled={disabled}
                >
                  <Icon className={`w-4 h-4 ${disabled ? 'text-gray-400' : color}`} />
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>
        )
      }
    </div >
  );
};

export default GlobalTextSelectionPopup;
