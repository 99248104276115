import React from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';

const ConfidenceIndicator = ({ confidence }) => {
  const safeConfidence = typeof confidence === 'number' && !isNaN(confidence)
    ? Math.max(0, Math.min(100, confidence))
    : 0;

  const isHigh = safeConfidence >= 90;
  const colorClass = isHigh ? 'text-success' : 'text-warning';

  return (
    <div className="flex items-center gap-2">
      {isHigh ? (
        <CheckCircle className={`h-4 w-4 ${colorClass}`} />
      ) : (
        <AlertCircle className={`h-4 w-4 ${colorClass}`} />
      )}
      <span className={`text-sm ${colorClass}`}>
        {safeConfidence}%
      </span>
    </div>
  );
};

export default ConfidenceIndicator;
