import { Controller } from '@hotwired/stimulus'
import { createRoot } from 'react-dom/client'
import React from 'react'
import App from './views/App'

let isReactMounted = false
let root = null

export default class extends Controller {
  static values = {
    props: Object
  }


  connect() {
    if (!isReactMounted) {
      console.log('Mounting React app')
      root = createRoot(this.element)
      root.render(<App {...this.propsValue} />)
      isReactMounted = true
    } else {
      console.log('Updating React app props')
      root.render(<App {...this.propsValue} />)
    }
  }

  disconnect() {
    if (this.element.isConnected === false) {
      root?.unmount()
      isReactMounted = false
      root = null
      console.log('React app fully unmounted')
    }
  }
}
