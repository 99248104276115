import React, { useState } from "react";
import {
  AlertTriangle,
  AlertCircle,
  Layers,
  MapPin,
  Target,
  ChevronDown,
  ChevronUp,
  AlertOctagon,
  Info
} from "lucide-react";

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidNumber = num => typeof num === 'number' && !isNaN(num) && num >= 0 && num <= 100;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);
const isValidArray = arr => Array.isArray(arr) && arr.length > 0;

const getSeverityColor = (severity) => {
  if (!isValidString(severity)) return "bg-gray-100 text-gray-800 border-gray-200";

  const colors = {
    critical: "bg-red-100 text-red-800 border-red-200",
    high: "bg-orange-100 text-orange-800 border-orange-200",
    medium: "bg-yellow-100 text-yellow-800 border-yellow-200",
    low: "bg-blue-100 text-blue-800 border-blue-200"
  };
  return colors[severity.toLowerCase()] || "bg-gray-100 text-gray-800 border-gray-200";
};

const getTypeIcon = (type) => {
  if (!isValidString(type)) return Info;

  const icons = {
    content: AlertCircle,
    contextual: Layers,
    statistical: Target,
    behavioral: AlertOctagon
  };
  return icons[type.toLowerCase()] || Info;
};

const ConfidenceBar = ({ confidence }) => {
  const safeConfidence = isValidNumber(confidence) ? confidence : 0;

  return (
    <div className="w-full bg-base-200 rounded-full h-2 mt-1">
      <div
        className="bg-primary h-full rounded-full transition-all"
        style={{ width: `${safeConfidence}%` }}
      />
    </div>
  );
};

const Finding = ({ finding }) => {
  if (!isValidObject(finding)) return null;

  const [isExpanded, setIsExpanded] = useState(false);
  const TypeIcon = getTypeIcon(finding.type);

  const {
    id = 'N/A',
    severity = 'unknown',
    description = 'No description available',
    confidence = 0,
    location = {},
    impact = { description: 'No impact information', affected_areas: [] }
  } = finding;

  const safeLocation = {
    text: location?.text || 'Unknown location',
    position: location?.position || 'N/A'
  };

  const safeAffectedAreas = isValidArray(impact.affected_areas)
    ? impact.affected_areas
    : [];

  return (
    <div className="rounded-lg border bg-base-100 shadow-sm">
      <div className="p-4 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="flex items-start justify-between gap-4">
          <div className="flex items-start gap-3 flex-grow">
            <div className={`p-2 rounded-lg ${getSeverityColor(severity)}`}>
              <TypeIcon className="w-4 h-4" />
            </div>
            <div className="space-y-1 flex-grow">
              <div className="flex items-center gap-2">
                <span className="text-sm text-base-content/60">#{id}</span>
                <span className={`px-2 py-0.5 rounded text-xs font-medium ${getSeverityColor(severity)}`}>
                  {severity}
                </span>
              </div>
              <p className="font-medium">{description}</p>
            </div>
          </div>
          {isExpanded ? (
            <ChevronUp className="w-4 h-4 text-base-content/60" />
          ) : (
            <ChevronDown className="w-4 h-4 text-base-content/60" />
          )}
        </div>

        <div className="mt-4 space-y-1">
          <div className="flex justify-between items-center">
            <span className="text-xs text-base-content/60">Confidence</span>
            <span className="text-xs font-medium">{confidence}%</span>
          </div>
          <ConfidenceBar confidence={confidence} />
        </div>
      </div>

      {isExpanded && (
        <div className="px-4 pb-4 pt-2 border-t border-base-200">
          <div className="grid gap-4">
            <div className="flex items-start gap-2">
              <MapPin className="w-4 h-4 text-base-content/60 mt-1" />
              <div>
                <p className="text-sm font-medium">Location</p>
                <p className="text-sm text-base-content/60">
                  {safeLocation.text} ({safeLocation.position})
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              <AlertTriangle className="w-4 h-4 text-base-content/60 mt-1" />
              <div className="space-y-2">
                <div>
                  <p className="text-sm font-medium">Impact</p>
                  <p className="text-sm text-base-content/60">{impact.description}</p>
                </div>
                {safeAffectedAreas.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {safeAffectedAreas.map((area, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 text-xs rounded-full bg-base-200"
                      >
                        {String(area)}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AnomaliesResultAnalysis = ({ analysis }) => {
  if (!isValidObject(analysis) || !isValidArray(analysis.findings)) return null;

  const groupedFindings = analysis.findings.reduce((acc, finding) => {
    if (!isValidObject(finding)) return acc;
    const severity = isValidString(finding.severity) ? finding.severity : 'unknown';
    acc[severity] = acc[severity] || [];
    acc[severity].push(finding);
    return acc;
  }, {});

  const severityOrder = ['critical', 'high', 'medium', 'low'];

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <AlertTriangle className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Anomaly Analysis</h2>
          </div>
          <div className="flex gap-2">
            {severityOrder.map(severity => (
              isValidArray(groupedFindings[severity]) && (
                <span
                  key={severity}
                  className={`px-3 py-1 rounded-lg text-xs font-medium ${getSeverityColor(severity)}`}
                >
                  {severity}: {groupedFindings[severity].length}
                </span>
              )
            ))}
          </div>
        </div>

        <div className="grid gap-4">
          {severityOrder.map(severity => (
            isValidArray(groupedFindings[severity]) &&
            groupedFindings[severity].map(finding => (
              <Finding key={finding.id || Math.random()} finding={finding} />
            ))
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnomaliesResultAnalysis;
