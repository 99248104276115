import React from "react";
import ActionsResultActions from "./ActionsResultActions";
import ActionsResultRecommendations from "./ActionsResultRecommendations";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import AIAgentCard from "../../common/AIAgentCard";

const ActionsResult = ({ actionableInsight, onRerun }) => {
  const { agent_name, result: { actions, recommendations } } = actionableInsight;
  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('actions')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(actionableInsight)} copyKey={'actions'} />
        </div>
      </div>

      <div className="flex gap-6 justify-start w-full flex-col">
        <ActionsResultActions actions={actions} />
        <ActionsResultRecommendations recommendations={recommendations} />
      </div>
    </div>
  );
};

export default ActionsResult;
