import { AudioLines, FilePlus, PlusCircle } from 'lucide-react'
import React from 'react'

const EmptyState = ({addContent, addFile, addAudio}) => {
  return (
    <div className="flex flex-col items-center justify-center py-16 px-4">
      <h3 className="text-2xl text-blue-500 font-medium mb-8">
        You have no content added yet
      </h3>
      <div className="flex flex-wrap justify-center gap-4">
        <button 
          onClick={addContent}
        className="btn btn-success gap-2">
          <PlusCircle className="h-5 w-5"/>
          Add Content
        </button>
        <button 
          onClick={addFile}
          className="btn btn-success gap-2">
          <FilePlus className="h-5 w-5"/>
          Add File
        </button>
        <button 
          onClick={addAudio}
          className="btn btn-success gap-2">
          <AudioLines className="h-5 w-5"/>
          Add Audio File
        </button>
      </div>
    </div>
  )
}

export default EmptyState
