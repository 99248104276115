import React from "react";
import {
  AlertTriangle,
  Clock,
  CalendarClock,
  CheckCircle2,
  ArrowRight,
  ShieldAlert
} from "lucide-react";

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidArray = arr => Array.isArray(arr) && arr.length > 0;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

const formatText = (text) => {
  if (!isValidString(text)) return '';
  return text.split('_').join(' ');
};

const RecommendationSection = ({ title, icon: Icon, items, urgencyColor, description }) => {
  if (!Icon || !isValidArray(items)) return null;

  const safeColor = isValidString(urgencyColor) ? urgencyColor : 'bg-base-200 text-base-content';
  const safeTitle = isValidString(title) ? title : 'Recommendations';
  const safeDescription = isValidString(description) ? description : '';

  return (
    <div className="rounded-lg border bg-base-100 shadow-sm p-4">
      <div className="space-y-4">
        <div className="flex items-start gap-3">
          <div className={`p-2 rounded-lg ${safeColor}`}>
            <Icon className="w-4 h-4" />
          </div>
          <div>
            <h3 className="font-medium">{safeTitle}</h3>
            {safeDescription && (
              <p className="text-sm text-base-content/60">{safeDescription}</p>
            )}
          </div>
        </div>

        <div className="pl-11 space-y-2">
          {items.map((item, index) => (
            <div
              key={`${safeTitle}-${index}`}
              className="group flex items-start gap-2 p-3 rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors"
            >
              <ArrowRight className="w-4 h-4 mt-0.5 text-base-content/60 group-hover:text-primary transition-colors" />
              <span className="text-sm">{String(item)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const AnomaliesResultRecommendations = ({ recommendations }) => {
  if (!isValidObject(recommendations)) return null;

  const sections = [
    {
      key: 'immediate',
      title: 'Immediate Actions Required',
      icon: AlertTriangle,
      urgencyColor: 'bg-error/10 text-error',
      description: 'Address these issues as soon as possible to mitigate risks'
    },
    {
      key: 'short_term',
      title: 'Short-term Recommendations',
      icon: Clock,
      urgencyColor: 'bg-warning/10 text-warning',
      description: 'Implement these changes in the coming days or weeks'
    },
    {
      key: 'long_term',
      title: 'Long-term Preventive Measures',
      icon: CalendarClock,
      urgencyColor: 'bg-success/10 text-success',
      description: 'Strategic changes to prevent future anomalies'
    }
  ];

  const hasRecommendations = sections.some(
    section => isValidArray(recommendations[section.key])
  );

  if (!hasRecommendations) return null;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <ShieldAlert className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Recommended Actions</h2>
          </div>

          <div className="flex gap-2">
            {sections.map(section => {
              const sectionItems = recommendations[section.key];
              return isValidArray(sectionItems) && (
                <span
                  key={section.key}
                  className={`px-3 py-1 rounded-lg text-xs font-medium ${section.urgencyColor}`}
                >
                  {formatText(section.key)}: {sectionItems.length}
                </span>
              );
            })}
          </div>
        </div>

        <div className="grid gap-4">
          {sections.map((section) => (
            <RecommendationSection
              key={section.key}
              title={section.title}
              icon={section.icon}
              items={recommendations[section.key]}
              urgencyColor={section.urgencyColor}
              description={section.description}
            />
          ))}
        </div>

        <div className="flex items-center gap-2 p-4 rounded-lg bg-primary/5">
          <CheckCircle2 className="w-4 h-4 text-primary" />
          <p className="text-sm text-base-content/80">
            Following these recommendations will help address detected anomalies and prevent future issues.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnomaliesResultRecommendations;
