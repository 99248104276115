import React, { useState, useMemo } from 'react';
import {
  Zap, Cpu, Brain, Database, Gauge, Check,
  Rocket, Crown, Building, Star, Shield, Headphones
} from 'lucide-react';

const Plans = ({ newUserCheckoutsPath }) => {
  const [billingPeriod, setBillingPeriod] = useState('monthly');

  const plans = useMemo(() => [
    {
      id: 'free',
      name: 'Free',
      icon: Zap,
      iconColor: 'text-gray-400',
      price: {
        monthly: 0,
        yearly: 0
      },
      features: {
        ai: ["has_tags", "has_summary"],
        limits: ["1 Project", "50 Contents"]
      },
      buttonText: "Get Started Free",
      buttonColor: "bg-blue-600 hover:bg-blue-700"
    },
    {
      id: 'startup',
      name: 'Startup',
      icon: Rocket,
      iconColor: 'text-blue-500',
      stripe: {
        productId: 'prod_REeDutXslU2JL8',
        monthlyPriceId: 'price_1QMAt6ClnNSqQwa3LftXZR0B',
        yearlyPriceId: 'price_1QMAvzClnNSqQwa3ifb4tJfe'
      },
      price: {
        monthly: 90,
        yearly: 700
      },
      features: {
        ai: [
          "has_tags",
          "has_summary",
          "has_sentiment",
          "has_category",
          "has_named_entity_recognition",
          "has_duplicate_detection"
        ],
        data: [
          "has_js_access",
          "has_public_access"
        ],
        limits: [
          "4 Projects",
          "1,000 Contents"
        ]
      },
      buttonText: "Upgrade to Startup",
      buttonColor: "bg-blue-600 hover:bg-blue-700"
    },
    {
      id: 'professional',
      name: 'Professional',
      icon: Crown,
      iconColor: 'text-yellow-500',
      stripe: {
        productId: 'prod_REeB8EPR26knUr',
        monthlyPriceId: 'price_1QMAt6ClnNSqQwa3LftXZR0B',
        yearlyPriceId: 'price_1QMAt6ClnNSqQwa3qocx6Ba0'
      },
      price: {
        monthly: 290,
        yearly: 2700
      },
      isPopular: true,
      features: {
        ai: [
          "has_tags",
          "has_summary",
          "has_sentiment",
          "has_category",
          "has_metadata_extractor",
          "has_named_entity_recognition",
          "has_actionable_insights",
          "has_anomaly_detector",
          "has_date_extraction",
          "has_pii_detection",
          "has_duplicate_detection"
        ],
        data: [
          "has_api_access",
          "has_public_access",
          "has_email_access",
          "has_js_access"
        ],
        limits: [
          "10 Projects",
          "5,000 Contents"
        ]
      },
      buttonText: "Upgrade to Pro",
      buttonColor: "bg-blue-600 hover:bg-blue-700"
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      icon: Building,
      iconColor: 'text-purple-500',
      price: {
        monthly: 'Custom',
        yearly: 'Custom'
      },
      features: {
        ai: [
          "has_tags",
          "has_summary",
          "has_sentiment",
          "has_category",
          "has_metadata_extractor",
          "has_named_entity_recognition",
          "has_actionable_insights",
          "has_anomaly_detector",
          "has_date_extraction",
          "has_pii_detection",
          "has_duplicate_detection"
        ],
        data: [
          "has_api_access",
          "has_public_access",
          "has_email_access",
          "has_js_access"
        ],
        limits: [
          "100 Projects",
          "10,000 Contents"
        ]
      },
      buttonText: "Contact Sales",
      buttonColor: "bg-purple-600 hover:bg-purple-700"
    }
  ], []);

  const calculateSavings = (plan) => {
    if (typeof plan.price.monthly !== 'number') return null;
    const yearlyTotal = plan.price.yearly;
    const monthlyTotal = plan.price.monthly * 12;
    return Math.round((monthlyTotal - yearlyTotal) / monthlyTotal * 100);
  };

  const formatFeature = (feature) => {
    // Remove 'has_' prefix and convert to title case
    return feature
      .replace('has_', '')
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const PlanFeatureSection = ({ icon: Icon, title, features, textColor = "text-gray-600" }) => (
    <div className="mb-6" role="list" aria-label={`${title} features`}>
      <h4 className={`font-semibold mb-2 flex items-center gap-2 ${textColor === 'text-gray-600' ? 'text-gray-900' : 'text-white'}`}>
        <Icon className="w-4 h-4 text-blue-500" aria-hidden="true" />
        {title}
      </h4>
      <ul className="space-y-2 text-sm">
        {features.map((feature, index) => (
          <li key={index} className={`flex items-center ${textColor}`} role="listitem">
            <Check className="w-4 h-4 mr-2 text-green-500" aria-hidden="true" />
            {title === 'Limits' ? feature : formatFeature(feature)}
          </li>
        ))}
      </ul>
    </div>
  );

  const handlePlanSelection = (plan) => {
    if (!plan.stripe) return '';
    const priceId = billingPeriod === 'yearly' ?
      plan.stripe.yearlyPriceId :
      plan.stripe.monthlyPriceId;
    return `${newUserCheckoutsPath}?price_id=${priceId}`;
  };

  const renderPlanButton = (plan) => {
    const baseClasses = `w-full py-3 text-white ${plan.buttonColor} rounded-xl
      transition-colors flex items-center justify-center gap-2 focus:ring-2
      focus:ring-offset-2 focus:ring-blue-500`;

    if (plan.id === 'free') {
      return (
        <button className={baseClasses} aria-label="Start free plan">
          {plan.buttonText}
        </button>
      );
    }

    if (plan.id === 'enterprise') {
      return (
        <button className={baseClasses} aria-label="Contact sales">
          <Headphones className="w-4 h-4" aria-hidden="true" />
          {plan.buttonText}
        </button>
      );
    }

    return (
      <a
        href={handlePlanSelection(plan)}
        className={baseClasses}
        aria-label={`Upgrade to ${plan.name} plan`}
      >
        {plan.buttonText}
      </a>
    );
  };

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white" aria-label="Pricing plans">
      <div className="flex justify-center mb-12">
        <div className="join gap-2" role="radiogroup" aria-label="Billing period">
          {['monthly', 'yearly'].map(period => (
            <button
              key={period}
              onClick={() => setBillingPeriod(period)}
              className={`btn ${billingPeriod === period ? 'btn-active' : ''}`}
              aria-pressed={billingPeriod === period}
              role="radio"
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
              {period === 'yearly' && (
                <span className="ml-1 text-sm text-green-500">Save up to 20%</span>
              )}
            </button>
          ))}
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600">
            Choose Your Plan
          </h2>
          <p className="text-lg text-gray-600">
            Enterprise-grade AI content analysis platform with state-of-the-art LLM models
          </p>
        </div>

        <div className="grid max-w-7xl mx-auto gap-8 lg:grid-cols-4 md:grid-cols-2">
          {plans.map((plan) => {
            const isPro = plan.id === 'professional';
            const textColor = isPro ? 'text-white' : 'text-gray-600';
            const savings = calculateSavings(plan);

            return (
              <div
                key={plan.id}
                className={`
                  rounded-2xl shadow-lg p-8 relative border transition-all
                  ${isPro ?
                    'bg-gray-900 border-gray-800 hover:border-blue-500 transform scale-105' :
                    'bg-white border-gray-100 hover:border-blue-100'}
                `}
                role="article"
                aria-label={`${plan.name} plan`}
              >
                {plan.isPopular && (
                  <div className="absolute -top-4 right-4">
                    <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm
                      flex items-center gap-1" aria-label="Popular plan">
                      <Star className="w-4 h-4" aria-hidden="true" />
                      Popular
                    </span>
                  </div>
                )}

                <h3 className={`text-xl font-bold mb-4 flex items-center gap-2
                  ${isPro ? 'text-white' : ''}`}>
                  {plan.name}
                  <plan.icon className={`w-5 h-5 ${plan.iconColor}`} aria-hidden="true" />
                </h3>

                <div className="mb-6">
                  <span className={`text-4xl font-bold ${isPro ? 'text-white' : ''}`}>
                    {typeof plan.price[billingPeriod] === 'number' ?
                      `$${plan.price[billingPeriod].toLocaleString()}` :
                      plan.price[billingPeriod]}
                  </span>
                  <span className={textColor}>
                    {plan.price[billingPeriod] === 'Custom' ?
                      '/custom billing' :
                      billingPeriod === 'yearly' ? '/yr' : '/mo'}
                  </span>
                  {savings && billingPeriod === 'yearly' && (
                    <div className="text-green-500 text-sm mt-1">
                      Save {savings}%
                    </div>
                  )}
                </div>

                {Object.entries(plan.features).map(([key, features]) => (
                  <PlanFeatureSection
                    key={key}
                    icon={
                      key === 'models' ? Cpu :
                        key === 'ai' ? Brain :
                          key === 'data' ? Database :
                            key === 'limits' ? Gauge :
                              key === 'support' ? Headphones :
                                Shield
                    }
                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                    features={features}
                    textColor={textColor}
                  />
                ))}

                {renderPlanButton(plan)}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Plans;
