import React, { useState, useEffect } from 'react'
import {
  Tags, List, Heart, FileText, AlertTriangle, User, Database,
  Lightbulb, Calendar, Lock, Copy, Blocks, Mail, Globe, Code,
  Webhook, BarChart3, FileStack, Cpu, Loader2, XCircle, MailQuestion, Smile, ShoppingCart, MapPin, Link, ChartBar, Users, Clock, CalendarClock, Hospital
} from 'lucide-react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
  ResponsiveContainer, LineChart, Line
} from 'recharts';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-8">
    <Loader2 className="h-8 w-8 animate-spin text-primary" />
  </div>
)

const ErrorAlert = ({ message }) => (
  <div role="alert" className="alert alert-error my-4">
    <XCircle className="h-6 w-6 shrink-0" />
    <span>{message}</span>
  </div>
);

const StatCard = ({ title, icon, children, isLoading, error }) => (
  <div className="card bg-base-100 shadow-lg border border-base-300 hover:border-primary hover:shadow-xl">
    <div className="card-body">
      <div className="flex justify-between items-center">
        <h2 className="card-title text-lg font-bold">{title}</h2>
        {icon}
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorAlert message={error} />
      ) : (
        children
      )}
    </div>
  </div>
)

const AgentUsageStats = ({ data, isLoading, error }) => {
  const agents = [
    { id: 'tags', label: 'Tag Detection', icon: <Tags className="h-5 w-5" />, processingKey: 'tags' },
    { id: 'category', label: 'Categorization', icon: <List className="h-5 w-5" />, processingKey: 'categorization' },
    { id: 'sentiment', label: 'Sentiment Analysis', icon: <Heart className="h-5 w-5" />, processingKey: 'sentiment' },
    { id: 'summary', label: 'Summarization', icon: <FileText className="h-5 w-5" />, processingKey: 'summaries' },
    { id: 'anomaly_detector', label: 'Anomaly Detection', icon: <AlertTriangle className="h-5 w-5" />, processingKey: 'anomalies' },
    { id: 'named_entity', label: 'Entity Recognition', icon: <User className="h-5 w-5" />, processingKey: 'entities' },
    { id: 'metadata_extractor', label: 'Metadata Extraction', icon: <Database className="h-5 w-5" />, processingKey: 'metadata' },
    { id: 'actionable_insights', label: 'Actionable Insights', icon: <Lightbulb className="h-5 w-5" />, processingKey: null },
    { id: 'date_extraction', label: 'Date Extraction', icon: <Calendar className="h-5 w-5" />, processingKey: 'date_extraction' },
    { id: 'pii_detection', label: 'PII Detection', icon: <Lock className="h-5 w-5" />, processingKey: 'pii_detection' },
    { id: 'duplicate_detection', label: 'Duplicate Detection', icon: <Copy className="h-5 w-5" />, processingKey: 'duplicate_detection' },
    { id: 'spam_extraction', label: 'Spam Detection', icon: <MailQuestion className="h-5 w-5" />, processingKey: 'spam_extraction' },
    { id: 'emotion', label: 'Emotion Detection', icon: <Smile className="h-5 w-5" />, processingKey: 'emotion' },
    { id: 'product_categorization', label: 'Product Categorization', icon: <ShoppingCart className="h-5 w-5" />, processingKey: 'product_categorization' },
    { id: 'address_validation', label: 'Address Validation', icon: <MapPin className="h-5 w-5" />, processingKey: 'address_validation' },
    { id: 'citation_detector', label: 'Citation Detection', icon: <Link className="h-5 w-5" />, processingKey: 'citation_detector' },
    { id: 'kpi_extractor', label: 'KPI Extraction', icon: <ChartBar className="h-5 w-5" />, processingKey: 'kpi_extractor' },
    { id: 'competitor_mention_detector', label: 'Competitor Mention Detection', icon: <Users className="h-5 w-5" />, processingKey: 'competitor_mention_detector' },
    { id: 'urgency_detector', label: 'Urgency Detection', icon: <Clock className="h-5 w-5" />, processingKey: 'urgency_detector' },
    { id: 'deadline_detector', label: 'Deadline Detection', icon: <CalendarClock className="h-5 w-5" />, processingKey: 'deadline_detector' },
    { id: 'medical_term_extractor', label: 'Medical Term Extraction', icon: <Hospital className="h-5 w-5" />, processingKey: 'medical_term_extractor' }
  ];

  return (
    <StatCard
      title="Agent Usage"
      icon={<Cpu className="h-6 w-6 text-primary" />}
      isLoading={isLoading}
      error={error}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {agents.map(agent => {
          // Get values directly from the data structure
          const enabledCount = data?.enabled_agents?.[agent.id] ?? 0;
          const processedCount = agent.processingKey ? (data?.processing_counts?.[agent.processingKey] ?? 0) : 0;

          return (
            <div key={agent.id} className="bg-base-200 rounded-lg p-4">
              <div className="flex items-center gap-3 mb-3">
                <div className="text-primary">{agent.icon}</div>
                <div className="font-medium">{agent.label}</div>
              </div>

              <div className="grid grid-cols-2 gap-2">
                <div className="bg-base-300 rounded p-2">
                  <div className="text-xs opacity-70">Enabled In</div>
                  <div className="text-lg font-bold">
                    {enabledCount} <span className="text-xs opacity-70">projects</span>
                  </div>
                </div>

                {agent.processingKey && (
                  <div className="bg-base-300 rounded p-2">
                    <div className="text-xs opacity-70">Processed</div>
                    <div className="text-lg font-bold">
                      {processedCount} <span className="text-xs opacity-70">items</span>
                    </div>
                  </div>
                )}
              </div>

              {enabledCount > 0 && processedCount > 0 && (
                <div className="mt-2">
                  <div className="text-xs text-right opacity-70">
                    ~{(processedCount / enabledCount).toFixed(1)} items/project
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </StatCard>
  );
};


const ProjectsStats = ({ data, isLoading, error, projectsURL }) => {
  const emptyProjectsCount = data?.projects_summary?.without_content?.length || 0;
  const activeProjectsCount = data?.content_distribution?.length || 0;
  const totalProjects = data?.projects_summary?.total || 0;

  return (
    <StatCard
      title={
        <a href={projectsURL} className="hover:underline link link-primary">
          {activeProjectsCount > 0 ? `${totalProjects} Projects` : "No Projects"}
        </a>
      }
      icon={<Blocks className="h-6 w-6 text-primary" />}
      isLoading={isLoading}
      error={error}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
        <div className="stats bg-base-200 rounded-lg">
          <div className="stat">
            <div className="stat-title">Total Projects</div>
            <div className="stat-value text-2xl">{totalProjects}</div>
          </div>
        </div>
        <div className="stats bg-base-200 rounded-lg">
          <div className="stat">
            <div className="stat-title">Active Projects</div>
            <div className="stat-value text-2xl">{activeProjectsCount}</div>
          </div>
        </div>
        <div className="stats bg-base-200 rounded-lg">
          <div className="stat">
            <div className="stat-title">Empty Projects</div>
            <div className="stat-value text-2xl">{emptyProjectsCount}</div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-base-200 rounded-lg p-4">
            <h3 className="font-medium mb-2">Active Projects</h3>
            <div className="space-y-2">
              {data?.content_distribution?.map(project => (
                <div key={project.id} className="flex justify-between items-center">
                  <span>{project.name}</span>
                  <span className="text-sm opacity-70">{project.count} contents</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-base-200 rounded-lg p-4">
            <h3 className="font-medium mb-2">Empty Projects</h3>
            <div className="space-y-2">
              {data?.projects_summary?.without_content?.map(project => (
                <div key={project.id} className="flex justify-between items-center">
                  <span>{project.name}</span>
                  <span className="text-sm opacity-70">No content</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </StatCard>
  );
};
const IntegrationStats = ({ data, isLoading, error }) => {
  const integrations = [
    {
      key: 'api_projects',
      distKey: 'api',
      label: 'API Access',
      icon: <Globe className="h-5 w-5" />
    },
    {
      key: 'email_projects',
      distKey: 'email',
      label: 'Email Integration',
      icon: <Mail className="h-5 w-5" />
    },
    {
      key: 'js_projects',
      distKey: 'js',
      label: 'JS Snippet',
      icon: <Code className="h-5 w-5" />
    },
    {
      key: 'webhook_projects',
      distKey: 'webhook',
      label: 'Webhooks',
      icon: <Webhook className="h-5 w-5" />
    },
  ];

  return (
    <StatCard
      title="Integrations"
      icon={<Blocks className="h-6 w-6 text-primary" />}
      isLoading={isLoading}
      error={error}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {integrations.map(integration => (
          <div key={integration.key} className="bg-base-200 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2 text-primary">
                {integration.icon}
                <span className="font-medium">{integration.label}</span>
              </div>
              <div className="text-2xl font-bold">{data?.[integration.key] || 0}</div>
            </div>

            <div className="space-y-2">
              {data?.integration_distribution?.[integration.distKey]?.length > 0 ? (
                data.integration_distribution[integration.distKey].map(project => (
                  <div
                    key={project.id}
                    className="flex items-center justify-between text-sm bg-base-300 rounded p-2"
                  >
                    <span>{project.name}</span>
                    <div className="badge badge-primary badge-sm">ID: {project.id}</div>
                  </div>
                ))
              ) : (
                <div className="text-sm opacity-70 text-center py-2">
                  No projects using {integration.label}
                </div>
              )}
            </div>

            {data?.integration_distribution?.[integration.distKey]?.length > 0 && (
              <div className="mt-2 text-xs text-right opacity-70">
                {data.integration_distribution[integration.distKey].length} project(s)
              </div>
            )}
          </div>
        ))}
      </div>
    </StatCard>
  );
};

const UsageLimitsStats = ({ data, isLoading, error }) => (
  <StatCard
    title="Usage & Limits"
    icon={<FileStack className="h-6 w-6 text-primary" />}
    isLoading={isLoading}
    error={error}
  >
    <div className="space-y-4 mt-4">
      <div className="bg-base-200 rounded-lg p-4">
        <div className="flex justify-between mb-2">
          <span className="font-medium">Projects</span>
          <span className="opacity-70">
            {data?.projects?.current || 0} / {data?.projects?.limit || 0}
          </span>
        </div>
        <progress
          className="progress progress-primary w-full"
          value={data?.projects?.percentage || 0}
          max="100"
        />
      </div>
      <div className="bg-base-200 rounded-lg p-4">
        <div className="flex justify-between mb-2">
          <span className="font-medium">Content</span>
          <span className="opacity-70">
            {data?.content?.current || 0} / {data?.content?.limit || 0}
          </span>
        </div>
        <progress
          className="progress progress-primary w-full"
          value={data?.content?.percentage || 0}
          max="100"
        />
      </div>
    </div>
  </StatCard>
)

const ContentTimelineStats = ({ data, isLoading, error }) => {
  // Transform daily content data for the chart
  const dailyData = Object.entries(data?.daily_content || {}).map(([date, count]) => ({
    date: new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    count: count
  })).reverse();

  // Transform weekly content data for the chart
  const weeklyData = Object.entries(data?.weekly_content || {}).map(([date, count]) => ({
    date: new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    count: count
  })).reverse();

  // Custom tooltip formatter
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-base-100 border border-base-300 p-2 rounded-lg shadow-lg">
          <p className="font-medium">{label}</p>
          <p className="text-primary">{`Count: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <StatCard
      title="Content Activity"
      icon={<BarChart3 className="h-6 w-6 text-primary" />}
      isLoading={isLoading}
      error={error}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="bg-base-200 rounded-lg p-4">
          <h3 className="font-medium mb-4">Daily Content</h3>
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dailyData}>
                <CartesianGrid strokeDasharray="3 3" className="opacity-30" />
                <XAxis
                  dataKey="date"
                  tick={{ fontSize: 12 }}
                  tickMargin={5}
                />
                <YAxis
                  tick={{ fontSize: 12 }}
                  tickMargin={5}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="count"
                  fill="hsl(var(--p))"
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-base-200 rounded-lg p-4">
          <h3 className="font-medium mb-4">Weekly Content</h3>
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={weeklyData}>
                <CartesianGrid strokeDasharray="3 3" className="opacity-30" />
                <XAxis
                  dataKey="date"
                  tick={{ fontSize: 12 }}
                  tickMargin={5}
                />
                <YAxis
                  tick={{ fontSize: 12 }}
                  tickMargin={5}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="hsl(var(--p))"
                  strokeWidth={2}
                  dot={{ fill: "hsl(var(--p))" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </StatCard>
  );
};

const Dashboard = ({
  projectsStatsPath,
  integrationStatsPath,
  agentUsageStatsPath,
  contentTimelineStatsPath,
  usageLimitsStatsPath,
  projectsURL
}) => {
  // State for data
  const [projectsStats, setProjectsStats] = useState(null)
  const [integrationStats, setIntegrationStats] = useState(null)
  const [agentUsageStats, setAgentUsageStats] = useState(null)
  const [contentTimelineStats, setContentTimelineStats] = useState(null)
  const [usageLimitsStats, setUsageLimitsStats] = useState(null)

  // State for loading
  const [isLoadingProjects, setIsLoadingProjects] = useState(true)
  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(true)
  const [isLoadingAgentUsage, setIsLoadingAgentUsage] = useState(true)
  const [isLoadingContentTimeline, setIsLoadingContentTimeline] = useState(true)
  const [isLoadingUsageLimits, setIsLoadingUsageLimits] = useState(true)

  // State for errors
  const [projectsError, setProjectsError] = useState(null)
  const [integrationsError, setIntegrationsError] = useState(null)
  const [agentUsageError, setAgentUsageError] = useState(null)
  const [contentTimelineError, setContentTimelineError] = useState(null)
  const [usageLimitsError, setUsageLimitsError] = useState(null)

  const fetchStats = async () => {
    setIsLoadingProjects(true)
    try {
      const response = await fetch(projectsStatsPath, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Failed to fetch projects stats')
      const data = await response.json()
      setProjectsStats(data)
      setProjectsError(null)
    } catch (error) {
      setProjectsError(error.message)
    } finally {
      setIsLoadingProjects(false)
    }
  }

  const fetchIntegrationStats = async () => {
    setIsLoadingIntegrations(true)
    try {
      const response = await fetch(integrationStatsPath, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Failed to fetch integration stats')
      const data = await response.json()
      setIntegrationStats(data)
      setIntegrationsError(null)
    } catch (error) {
      setIntegrationsError(error.message)
    } finally {
      setIsLoadingIntegrations(false)
    }
  }

  const fetchAgentUsageStats = async () => {
    setIsLoadingAgentUsage(true)
    try {
      const response = await fetch(agentUsageStatsPath, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Failed to fetch agent usage stats')
      const data = await response.json()
      setAgentUsageStats(data)
      setAgentUsageError(null)
    } catch (error) {
      setAgentUsageError(error.message)
    } finally {
      setIsLoadingAgentUsage(false)
    }
  }

  const fetchContentTimelineStats = async () => {
    setIsLoadingContentTimeline(true)
    try {
      const response = await fetch(contentTimelineStatsPath, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Failed to fetch content timeline stats')
      const data = await response.json()
      setContentTimelineStats(data)
      setContentTimelineError(null)
    } catch (error) {
      setContentTimelineError(error.message)
    } finally {
      setIsLoadingContentTimeline(false)
    }
  }

  const fetchUsageLimitsStats = async () => {
    setIsLoadingUsageLimits(true)
    try {
      const response = await fetch(usageLimitsStatsPath, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Failed to fetch usage limits stats')
      const data = await response.json()
      setUsageLimitsStats(data)
      setUsageLimitsError(null)
    } catch (error) {
      setUsageLimitsError(error.message)
    } finally {
      setIsLoadingUsageLimits(false)
    }
  }

  useEffect(() => {
    fetchStats()
    fetchIntegrationStats()
    fetchAgentUsageStats()
    fetchContentTimelineStats()
    fetchUsageLimitsStats()
  }, [])

  return (
    <div className="p-8 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ProjectsStats
          projectsURL={projectsURL}
          data={projectsStats}
          isLoading={isLoadingProjects}
          error={projectsError}
        />
        <IntegrationStats
          data={integrationStats}
          isLoading={isLoadingIntegrations}
          error={integrationsError}
        />
      </div>
      <AgentUsageStats
        data={agentUsageStats}
        isLoading={isLoadingAgentUsage}
        error={agentUsageError}
      />
      <ContentTimelineStats
        data={contentTimelineStats}
        isLoading={isLoadingContentTimeline}
        error={contentTimelineError}
      />
      <UsageLimitsStats
        data={usageLimitsStats}
        isLoading={isLoadingUsageLimits}
        error={usageLimitsError}
      />
    </div>
  )
}

export default Dashboard
