import React from 'react';
import {
  Terminal, Briefcase, Heart, Info, Play, Globe, Tag, Fingerprint, MapPin
} from 'lucide-react';

const ContextResult = ({ context }) => {
  if (!context) return null;

  const typeIcons = {
    technical: <Terminal className="w-5 h-5" />,
    business: <Briefcase className="w-5 h-5" />,
    emotional: <Heart className="w-5 h-5" />,
    informational: <Info className="w-5 h-5" />,
    action: <Play className="w-5 h-5" />,
    general: <Globe className="w-5 h-5" />
  };

  const getTypeColor = (type) => {
    const colors = {
      technical: 'bg-blue-100 text-blue-800',
      business: 'bg-green-100 text-green-800',
      emotional: 'bg-pink-100 text-pink-800',
      informational: 'bg-purple-100 text-purple-800',
      action: 'bg-orange-100 text-orange-800',
      general: 'bg-gray-100 text-gray-800'
    };
    return colors[type] || colors.general;
  };

  const hasKeyPhrases = Array.isArray(context.key_phrases) && context.key_phrases.length > 0;
  const hasPatterns = Array.isArray(context.detected_patterns) && context.detected_patterns.length > 0;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg border border-base-200">
      <div className="flex flex-col gap-4">
        {context.type && (
          <div className="flex items-center gap-2">
            <div className={`p-2 rounded-lg ${getTypeColor(context.type)}`}>
              {typeIcons[context.type] || typeIcons.general}
            </div>
            <div>
              <p className="text-sm font-medium text-base-content/60">Type</p>
              <p className="text-lg font-semibold capitalize">{context.type}</p>
            </div>
          </div>
        )}

        {context.domain && (
          <div className="flex items-center gap-2">
            <div className="p-2 rounded-lg bg-base-200">
              <MapPin className="w-5 h-5" />
            </div>
            <div>
              <p className="text-sm font-medium text-base-content/60">Domain</p>
              <p className="text-lg font-semibold capitalize">{context.domain}</p>
            </div>
          </div>
        )}
      </div>

      {hasKeyPhrases && (
        <div className="mt-6">
          <div className="flex items-center gap-2 mb-3">
            <Tag className="w-4 h-4" />
            <h3 className="font-semibold">Key Phrases</h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {context.key_phrases.map((phrase, index) => (
              <span
                key={`phrase-${index}-${String(phrase).slice(0, 10)}`}
                className="px-3 py-1 text-sm rounded-full bg-base-200"
              >
                {String(phrase)}
              </span>
            ))}
          </div>
        </div>
      )}

      {hasPatterns && (
        <div className="mt-6">
          <div className="flex items-center gap-2 mb-3">
            <Fingerprint className="w-4 h-4" />
            <h3 className="font-semibold">Detected Patterns</h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {context.detected_patterns.map((pattern, index) => (
              <span
                key={`pattern-${index}-${String(pattern).slice(0, 10)}`}
                className="px-3 py-1 text-sm rounded-full bg-base-200"
              >
                {String(pattern)}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContextResult;
