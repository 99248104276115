import React from 'react';
import { AlertCircle, Clock, Timer, RotateCcw, PieChart } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const UrgencyLevelDisplay = ({ urgencyLevel }) => {
  if (!urgencyLevel) return null;

  const getUrgencyColor = (category) => {
    switch (category) {
      case 'critical': return 'error';
      case 'high': return 'warning';
      case 'medium': return 'info';
      case 'low': return 'success';
      default: return 'neutral';
    }
  };

  const getBadgeColor = (confidence) => {
    const score = parseFloat(confidence);
    if (isNaN(score)) return 'neutral';
    if (score >= 0.8) return 'success';
    if (score >= 0.5) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <AlertCircle className="w-4 h-4" />
          Urgency Level
        </h3>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <span className={`badge badge-lg badge-${getUrgencyColor(urgencyLevel?.category)}`}>
                  {urgencyLevel?.category || 'Unknown'} Priority
                </span>
                <span className={`badge badge-${getBadgeColor(urgencyLevel?.confidence)}`}>
                  {urgencyLevel?.confidence ?
                    `${(urgencyLevel?.confidence * 100).toFixed(1)}% Confidence` :
                    'No Score'}
                </span>
              </div>
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Urgency Score</p>
            <div className="w-full h-3 bg-base-300 rounded-full mt-1">
              <div
                className={`h-full rounded-full bg-${getUrgencyColor(urgencyLevel?.category)}`}
                style={{ width: `${(urgencyLevel?.score || 0) * 100}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UrgencyIndicatorsDisplay = ({ indicators }) => {
  if (!indicators) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Timer className="w-4 h-4" />
          Urgency Indicators
        </h3>
        <div className="space-y-4">
          <div>
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm font-medium">Response Required</p>
              <span className={`badge ${indicators?.action_required ? 'badge-error' : 'badge-success'}`}>
                {indicators?.action_required ? 'Yes' : 'No'}
              </span>
            </div>
            {indicators.response_timeframe && (
              <div className="flex items-center gap-2 mt-1">
                <Clock className="w-4 h-4" />
                <span className="text-sm">{indicators?.response_timeframe}</span>
              </div>
            )}
          </div>

          {indicators.temporal_markers && indicators.temporal_markers.length > 0 && (
            <div>
              <p className="text-xs text-base-content/70 mb-1">Time Indicators:</p>
              <div className="flex flex-wrap gap-2">
                {indicators?.temporal_markers?.map((marker, idx) => (
                  <span key={idx} className="badge badge-sm badge-primary">
                    {marker}
                  </span>
                ))}
              </div>
            </div>
          )}

          {indicators.priority_phrases && indicators.priority_phrases.length > 0 && (
            <div>
              <p className="text-xs text-base-content/70 mb-1">Priority Indicators:</p>
              <div className="flex flex-wrap gap-2">
                {indicators?.priority_phrases?.map((phrase, idx) => (
                  <span key={idx} className="badge badge-sm badge-secondary">
                    {phrase}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.assessment_quality) return null;

  const getContextClarityColor = (clarity) => {
    switch (clarity) {
      case 'high': return 'success';
      case 'medium': return 'warning';
      case 'low': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Assessment Quality
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Confidence Score</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(metadata?.assessment_quality?.confidence_score || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">False Positive Risk</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-error rounded-full"
                style={{ width: `${(metadata?.assessment_quality?.false_positive_risk || 0) * 100}%` }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">Context Clarity:</span>
            <span className={`badge badge-${getContextClarityColor(metadata?.assessment_quality?.context_clarity)}`}>
              {metadata?.assessment_quality?.context_clarity || 'unknown'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const UrgencyDetectionResults = ({ urgencyDetection, onRerun }) => {
  console.log(urgencyDetection);

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={urgencyDetection?.agent_name || 'Unknown Agent'} />
            </p>
            <div className="flex items-center gap-2">
              <ResultStatus status={urgencyDetection?.response?.result?.status || 'unknown'} />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('urgencyDetection')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(urgencyDetection?.result?.response || {})}
              copyKey="urgency-detection"
            />
          </div>
        </div>
        {urgencyDetection?.result?.response && (
          <div className="space-y-4">
            <UrgencyLevelDisplay urgencyLevel={urgencyDetection?.result?.response?.urgency_analysis?.urgency_level} />
            <UrgencyIndicatorsDisplay indicators={urgencyDetection?.result?.response?.urgency_analysis?.urgency_indicators} />
            <MetadataDisplay metadata={urgencyDetection?.result?.response?.metadata} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UrgencyDetectionResults;
