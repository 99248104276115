import React from 'react';
import {
  Activity, AlertCircle, CheckCircle, CreditCard, Database,
  FileEdit, FilePlus, Fingerprint, FolderTree, Globe,
  Heart, Key, Lightbulb, Map, MessageSquare, Radar,
  Settings, Tag, Trash2, User, UserPlus, XCircle
} from 'lucide-react';

const ICON_MAP = {
  // User related
  'user.create': UserPlus,
  'user.update': User,

  // Project related
  'project.create': FilePlus,
  'project.update': FileEdit,
  'content.public_project.show': Globe,

  // Content core actions
  'content.create': FilePlus,
  'content.created': CheckCircle,
  'content.update': FileEdit,
  'content.destroy': Trash2,

  // Tagging related
  'content.tagging.done': Tag,
  'content.tagging.error': AlertCircle,
  'tagging.create': Tag,

  // Summary related
  'content.summary.done': MessageSquare,
  'summary.create': MessageSquare,

  // Sentiment related
  'content.sentiment.done': Heart,
  'sentiment_analysis.create': Heart,

  // Intent related
  'content.intent_result.done': Activity,
  'content_intent.create': Activity,

  // Categorization related
  'categorization.create': FolderTree,
  'content.category_result.done': FolderTree,
  'content.category_result.error': XCircle,
  'content.missing_category_fields.done': FolderTree,
  'missing_category_field.create': FolderTree,

  // Industry mapping
  'content.content_mapping_per_industry.done': Map,
  'context_mapping_per_industry.create': Map,

  // Anomaly detection
  'content.anomaly.done': Activity,
  'anomaly_detection.create': Radar,

  // Metadata related
  'content.metadata_expectation_keys.done': Key,
  'metadata_expectation.create': Key,
  'content.metadata_extractor.done': Database,
  'metadata_extraction.create': Database,

  // Named entity
  'content.name_entity.done': Fingerprint,
  'named_entity.create': Fingerprint,

  // Insights
  'content.actionable_insights_result.done': Lightbulb,
  'content.actionable_insights_result_error': AlertCircle,
  'actionable_insight.create': Lightbulb,

  // Stripe/Billing
  'stripe.update_checkout': CreditCard,
};

const formatActivityKey = (key) => {
  if (!key || typeof key !== 'string') return '';
  return key.split('.').join(' ');
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    return new Date(dateString).toLocaleString();
  } catch (error) {
    console.error('Invalid date:', error);
    return '';
  }
};

const ActivityItem = ({ activity }) => {
  if (!activity || typeof activity !== 'object') {
    return null; // Return null if activity prop is invalid
  }

  const { key, created_at, trackable_type } = activity;
  const IconComponent = key && ICON_MAP[key] ? ICON_MAP[key] : Settings;

  return (
    <li className="border-b border-base-200 last:border-b-0">
      <div className="hover:bg-base-200 py-2 px-3">
        <div className="flex gap-3 flex-wrap">
          <div className="flex-shrink-0 mt-1">
            <IconComponent className="w-4 h-4 text-primary" />
          </div>
          <div className="flex-grow flex justify-between items-start">
            <div>
              <p className="text-sm font-medium text-gray-700">
                {formatActivityKey(key)}
              </p>
              <p className="text-xs text-gray-500">
                {formatDate(created_at)}
              </p>
            </div>
            <div className="text-xs text-gray-400 ml-2">
              {trackable_type || ''}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ActivityItem;
