import React from "react";
import SummaryResult from "./SummaryResult";
import AnalysisResult from "./AnalysisResult";
import MetadataResult from "./MetadataResult";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import ResultStatus from "../../common/ResultStatus";
import AIAgentCard from "../../common/AIAgentCard";
const extractData = (summaries) => {
  try {
    // Case 1: With response object
    if (summaries?.result?.response) {
      return {
        agent_name: summaries?.agent_name,
        result: {
          summary: summaries?.result?.response?.summary,
          analysis: summaries?.result?.response?.analysis,
          status: summaries?.status,
          response_status: summaries?.result?.response?.status
        }
      };
    }
    // Case 2: Direct access
    else if (summaries?.result?.summary) {
      return {
        agent_name: summaries?.agent_name,
        result: {
          summary: summaries?.result?.summary,
          analysis: summaries?.result?.analysis,
          status: summaries?.status
        }
      };
    }
    throw new Error('Invalid data structure');
  } catch (error) {
    console.error('Error parsing summaries:', error);
    return null;
  }
};

const SummariesResult = ({ summaries, onRerun }) => {
  const data = extractData(summaries);

  if (!data) {
    return (
      <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
        <p className="text-red-500">Error: Invalid data structure</p>
      </div>
    );
  }
  const { agent_name, result: { summary, analysis, metadata, status } } = data;

  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
            <ResultStatus status={status} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('summaries')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(summary)} copyKey={'summaries'} />
        </div>
      </div>

      <div className="flex gap-6 justify-start w-full flex-col">
        <SummaryResult summary={summary} />
        <AnalysisResult analysis={analysis} />
        <MetadataResult metadata={metadata} />
      </div>
    </div>
  );
};

export default SummariesResult;
