import React from 'react';
import { MapPin, Check, AlertTriangle, RotateCcw, FileCheck, Database } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const ValidationScoreDisplay = ({ validation }) => {
  if (!validation) return null;

  const getScoreColor = (score) => {
    if (score >= 0.8) return 'success';
    if (score >= 0.6) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <FileCheck className="w-4 h-4" />
          Validation Results
        </h3>
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <span className="font-medium">Status:</span>
            {validation.is_valid ? (
              <span className="badge badge-success gap-1">
                <Check className="w-4 h-4" /> Valid
              </span>
            ) : (
              <span className="badge badge-error gap-1">
                <AlertTriangle className="w-4 h-4" /> Invalid
              </span>
            )}
          </div>
          <div>
            <p className="text-sm mb-1">Quality Score:</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className={`h-full bg-${getScoreColor(validation.quality_score)} rounded-full`}
                style={{ width: `${validation.quality_score * 100}%` }}
              />
            </div>
            <p className="text-xs text-right mt-1">
              {(validation.quality_score * 100).toFixed(1)}%
            </p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-base-content/70">Method:</span>
            <span className="badge badge-neutral capitalize">{validation.validation_method}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-base-content/70">Confidence:</span>
            <span className="badge badge-primary">
              {(validation.confidence * 100).toFixed(1)}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressDisplay = ({ address }) => {
  if (!address) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <MapPin className="w-4 h-4" />
          Standardized Address
        </h3>
        <div className="space-y-2">
          <p className="font-medium">{address?.formatted_address}</p>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>
              <p className="text-base-content/70">Street:</p>
              <p>{address?.street_line1}</p>
              {address?.street_line2 && <p>{address?.street_line2}</p>}
            </div>
            <div>
              <p className="text-base-content/70">City/State:</p>
              <p>{address?.city}, {address?.state} {address?.postal_code}</p>
              <p>{address?.country}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Database className="w-4 h-4" />
          Metadata
        </h3>
        <div className="flex items-center gap-4">
          <div>
            <span className="text-sm text-base-content/70">Format:</span>
            <span className="ml-2 badge badge-neutral">{metadata?.country_format}</span>
          </div>
          <div>
            <span className="text-sm text-base-content/70">Postal Compliance:</span>
            <span className={`ml-2 badge badge-${metadata?.postal_compliance ? 'success' : 'error'}`}>
              {metadata?.postal_compliance ? 'Compliant' : 'Non-Compliant'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressValidationResults = ({ addressValidation, onRerun }) => {

  const { result = {}, agent_name } = addressValidation || {};

// Second level destructuring with fallback
  const { response = {} } = result;

// Third level destructuring with fallback
  const {
    validation = {},
    standardized_address = {},
    metadata = {},
    status = {}
  } = response;

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={agent_name} />
              <ResultStatus status={status} />
            </p>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('addressValidation')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(result)}
              copyKey={'address-validation'}
            />
          </div>
        </div>

        <div className="space-y-4">
          {validation && <ValidationScoreDisplay validation={validation} />}
          {standardized_address && <AddressDisplay address={standardized_address} />}
          {metadata && <MetadataDisplay metadata={metadata} />}
        </div>
      </div>
    </div>
  );
};

export default AddressValidationResults;
