import React from 'react';
import { Quote, Book, AlertCircle, RotateCcw, PieChart } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const CitationDisplay = ({ citations = [] }) => {
  if (!Array.isArray(citations) || citations.length === 0) return null;

  const getBadgeColor = (confidence) => {
    const score = parseFloat(confidence);
    if (isNaN(score)) return 'neutral';
    if (score >= 0.8) return 'success';
    if (score >= 0.5) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Quote className="w-4 h-4" />
          Citations Found
        </h3>
        <div className="space-y-4">
          {citations.map((citation, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <span className="badge badge-neutral capitalize">
                  {citation?.style || 'Unknown Style'}
                </span>
                <span className={`badge badge-${getBadgeColor(citation?.confidence)}`}>
                  {citation?.confidence ?
                    `${(citation?.confidence * 100).toFixed(1)}% Confidence` :
                    'No Confidence Score'}
                </span>
              </div>
              <p className="text-sm mb-2 font-medium">{citation?.text || 'No citation text'}</p>
              <div className="space-y-2">
                {citation?.components && (
                  <div className="text-sm">
                    <p className="text-xs text-base-content/70 mb-1">Components:</p>
                    <div className="grid grid-cols-2 gap-2">
                      <div>
                        <span className="text-xs text-base-content/70">Authors: </span>
                        <span>{citation?.components?.authors?.join(', ') || 'N/A'}</span>
                      </div>
                      <div>
                        <span className="text-xs text-base-content/70">Year: </span>
                        <span>{citation?.components?.year || 'N/A'}</span>
                      </div>
                      <div className="col-span-2">
                        <span className="text-xs text-base-content/70">Title: </span>
                        <span>{citation?.components?.title || 'N/A'}</span>
                      </div>
                      <div className="col-span-2">
                        <span className="text-xs text-base-content/70">Source: </span>
                        <span>{citation?.components?.source || 'N/A'}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ValidationDisplay = ({ validation }) => {
  if (!validation) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <AlertCircle className="w-4 h-4" />
          Validation Results
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Completeness</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(validation?.completeness || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Formatting Accuracy</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-secondary rounded-full"
                style={{ width: `${(validation?.formatting_accuracy || 0) * 100}%` }}
              />
            </div>
          </div>
          {validation?.missing_elements && validation?.missing_elements?.length > 0 && (
            <div>
              <p className="text-xs text-base-content/70 mb-1">Missing Elements:</p>
              <div className="flex flex-wrap gap-2">
                {validation?.missing_elements?.map((element, idx) => (
                  <span key={idx} className="badge badge-sm badge-warning">
                    {element}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.analysis_confidence) return null;

  const getValidationStrengthColor = (strength) => {
    switch (strength) {
      case 'high': return 'success';
      case 'medium': return 'warning';
      case 'low': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Analysis Confidence
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Overall Score</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(metadata?.analysis_confidence?.overall_score || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Style Recognition</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-secondary rounded-full"
                style={{ width: `${(metadata?.analysis_confidence?.style_recognition || 0) * 100}%` }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">Validation Strength:</span>
            <span className={`badge badge-${getValidationStrengthColor(metadata?.analysis_confidence?.validation_strength)}`}>
              {metadata?.analysis_confidence?.validation_strength || 'unknown'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CitationDetectionResults = ({ citationDetection, onRerun }) => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={citationDetection?.agent_name || 'Unknown Agent'} />
            </p>
            <div className="flex items-center gap-2">
              <ResultStatus status={citationDetection?.status || 'unknown'} />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('citationDetection')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(citationDetection?.result || {})}
              copyKey="citation-detection"
            />
          </div>
        </div>

        {citationDetection?.result && (
          <div className="space-y-4">
            <div className="card bg-base-200 shadow-lg">
              <div className="card-body p-4">
                <h3 className="card-title text-sm flex items-center gap-2">
                  <Book className="w-4 h-4" />
                  Overview
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  <div>
                    <p className="text-xs text-base-content/70">Total Citations</p>
                    <p className="text-lg font-medium">
                      {citationDetection?.result?.citation_analysis?.citations_found?.count || 0}
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-base-content/70">Confidence</p>
                    <p className="text-lg font-medium">
                      {citationDetection?.result?.citation_analysis?.citations_found?.confidence ?
                        `${(citationDetection?.result?.citation_analysis?.citations_found?.confidence * 100).toFixed(1)}%` :
                        'N/A'}
                    </p>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <p className="text-xs text-base-content/70">Styles Detected</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {citationDetection.result.citation_analysis?.citations_found?.styles_detected?.map((style, idx) => (
                        <span key={idx} className="badge badge-sm badge-ghost">
                          {style}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CitationDisplay citations={citationDetection?.result?.citation_analysis?.citations} />
            <ValidationDisplay validation={citationDetection?.result?.citation_analysis?.validation_results} />
            <MetadataDisplay metadata={citationDetection?.result?.metadata} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CitationDetectionResults;
