import React from 'react';
import CategoryItem from './CategoryItem';

const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);
const isValidArray = arr => Array.isArray(arr) && arr.length > 0;

const CategoryResult = ({ category }) => {
  if (!isValidObject(category)) return null;

  return (
    <div className="flex gap-4 flex-col border shadow-md">
      {isValidObject(category?.primary) && (
        <CategoryItem
          type="primary"
          data={category?.primary}
        />
      )}
      {isValidArray(category?.alternatives) &&
        category?.alternatives.map((alt, index) => (
          <CategoryItem
            key={`alt-${index}`}
            type="alternative"
            data={alt}
          />
        ))}
    </div>
  );
};

export default CategoryResult;
