import React from 'react';
import { MessageCircle, Key, CheckCircle2, Sparkles, Heart } from 'lucide-react';

const AnalysisResult = ({ analysis }) => {
  if (!analysis) return null;

  const getToneIcon = (tone) => {
    const icons = {
      formal: <MessageCircle className="w-5 h-5" />,
      informal: <Sparkles className="w-5 h-5" />,
      technical: <Key className="w-5 h-5" />,
      emotional: <Heart className="w-5 h-5" />,
      neutral: <MessageCircle className="w-5 h-5" />
    };
    return icons[tone] || icons.neutral;
  };

  const getToneColor = (tone) => {
    const colors = {
      formal: 'bg-blue-100 text-blue-800',
      informal: 'bg-green-100 text-green-800',
      technical: 'bg-purple-100 text-purple-800',
      emotional: 'bg-pink-100 text-pink-800',
      neutral: 'bg-gray-100 text-gray-800'
    };
    return colors[tone] || colors.neutral;
  };

  const hasKeyTerms = Array.isArray(analysis.key_terms) && analysis.key_terms.length > 0;
  const hasConfidenceFactors = Array.isArray(analysis.confidence_factors) && analysis.confidence_factors.length > 0;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg border border-base-200">
      {analysis.tone && (
        <div className="flex items-center gap-2 mb-6">
          <div className={`p-2 rounded-lg ${getToneColor(analysis.tone)}`}>
            {getToneIcon(analysis.tone)}
          </div>
          <div>
            <p className="text-sm font-medium text-base-content/60">Tone</p>
            <p className="text-lg font-semibold capitalize">{analysis.tone}</p>
          </div>
        </div>
      )}

      {hasKeyTerms && (
        <div className="mb-6">
          <div className="flex items-center gap-2 mb-3">
            <Key className="w-4 h-4" />
            <h3 className="font-semibold">Key Terms</h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {analysis.key_terms.map((term, index) => (
              <span
                key={`term-${index}-${term.slice(0, 10)}`}
                className="px-3 py-1 text-sm rounded-full bg-base-200 hover:bg-base-300 transition-colors"
              >
                {String(term)}
              </span>
            ))}
          </div>
        </div>
      )}

      {hasConfidenceFactors && (
        <div>
          <div className="flex items-center gap-2 mb-3">
            <CheckCircle2 className="w-4 h-4" />
            <h3 className="font-semibold">Confidence Factors</h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {analysis.confidence_factors.map((factor, index) => (
              <div
                key={`factor-${index}-${String(factor).slice(0, 10)}`}
                className="flex items-center gap-1.5 px-3 py-1.5 text-sm rounded-lg bg-success/10 text-success"
              >
                <CheckCircle2 className="w-3.5 h-3.5" />
                <span>{String(factor)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalysisResult;
