import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react'
import utils from '../../../utils'

const ActivityLog = ({ activities = [] }) => {
  const { formatDate } = utils;

  const formatActivityInfo = (activity) => {
    const actionParts = activity.key.split('.');
    return (
      <span>
        <span className="opacity-75">{actionParts.join(' • ')}</span>
        {' '}
        <span className="font-medium">"{activity?.trackable_type}"</span>
        {activity.parameters && Object.keys(activity?.parameters).length > 0 && (
          <span className="opacity-75"> with {JSON.stringify(activity?.parameters)}</span>
        )}
      </span>
    );
  };

  return (
    <div className="w-full">
      <table className="table w-full">
        <thead>
        <tr className="bg-base-200">
          <th className="font-medium text-base-content">Created At</th>
          <th className="font-medium text-base-content">Info</th>
        </tr>
        </thead>
        <tbody>
        {activities?.map((activity, index) => (
          <tr key={activity.id} className={index % 2 === 0 ? 'bg-base-100' : 'bg-base-200/50'}>
            <td className="py-4 text-base-content/75">
              {formatDate(activity?.created_at)}
            </td>
            <td className="py-4 text-base-content">
              {formatActivityInfo(activity)}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="flex justify-end gap-2 p-4">
        <button className="btn btn-sm gap-1">
          <ChevronLeft className="h-4 w-4" />
          Previous
        </button>
        <button className="btn btn-sm gap-1">
          Next
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default ActivityLog;
