import React from "react";
import { FileText, Clock, BarChart2, Tag, Info } from "lucide-react";

const MetadataField = ({ icon: Icon, label, value }) => {
  if (!value) return null;
  return (
    <div className="flex items-center gap-2">
      <div className="p-2 rounded-lg bg-primary/10">
        <Icon className="w-4 h-4 text-primary" />
      </div>
      <div>
        <p className="text-sm font-medium text-base-content/60">{label}</p>
        <p className="text-lg font-semibold">{String(value)}</p>
      </div>
    </div>
  );
};

const MetadataResult = ({ metadata }) => {
  if (!metadata) return null;

  const formatDate = (isoString) => {
    if (!isoString) return '';
    try {
      const date = new Date(isoString);
      if (isNaN(date.getTime())) return isoString;
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short'
      }).format(date);
    } catch (e) {
      return isoString;
    }
  };

  const getComplexityColor = (level) => {
    const colors = {
      low: 'bg-success/10 text-success',
      medium: 'bg-warning/10 text-warning',
      high: 'bg-error/10 text-error'
    };
    return colors[String(level).toLowerCase()] || 'bg-primary/10 text-primary';
  };

  const formatSourceType = (type) => {
    if (!type) return '';
    return String(type)
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg border border-base-200">
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-2 mb-2">
          <Info className="w-5 h-5" />
          <h3 className="font-semibold text-lg">Metadata Information</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <MetadataField
            icon={Tag}
            label="Version"
            value={metadata.version}
          />
          <MetadataField
            icon={Clock}
            label="Timestamp"
            value={formatDate(metadata.timestamp)}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <MetadataField
            icon={FileText}
            label="Source Type"
            value={formatSourceType(metadata.source_type)}
          />
          {metadata.complexity_level && (
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <BarChart2 className="w-4 h-4 text-primary" />
              </div>
              <div>
                <p className="text-sm font-medium text-base-content/60">Complexity Level</p>
                <div className={`inline-flex items-center gap-2 px-3 py-1 rounded-lg mt-1 ${getComplexityColor(metadata.complexity_level)}`}>
                  <span className="font-medium capitalize">
                    {String(metadata.complexity_level)}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetadataResult;
