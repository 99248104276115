import React, { useEffect, useRef, useState } from 'react';
import {
  Tags,
  AlertTriangle,
  BookDown,
  HeartPulse,
  FileText,
  Lightbulb,
  DatabaseBackup,
  User,
  Calendar,
  Lock,
  Copy,
  X,
  MailQuestion
} from 'lucide-react';
import { useUpdates } from '../../contexts/UpdatesContext';

const ProcessingItem = ({ done, Icon, label, color }) => (
  <div className={`flex items-center gap-3 p-3 rounded-lg border ${done ? 'border-success bg-success/10' : 'border-base-200'}`}>
    <Icon className={`h-5 w-5 ${done ? color : 'text-base-300'}`} />
    <span className={`flex-1 ${done ? 'text-base-content' : 'text-base-300'}`}>
      {label}
    </span>
    {!done && (
      <span className="loading loading-spinner loading-sm text-base-300" />
    )}
    {done && (
      <div className="badge badge-success badge-sm">Done</div>
    )}
  </div>
);

const QuickResultPreviewModal = ({ isOpen, onClose, content }) => {
  const dialogRef = useRef(null);
  const [tagging, setTagging] = useState(false);
  const [summary, setSummary] = useState(false);
  const [duplicateDetection, setDuplicateDetection] = useState(false);
  const [actionableInsights, setActionableInsights] = useState(false);
  const [dateExtraction, setDateExtraction] = useState(false);
  const [piiDetection, setPiiDetection] = useState(false);
  const [sentiment, setSentiment] = useState(false);
  const [namedEntityRecognition, setNamedEntityRecognition] = useState(false);
  const [contentIntent, setContentIntent] = useState(false);
  const [category, setCategory] = useState(false);
  const [anomalyDetection, setAnomalyDetection] = useState(false);
  const [contentMetadata, setContentMetadata] = useState(false);
  const [spamExtraction, setSpamExtraction] = useState(false);
  const updates = useUpdates();

  useEffect(() => {
    if (!dialogRef.current) return;
    if (isOpen) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [isOpen]);

  useEffect(() => {
    if (updates.type === 'content_tagging.done') {
      setTagging(true);
    }
    if (updates.type === 'content_summary.done') {
      setSummary(true);
    }
    if (updates.type === 'duplicate_detection.done') {
      setDuplicateDetection(true);
    }
    if (updates.type === 'content_actionable_insights.done') {
      setActionableInsights(true);
    }
    if (updates.type === 'content_date_extraction.done') {
      setDateExtraction(true);
    }
    if (updates.type === 'content_pii_detection.done') {
      setPiiDetection(true);
    }
    if (updates.type === 'content_sentiment.done') {
      setSentiment(true);
    }
    if (updates.type === 'content_named_entity.done') {
      setNamedEntityRecognition(true);
    }
    if (updates.type === 'content_intent.done') {
      setContentIntent(true);
    }
    if (updates.type === 'content_category.done') {
      setCategory(true);
    }
    if (updates.type === 'content_anomaly.done') {
      setAnomalyDetection(true);
    }
    if (updates.type === 'content_metadata.done') {
      setContentMetadata(true);
    }
    if (updates.type === 'content_spam_extraction.done') {
      setSpamExtraction(true);
    }
  }, [updates]);

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const processingStates = [
    { state: tagging, Icon: Tags, label: 'Tagging', color: 'text-primary' },
    { state: summary, Icon: FileText, label: 'Summary', color: 'text-secondary' },
    { state: duplicateDetection, Icon: Copy, label: 'Duplicate Detection', color: 'text-accent' },
    { state: actionableInsights, Icon: Lightbulb, label: 'Actionable Insights', color: 'text-primary' },
    { state: dateExtraction, Icon: Calendar, label: 'Date Extraction', color: 'text-secondary' },
    { state: piiDetection, Icon: Lock, label: 'PII Detection', color: 'text-accent' },
    { state: sentiment, Icon: HeartPulse, label: 'Sentiment Analysis', color: 'text-primary' },
    { state: namedEntityRecognition, Icon: User, label: 'Named Entity Recognition', color: 'text-secondary' },
    { state: contentIntent, Icon: DatabaseBackup, label: 'Content Intent', color: 'text-accent' },
    { state: category, Icon: BookDown, label: 'Category Detection', color: 'text-primary' },
    { state: anomalyDetection, Icon: AlertTriangle, label: 'Anomaly Detection', color: 'text-secondary' },
    { state: contentMetadata, Icon: FileText, label: 'Metadata Extraction', color: 'text-accent' },
    { state: spamExtraction, Icon: MailQuestion, label: 'Spam Extraction', color: 'text-accent' }
  ];

  return (
    <dialog ref={dialogRef} className="modal">
      <div className="modal-box relative max-w-3xl">
        <div className="flex items-center justify-between mb-6">
          <h3 className="font-bold text-lg">Processing {content?.title}</h3>
          <button
            onClick={handleClose}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            <X className="h-4 w-4" />
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
          {processingStates.map(({ state, Icon, label, color }) => (
            <ProcessingItem
              key={label}
              done={state}
              Icon={Icon}
              label={label}
              color={color}
            />
          ))}
        </div>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  );
};

export default QuickResultPreviewModal;
