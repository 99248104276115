import React from 'react';
import {
  AlertTriangle,
  Tags,
  User,
  Calendar,
  Lock,
  Copy,
  Bot,
  FileText,
  Smile,
  MailQuestion,
  ShoppingCart,
  MapPin,
  Users,
  Link,
  Lightbulb,
  ChartBar,
  Hospital,
  BookDown,
  HeartPulse,
  DatabaseBackup,
  Clock,
  CalendarClock
} from "lucide-react";

const statsConfig = [
  {
    key: "total_tokens",
    icon: Bot,
    title: "Total Tokens Processed",
    tooltip: "Total number of tokens processed",
    colorClass: "text-primary",
    bgClass: "bg-primary/20",
    ariaLabel: "Token Processing Stats"
  },
  {
    key: "number_of_contents",
    icon: FileText,
    title: "Number of Contents",
    tooltip: "Total content items processed",
    colorClass: "text-secondary",
    bgClass: "bg-secondary/20",
    ariaLabel: "Content Count Stats"
  },
  {
    key: "metadata_extraction",
    icon: DatabaseBackup,
    title: "Metadata Extraction",
    tooltip: "Metadata Extractor",
    colorClass: "text-accent",
    bgClass: "bg-accent/20",
    ariaLabel: "Metadata Extraction Feature"
  },
  {
    key: "anomalies_detected",
    icon: AlertTriangle,
    title: "Anomalies Detected",
    tooltip: "Anomaly Detector",
    colorClass: "text-warning",
    bgClass: "bg-warning/20",
    ariaLabel: "Anomaly Detection Feature"
  },
  {
    key: "tags",
    icon: Tags,
    title: "Tags",
    tooltip: "Tag Detection",
    colorClass: "text-info",
    bgClass: "bg-info/20",
    ariaLabel: "Tag Detection Feature"
  },
  {
    key: "categorization",
    icon: BookDown,
    title: "Categorization",
    tooltip: "Category Detection",
    colorClass: "text-success",
    bgClass: "bg-success/20",
    ariaLabel: "Category Detection Feature"
  },
  {
    key: "sentiment",
    icon: HeartPulse,
    title: "Sentiment",
    tooltip: "Sentiment Analysis",
    colorClass: "text-error",
    bgClass: "bg-error/20",
    ariaLabel: "Sentiment Analysis Feature"
  },
  {
    key: "summaries",
    icon: FileText,
    title: "Summaries",
    tooltip: "Summary Generation",
    colorClass: "text-primary",
    bgClass: "bg-primary/20",
    ariaLabel: "Summary Generation Feature"
  },
  {
    key: "entities",
    icon: User,
    title: "Entities",
    tooltip: "Named Entity Recognition",
    colorClass: "text-secondary",
    bgClass: "bg-secondary/20",
    ariaLabel: "Named Entity Recognition Feature"
  },
  {
    key: "date_extraction",
    icon: Calendar,
    title: "Date Extraction",
    tooltip: "Date Extraction",
    colorClass: "text-accent",
    bgClass: "bg-accent/20",
    ariaLabel: "Date Extraction Feature"
  },
  {
    key: "pii_detection",
    icon: Lock,
    title: "PII Detection",
    tooltip: "PII Detection",
    colorClass: "text-info",
    bgClass: "bg-info/20",
    ariaLabel: "PII Detection Feature"
  },
  {
    key: "duplicate_detection",
    icon: Copy,
    title: "Duplicate Detection",
    tooltip: "Duplicate Detection",
    colorClass: "text-success",
    bgClass: "bg-success/20",
    ariaLabel: "Duplicate Detection Feature"
  },
  {
    key: "emotion",
    icon: Smile,
    title: "Emotion Detection",
    tooltip: "Emotion Detection",
    colorClass: "text-warning",
    bgClass: "bg-warning/20",
    ariaLabel: "Emotion Detection Feature"
  },
  {
    key: "spam_detection",
    icon: MailQuestion,
    title: "Spam Detection",
    tooltip: "Spam Detection",
    colorClass: "text-error",
    bgClass: "bg-error/20",
    ariaLabel: "Spam Detection Feature"
  },
  {
    key: "product_categorization",
    icon: ShoppingCart,
    title: "Product Categorization",
    tooltip: "Product Categorization",
    colorClass: "text-primary",
    bgClass: "bg-primary/20",
    ariaLabel: "Product Categorization Feature"
  },
  {
    key: "address_validation",
    icon: MapPin,
    title: "Address Validation",
    tooltip: "Address Validation",
    colorClass: "text-secondary",
    bgClass: "bg-secondary/20",
    ariaLabel: "Address Validation Feature"
  },
  {
    key: "medical_term_extraction",
    icon: Hospital,
    title: "Medical Term Extraction",
    tooltip: "Medical Term Extraction",
    colorClass: "text-accent",
    bgClass: "bg-accent/20",
    ariaLabel: "Medical Term Extraction Feature"
  },
  {
    key: "urgency_detection",
    icon: Clock,
    title: "Urgency Detection",
    tooltip: "Urgency Detection",
    colorClass: "text-error",
    bgClass: "bg-error/20",
    ariaLabel: "Urgency Detection Feature"
  },
  {
    key: "competitor_mention_detection",
    icon: Users,
    title: "Competitor Mention Detection",
    tooltip: "Competitor Mention Detection",
    colorClass: "text-info",
    bgClass: "bg-info/20",
    ariaLabel: "Competitor Mention Detection Feature"
  },
  {
    key: "deadline_detection",
    icon: CalendarClock,
    title: "Deadline Detection",
    tooltip: "Deadline Detection",
    colorClass: "text-warning",
    bgClass: "bg-warning/20",
    ariaLabel: "Deadline Detection Feature"
  },
  {
    key: "kpi_extraction",
    icon: ChartBar,
    title: "KPI Extraction",
    tooltip: "KPI Extraction",
    colorClass: "text-success",
    bgClass: "bg-success/20",
    ariaLabel: "KPI Extraction Feature"
  },
  {
    key: "citation_detection",
    icon: Link,
    title: "Citation Detection",
    tooltip: "Citation Detection",
    colorClass: "text-primary",
    bgClass: "bg-primary/20",
    ariaLabel: "Citation Detection Feature"
  },
  {
    key: "actionable_insights",
    icon: Lightbulb,
    title: "Actionable Insights",
    tooltip: "Actionable Insights",
    colorClass: "text-secondary",
    bgClass: "bg-secondary/20",
    ariaLabel: "Actionable Insights Feature"
  }
];

const StatsCard = ({ icon: Icon, title, value, tooltip, colorClass, bgClass, ariaLabel }) => {
  return (
    <div className="card bg-base-100 shadow-xl hover:shadow-2xl transition-all duration-200"
      data-tip={tooltip}
      aria-label={ariaLabel}>
      <div className="card-body p-6">
        <div className="flex items-center space-x-4">
          <div className={`p-2 rounded-lg ${bgClass}`}>
            <Icon className={`w-6 h-6 ${colorClass}`} />
          </div>
          <div>
            <h3 className="text-sm opacity-70">{title}</h3>
            <p className="text-2xl font-bold">{value || '0'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatsInfo = ({ stats }) => {
  return (
    <div className="p-6 bg-base-200 rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {statsConfig.map((stat) => (
          <StatsCard
            key={stat.key}
            icon={stat.icon}
            title={stat.title}
            value={stats[stat.key]}
            tooltip={stat.tooltip}
            colorClass={stat.colorClass}
            bgClass={stat.bgClass}
            ariaLabel={stat.ariaLabel}
          />
        ))}
      </div>
    </div>
  );
};

export default StatsInfo;
