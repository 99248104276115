import React from "react";
import TagResult from "./TagResult";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import AIAgentCard from "../../common/AIAgentCard";
const TagsResult = ({ tagging, onRerun }) => {
  const tags = tagging?.result?.response?.tags || tagging?.result?.tags || [];
  const agent_name = tagging?.agent_name;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2 mb-6">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('tags')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(tags)} copyKey={'tags'} />
        </div>
      </div>

      <div className="flex flex-wrap gap-4 flex-col">
        {tags?.map((tag, index) => (
          <TagResult key={index} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export default TagsResult;
