import React, { useEffect, useState } from 'react';
import {
  UtilityPole, CircleUserRoundIcon, Settings,
  CreditCard, LogOut, AlertCircle
} from 'lucide-react';
import LatestNotifications from '../components/header/LatestNotifications';
import { useAuth } from '../contexts/AuthContext';
import { useUpdates } from '../contexts/UpdatesContext';
const Header = ({
  projectsURL,
  latestActivitiesPath,
  newUserSubscriptionsPath,
  settingsPath,
  userPortalsPath,
  logoutPath,
  appLogo,
  dashboardPath
}) => {
  const currentUser = useAuth();
  const updates = useUpdates()
  const [newNotifications, setNewNotifications] = useState(false)
  useEffect(() => {
    if (updates.type === 'new_notification') {
      setNewNotifications(true)
    }
  }, [updates])

  const calculateUsagePercentage = () => {
    if (!currentUser?.current_limit_content || !currentUser?.limit_content) return 0;
    const percentage = (currentUser.current_limit_content / currentUser.limit_content) * 100;
    return Math.min(Math.max(percentage, 0), 100); // Clamp between 0-100
  };

  const getProgressColor = (percentage) => {
    if (percentage <= 60) return 'progress-success';
    if (percentage <= 90) return 'progress-warning';
    return 'progress-error';
  };

  const handleLogout = (e) => {
    e.preventDefault();
    try {
      const csrfToken = document.querySelector('[name="csrf-token"]')?.content;
      if (!csrfToken) {
        console.error('CSRF token not found');
        return;
      }

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = logoutPath;

      const appendInput = (name, value) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
      };

      appendInput('authenticity_token', csrfToken);
      appendInput('_method', 'DELETE');

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const usagePercentage = calculateUsagePercentage();
  const showUsageWarning = usagePercentage > 90;

  return (
    <header className="fixed top-0 left-0 right-0 border-b border-gray-400 z-50 shadow-lg bg-base-100">
      <div className="flex items-center justify-between px-6 py-3">
        <div className="flex items-center gap-4">
          <a href={dashboardPath} className="flex items-center px-3 py-1.5">
            <img src={appLogo} alt="neuropink.ai" className="h-8" />
          </a>
          <a
            href={projectsURL}
            className="btn btn-outline btn-ghost btn-sm inline-flex items-center gap-2 underline underline-offset-4"
            aria-label="View My Projects"
          >
            <span>My Projects</span>
            <UtilityPole className="w-5 h-5" />
          </a>
        </div>

        <div className="flex items-center gap-6">
          <div className="flex items-center gap-4">
            <div className="text-xs text-gray-600 mt-1 text-center">
              {Math.round(usagePercentage)}% used
              {showUsageWarning && (
                <AlertCircle className="w-4 h-4 text-error inline ml-1" />
              )}
            </div>
            <div className="w-32">
              <progress
                className={`progress progress-sm w-full ${getProgressColor(usagePercentage)}`}
                value={usagePercentage}
                max="100"
                aria-label={`Usage: ${Math.round(usagePercentage)}%`}
              />
            </div>
          </div>

          <LatestNotifications
            newNotifications={newNotifications}
            currentUser={currentUser}
            latestActivitiesPath={latestActivitiesPath}
          />

          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="flex items-center gap-3 cursor-pointer hover:opacity-80"
              aria-label="User menu"
            >
              <span className="text-sm text-gray-600">{currentUser?.email}</span>
              <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                <CircleUserRoundIcon className="w-6 h-6 text-primary" />
              </div>
            </div>

            <ul
              tabIndex={0}
              className="dropdown-content z-10 menu p-3 shadow-lg bg-base-100 rounded-lg w-56 mt-3 border border-gray-200"
              role="menu"
            >
              <li role="none">
                <a
                  href={settingsPath}
                  className="flex items-center gap-3 text-sm py-2.5 text-gray-700 hover:text-primary"
                  role="menuitem"
                >
                  <Settings className="w-5 h-5" />
                  Settings
                </a>
              </li>
              <li role="none">
                <a
                  href={newUserSubscriptionsPath}
                  className="flex items-center gap-3 text-sm py-2.5 text-gray-700 hover:text-primary"
                  role="menuitem"
                >
                  <CreditCard className="w-5 h-5" />
                  Payments
                </a>
              </li>
              <li role="none">
                <a
                  href={userPortalsPath}
                  className="flex items-center gap-3 text-sm py-2.5 text-gray-700 hover:text-primary"
                  role="menuitem"
                >
                  <CreditCard className="w-5 h-5" />
                  Billing Portal
                </a>
              </li>
              <div className="divider my-1" role="separator"></div>
              <li role="none">
                <a
                  href="#"
                  onClick={handleLogout}
                  className="flex items-center gap-3 text-sm py-2.5 text-error hover:bg-error/10"
                  role="menuitem"
                >
                  <LogOut className="w-5 h-5" />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
