import React, { createContext, useContext, useEffect, useState } from 'react'
import consumer from "../../channels/consumer"

const UpdatesContext = createContext(null)

export const UpdatesProvider = ({ children }) => {
  const [updates, setUpdates] = useState({})

  useEffect(() => {
    // TODO extract channel name from params
    const subscription = consumer.subscriptions.create("UpdatesChannel", {
      received: (data) => setUpdates(JSON.parse(data))
    })
    return () => subscription.unsubscribe()
  }, [])

  return (
    <UpdatesContext.Provider value={updates}>
      {children}
    </UpdatesContext.Provider>
  )
}

export const useUpdates = () => {
  return useContext(UpdatesContext)
}

