import React, { useState, useEffect } from 'react';
import { CreditCard, BarChart, Download, Trash2, ChevronRight, X, Loader, Tags, BookDown, HeartPulse, FileText, Lightbulb, DatabaseBackup, User, AlertTriangle, FolderOpen, Mail, Code, Radio } from 'lucide-react';
import StatsModal from '../components/settings/StatsModal';
import SubscriptionInfoModal from '../components/settings/SubscriptionInfoModal';
import DownloadModal from '../components/settings/DownloadModal';
import DeleteModal from '../components/settings/DeleteModal';
import { useAuth } from '../contexts/AuthContext';
const SettingsOption = ({
  icon: Icon,
  title,
  description,
  onClick,
  iconColor = 'text-gray-600',
  bgColor = 'bg-gray-100',
  loading = false,
  error = null,
  stats = null
}) => (
  <button
    onClick={onClick}
    className="w-full p-4 flex items-center justify-between hover:bg-gray-100 rounded-lg transition-colors"
    aria-label={title}
    disabled={loading}
  >
    <div className="flex items-center gap-4">
      <div className="bg-gray-100 p-2 rounded-lg">
        <Icon className={`w-5 h-5 transition-colors ${iconColor}`} />
      </div>
      <div className="text-left">
        <h3 className="font-medium text-gray-900">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
        {loading && (
          <p className="text-sm text-blue-500 flex items-center mt-1">
            <Loader className="w-3 h-3 mr-1 animate-spin" /> Loading stats...
          </p>
        )}
        {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
        {stats && (
          <div className="mt-2 grid grid-cols-2 gap-2">
            <div className="text-xs text-gray-600">
              <span className="font-medium">Tokens:</span> {stats?.total_tokens?.toLocaleString() || 0}
            </div>
            <div className="text-xs text-gray-600">
              <span className="font-medium">Projects:</span> {stats?.number_of_projects || 0}
            </div>
          </div>
        )}
      </div>
    </div>
    <ChevronRight className="w-5 h-5 text-gray-400" />
  </button>
);

const Settings = ({ accountStatsPath }) => {
  const [activeOption, setActiveOption] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [accountStats, setAccountStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useAuth()
  const fetchAccountStats = async () => {
    if (!accountStatsPath) return;

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(accountStatsPath, {
        headers: { 'Accept': 'application/json' }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAccountStats(data);
    } catch (error) {
      console.error('Failed to fetch account stats:', error);
      setError('Failed to load account statistics');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchAccountStats();
  }, [accountStatsPath]);

  const handleOptionClick = (option) => {
    setActiveOption(option);
    setActiveModal(option);
  };

  const getIconStyles = (optionName) => {
    if (activeOption !== optionName) {
      return { iconColor: 'text-gray-600', bgColor: 'bg-gray-100' };
    }

    const styles = {
      subscription: { iconColor: 'text-purple-500', bgColor: 'bg-purple-100' },
      stats: { iconColor: 'text-blue-500', bgColor: 'bg-blue-100' },
      download: { iconColor: 'text-green-500', bgColor: 'bg-green-100' },
      delete: { iconColor: 'text-red-500', bgColor: 'bg-red-100' }
    };

    return styles[optionName] || { iconColor: 'text-gray-600', bgColor: 'bg-gray-100' };
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">
        Your information {currentUser?.name ? `- ${currentUser.name}` : ''}
      </h1>

      <div className="space-y-4">
        <SettingsOption
          icon={CreditCard}
          title="Subscription Information"
          description="View your subscription plan"
          onClick={() => handleOptionClick('subscription')}
          {...getIconStyles('subscription')}
        />

        <SettingsOption
          icon={BarChart}
          title="Statistics"
          description="View your usage and activity stats"
          onClick={() => handleOptionClick('stats')}
          {...getIconStyles('stats')}
          loading={loading}
          error={error}
          stats={accountStats}
        />

        <SettingsOption
          icon={Download}
          title="Download Data"
          description="Export all your data in a ZIP file"
          onClick={() => handleOptionClick('download')}
          {...getIconStyles('download')}
        />

        <div className="pt-4 border-t">
          <SettingsOption
            icon={Trash2}
            title="Delete Account"
            description="Permanently delete your account and all data"
            onClick={() => handleOptionClick('delete')}
            {...getIconStyles('delete')}
          />
        </div>
      </div>

      <SubscriptionInfoModal
        isOpen={activeModal === 'subscription'}
        onClose={() => setActiveModal(null)}
        currentUser={currentUser}
      />
      <StatsModal
        isOpen={activeModal === 'stats'}
        onClose={() => setActiveModal(null)}
        stats={accountStats}
      />
      <DownloadModal
        isOpen={activeModal === 'download'}
        onClose={() => setActiveModal(null)}
      />
      <DeleteModal
        isOpen={activeModal === 'delete'}
        onClose={() => setActiveModal(null)}
      />
    </div>
  );
};

export default Settings;
