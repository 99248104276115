import React from 'react';
import IndustryItem from './IndustryItem';

const IndustryResult = ({ industry }) => {
  if (!industry) return null;

  const hasValidPrimary = industry.primary && Object.keys(industry.primary).length > 0;
  const hasValidAlternatives = Array.isArray(industry.alternatives) && industry.alternatives.length > 0;

  if (!hasValidPrimary && !hasValidAlternatives) return null;

  return (
    <div className="flex gap-4 flex-col border shadow-md">
      {hasValidPrimary && (
        <IndustryItem type="primary" data={industry.primary} />
      )}
      {hasValidAlternatives &&
        industry.alternatives.map((alt, index) => (
          <IndustryItem
            key={`alt-${index}-${alt.id || index}`}
            type="alternative"
            data={alt}
          />
        ))
      }
    </div>
  );
};

export default IndustryResult;
