import React from 'react';
import { Calendar, RotateCcw, PieChart } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const DeadlinesDisplay = ({ deadlines = [] }) => {
  if (!Array.isArray(deadlines) || deadlines?.length === 0) return null;

  const getTypeBadgeColor = (type) => {
    switch (type) {
      case 'hard': return 'error';
      case 'soft': return 'warning';
      case 'recurring': return 'info';
      default: return 'neutral';
    }
  };

  const getPriorityBadgeColor = (priority) => {
    switch (priority) {
      case 'high': return 'error';
      case 'medium': return 'warning';
      case 'low': return 'success';
      default: return 'neutral';
    }
  };

  const getBadgeColor = (confidence) => {
    const score = parseFloat(confidence);
    if (isNaN(score)) return 'neutral';
    if (score >= 0.8) return 'success';
    if (score >= 0.5) return 'warning';
    return 'error';
  };

  const formatDate = (dateStr) => {
    try {
      return new Date(dateStr).toLocaleString();
    } catch (e) {
      return dateStr;
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Calendar className="w-4 h-4" />
          Deadlines Found
        </h3>
        <div className="space-y-4">
          {deadlines.map((deadline, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <span className={`badge badge-${getTypeBadgeColor(deadline?.type)}`}>
                    {deadline?.type || 'Unknown Type'}
                  </span>
                  <span className={`badge badge-${getPriorityBadgeColor(deadline?.context?.priority)}`}>
                    {deadline?.context?.priority || 'No Priority'} Priority
                  </span>
                </div>
                <span className={`badge badge-${getBadgeColor(deadline?.confidence)}`}>
                  {deadline?.confidence ?
                    `${(deadline.confidence * 100).toFixed(1)}% Confidence` :
                    'No Score'}
                </span>
              </div>
              <div className="space-y-2">
                <div>
                  <p className="text-sm font-medium">{deadline?.description || 'No description'}</p>
                  <p className="text-sm text-base-content/70">
                    Due: {deadline?.date ? formatDate(deadline?.date) : 'No Date'}
                  </p>
                </div>

                {deadline?.context?.dependencies && deadline?.context?.dependencies?.length > 0 && (
                  <div>
                    <p className="text-xs text-base-content/70">Dependencies:</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {deadline?.context?.dependencies?.map((dep, idx) => (
                        <span key={idx} className="badge badge-sm badge-ghost">
                          {dep}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {deadline?.context?.stakeholders && deadline?.context?.stakeholders?.length > 0 && (
                  <div>
                    <p className="text-xs text-base-content/70">Stakeholders:</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {deadline.context.stakeholders.map((stakeholder, idx) => (
                        <span key={idx} className="badge badge-sm badge-secondary">
                          {stakeholder}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.detection_metrics) return null;

  const getAmbiguityLevelColor = (level) => {
    switch (level) {
      case 'low': return 'success';
      case 'medium': return 'warning';
      case 'high': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Detection Metrics
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Confidence Score</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(metadata?.detection_metrics?.confidence_score || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Completeness</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-secondary rounded-full"
                style={{ width: `${(metadata?.detection_metrics?.completeness || 0) * 100}%` }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">Ambiguity Level:</span>
            <span className={`badge badge-${getAmbiguityLevelColor(metadata?.detection_metrics?.ambiguity_level)}`}>
              {metadata?.detection_metrics?.ambiguity_level || 'unknown'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeadlineDetectionResults = ({ deadlineDetection, onRerun }) => {
  const getResultData = (data) => {
    return {
      status: data?.response?.status || data?.status || 'unknown',
      deadlines: data?.response?.deadline_analysis?.deadlines ||
        data?.deadline_analysis?.deadlines || [],
      metadata: data?.response?.metadata || data?.metadata
    };
  };

  const resultData = getResultData(deadlineDetection?.result);

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={deadlineDetection?.agent_name || 'Unknown Agent'} />
              <ResultStatus status={resultData?.status} />
            </p>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('deadlineDetection')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(deadlineDetection?.result || {})}
              copyKey="deadline-detection"
            />
          </div>
        </div>

        {deadlineDetection?.result && (
          <div className="space-y-4">
            <DeadlinesDisplay deadlines={resultData?.deadlines} />
            <MetadataDisplay metadata={resultData?.metadata} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DeadlineDetectionResults;
