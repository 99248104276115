import React, { useState } from 'react'
import SettingsModal from './SettingsModal'
import { Loader } from 'lucide-react'

const DeleteModal = ({ isOpen, onClose }) => {
  const [confirmText, setConfirmText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    if (confirmText !== 'DELETE') return;

    setLoading(true);
    setError(null);
    try {
      // Account deletion logic would go here
      throw new Error('Account deletion not implemented');
    } catch (error) {
      console.error('Deletion failed:', error);
      setError('Failed to delete account. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsModal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Account"
      className="border-error border-2"
    >
      <div className="space-y-4">
        <div className="alert alert-error">
          <span>This action cannot be undone. Please be certain.</span>
        </div>

        {error && (
          <div className="alert alert-error">
            <span>{error}</span>
          </div>
        )}

        <div className="form-control">
          <label className="label">
            <span className="label-text">Type "DELETE" to confirm</span>
          </label>
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder="DELETE"
            className="input input-bordered"
          />
        </div>

        <button
          className="btn btn-error w-full"
          onClick={handleDelete}
          disabled={loading || confirmText !== 'DELETE'}
        >
          {loading && <Loader className="w-4 h-4 mr-2 animate-spin" />}
          Permanently Delete Account
        </button>
      </div>
    </SettingsModal>
  );
};


export default DeleteModal;
