import { Key } from 'lucide-react';
import CopyButton from '../../common/CopyButton';
import React from 'react';

const APISource = ({ disabled, apiToken, project, onToggle, submitApiV1ProjectUrl, baseUrl }) => {
  const curlExample = (
    <>
      <pre data-prefix="$"><code>{`curl -X POST ${baseUrl}${submitApiV1ProjectUrl} \\`}</code></pre>
      <pre data-prefix=""><code>{`  -H "API-TOKEN: ${project?.api_token}" \\`}</code></pre>
      <pre data-prefix=""><code>  -H "Content-Type: application/json" \</code></pre>
      <pre data-prefix=""><code>{`  -d '{"content": "What is the most amazing city in the universe, and please dont tell me is on Mars", "title": "title: ${new Date().toISOString()}"}' `}</code></pre>
    </>
  );

  const curlText = `curl -X POST ${baseUrl}/${submitApiV1ProjectUrl} \\\n  -H "API-TOKEN: ${project?.api_token}" \\\n  -H "Content-Type: application/json" \\\n  -d '{"content": "What is the most amazing city in the universe, and please dont tell me is on Mars", "title": "title: ${new Date().toISOString()}"}'`;

  return (
    <div className={`space-y-4 ${disabled ? 'opacity-[.85]' : ''}`}>
      <div className="flex items-center justify-between bg-base-200 p-4 rounded-lg">
        <div className="flex items-center gap-3">
          <Key className={`h-5 w-5 ${disabled ? 'text-base-content/50' : 'text-primary'}`} />
          <div>
            <p className="font-medium">API Token</p>
            <p className="text-sm font-mono text-base-content/70">{apiToken}</p>
          </div>
        </div>
        {project?.has_api_access && <CopyButton text={apiToken} copyKey="apiToken" />}
      </div>

      <div className="mockup-code text-sm overflow-y-scroll h-48 w-full">
        {curlExample}
      </div>

      <div className="flex justify-end gap-2">
        {project?.has_api_access && <CopyButton text={curlText} copyKey="curlCommand" />}
        <button
          onClick={onToggle}
          className="btn btn-sm btn-primary"
        >
          {project?.has_api_access ? 'Disable API Access' : 'Enable API Access'}
        </button>
      </div>
    </div>
  );
};

export default APISource;
