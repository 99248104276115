import React, { useState } from 'react';
import { Zap, Key, FileText, Copy, Check } from 'lucide-react';
import CopyButton from '../../common/CopyButton';

const ProjectFormWebhook = ({ project, onUpdate }) => {
  const [isEnabled, setIsEnabled] = useState(project?.webhook_enabled || false);
  const [webhookUrl, setWebhookUrl] = useState(project?.webhook_url || '');
  const [error, setError] = useState('');

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setWebhookUrl(url);

    if (!url) {
      setError('Please fill in this field.');
    } else if (!validateUrl(url)) {
      setError('Please enter a valid URL.');
    } else {
      setError('');
    }
  };

  const handleSubmit = () => {
    if (!validateUrl(webhookUrl)) {
      setError('Please enter a valid URL.');
      return;
    }

    onUpdate({
      webhook_enabled: true,
      webhook_url: webhookUrl
    });
  };

  const handleToggleWebhook = () => {
    setIsEnabled(prev => !prev);
    if (isEnabled) {
      setWebhookUrl('') // we reset the url when disabling
      onUpdate({ webhook_enabled: false, webhook_url: null });
    }
  };

  const InfoCard = ({ icon: Icon, title, value, copyKey, monospace }) => (
    <div className="flex items-start gap-4 p-4 rounded-lg border border-base-300">
      <div className="p-2 rounded-lg text-base-content">
        <Icon className="w-5 h-5" />
      </div>
      <div className="flex-grow">
        <h3 className="text-sm font-medium mb-1">{title}</h3>
        <div className={`text-sm break-all ${monospace ? 'font-mono' : ''}`}>
          {value}
        </div>
      </div>
      {copyKey && <CopyButton value={value} />}
    </div>
  );

  return (
    <div className="card bg-base-100 border border-success/20 shadow-xl p-6">
      <div className="form-control">
        <label className="cursor-pointer flex items-center gap-3">
          <input
            type="checkbox"
            className="toggle toggle-success"
            checked={isEnabled}
            onChange={handleToggleWebhook}
          />
          <span className="label-text">{isEnabled ? 'Disable Webhook' : 'Enable Webhook'}</span>
        </label>
      </div>

      {!isEnabled ? (
        <div className="mt-4 text-sm opacity-50">
          Enable webhooks to receive real-time notifications about content updates
        </div>
      ) : (
        <div className="space-y-4 mt-4">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-base font-medium">
                Webhook URL
                <span className="text-error">*</span>
              </span>
            </label>
            <input
              type="text"
              value={webhookUrl}
              onChange={handleUrlChange}
              className={`input input-bordered w-full ${error ? 'input-error' : ''}`}
              placeholder="https://api.your-server.com/webhook"
            />
            {error && (
              <label className="label">
                <span className="label-text-alt text-error">{error}</span>
              </label>
            )}
          </div>

          {webhookUrl && !error && (
            <>
              <InfoCard
                icon={Key}
                title="Signing Secret"
                value={project?.webhook_secret}
                copyKey
                monospace
              />
              <InfoCard
                icon={FileText}
                title="Auth Token"
                value={project?.webhook_token}
                copyKey
                monospace
              />
            </>
          )}

          <div className="flex justify-end mt-6">
            <button
              className="btn btn-primary gap-2 text-white"
              onClick={handleSubmit}
              disabled={isEnabled && (!!error || !webhookUrl)}
            >
              <Zap className="w-4 h-4" />
              Update Webhook
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectFormWebhook;
