import { X } from 'lucide-react'
import React from 'react'

const SettingsModal = ({ isOpen, onClose, title, children, className = '' }) => {
  if (!isOpen) return null;

  return (
    <dialog className="modal modal-open">
      <div className={`modal-box relative max-w-5xl w-full max-h-[90vh] overflow-y-auto ${className}`}>
        <button
          onClick={onClose}
          className="btn btn-sm btn-circle absolute right-2 top-2"
          aria-label="Close modal"
        >
          <X className="w-4 h-4" />
        </button>
        <h3 className="font-bold text-lg mb-4">{title}</h3>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default SettingsModal;
