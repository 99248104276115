import React, { useState, useCallback } from 'react';
import {
  Tags, List, Heart, FileText, AlertTriangle, User,
  Database, Lightbulb, Sparkles, Calendar, Lock, Copy, MailQuestion, Smile, ShoppingCart, MapPin, Link, ChartBar, Users, Clock, CalendarClock, Hospital
} from 'lucide-react';

const AIAgentSelector = ({ onAgentChange }) => {
  const [selectedAgents, setSelectedAgents] = useState([]);

  const agents = [
    { id: 'has_tags', label: 'Tag Detection', icon: <Tags className="h-5 w-5" /> },
    { id: 'has_category', label: 'Categorization', icon: <List className="h-5 w-5" /> },
    { id: 'has_sentiment', label: 'Sentiment Analysis', icon: <Heart className="h-5 w-5" /> },
    { id: 'has_summary', label: 'Summarization', icon: <FileText className="h-5 w-5" /> },
    { id: 'has_anomaly_detector', label: 'Anomaly Detection', icon: <AlertTriangle className="h-5 w-5" /> },
    { id: 'has_named_entity_recognition', label: 'Entity Recognition', icon: <User className="h-5 w-5" /> },
    { id: 'has_metadata_extractor', label: 'Metadata Extraction', icon: <Database className="h-5 w-5" /> },
    { id: 'has_actionable_insights', label: 'Actionable Insights', icon: <Lightbulb className="h-5 w-5" /> },
    { id: 'has_date_extraction', label: 'Date Extraction', icon: <Calendar className="h-5 w-5" /> },
    { id: 'has_pii_detection', label: 'PII Detection', icon: <Lock className="h-5 w-5" /> },
    { id: 'has_duplicate_detection', label: 'Duplicate Detection', icon: <Copy className="h-5 w-5" /> },
    { id: 'has_spam_extraction', label: 'Spam Detection', icon: <MailQuestion className="h-5 w-5" /> },
    { id: 'has_emotion', label: 'Emotion Detection', icon: <Smile className="h-5 w-5" /> },
    { id: 'has_product_categorization', label: 'Product Categorization', icon: <ShoppingCart className="h-5 w-5" /> },
    { id: 'has_address_validation', label: 'Address Validation', icon: <MapPin className="h-5 w-5" /> },
    { id: 'has_citation_detector', label: 'Citation Detection', icon: <Link className="h-5 w-5" /> },
    { id: 'has_kpi_extractor', label: 'KPI Extraction', icon: <ChartBar className="h-5 w-5" /> },
    { id: 'has_competitor_mention_detector', label: 'Competitor Mention Detection', icon: <Users className="h-5 w-5" /> },
    { id: 'has_urgency_detector', label: 'Urgency Detection', icon: <Clock className="h-5 w-5" /> },
    { id: 'has_deadline_detector', label: 'Deadline Detection', icon: <CalendarClock className="h-5 w-5" /> },
    { id: 'has_medical_term_extractor', label: 'Medical Term Extraction', icon: <Hospital className="h-5 w-5" /> },
  ];

  const handleAgentToggle = useCallback((agentId) => {
    setSelectedAgents(prev => {
      const newAgents = prev.includes(agentId)
        ? prev.filter(id => id !== agentId)
        : [...prev, agentId];

      onAgentChange?.(newAgents);
      return newAgents;
    });
  }, [onAgentChange]);

  return (
    <div className="relative">
      <div className="flex gap-2">
        <div className="dropdown dropdown-bottom">
          <label
            tabIndex={0}
            className="btn btn-md btn-circle bg-base-200 hover:bg-base-300"
            aria-label="Select AI Agents"
            role="button"
          >
            <Sparkles
              className={`h-5 w-5 ${selectedAgents.length > 0 ? 'text-primary' : 'text-gray-500'}`}
              aria-hidden="true"
            />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow-lg bg-base-100 rounded-box w-72 mt-2 border overflow-y-auto h-64 block"
            role="listbox"
            aria-label="AI Agents"
          >
            {agents.map((agent) => (
              <li key={agent.id} role="option" aria-selected={selectedAgents.includes(agent.id)}>
                <label
                  className="flex items-center px-4 py-2 hover:bg-base-200 rounded-lg cursor-pointer"
                  htmlFor={`agent-${agent.id}`}
                >
                  <div className="flex items-center flex-1 gap-3">
                    {React.cloneElement(agent.icon, { 'aria-hidden': true })}
                    <span className="text-sm font-medium">{agent.label}</span>
                  </div>
                  <input
                    id={`agent-${agent.id}`}
                    type="checkbox"
                    className="checkbox checkbox-sm checkbox-primary"
                    checked={selectedAgents.includes(agent.id)}
                    onChange={() => handleAgentToggle(agent.id)}
                    aria-label={agent.label}
                  />
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AIAgentSelector;
