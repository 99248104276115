import React from 'react';
import { Boxes, LayoutList, ShoppingBag, RotateCcw, ArrowRight } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const PrimaryCategoryDisplay = ({ category }) => {
  if (!category) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <ShoppingBag className="w-4 h-4" />
          Primary Category
        </h3>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <span className="font-medium text-lg">{category?.name}</span>
            <span className="badge badge-primary">
              {(category?.confidence * 100).toFixed(1)}%
            </span>
          </div>
          {category.path && (
            <div className="flex flex-wrap items-center gap-2 text-sm text-base-content/70">
              {category?.path?.map((item, index) => (
                <React.Fragment key={index}>
                  <span>{item}</span>
                  {index < category?.path?.length - 1 && (
                    <ArrowRight className="w-4 h-4" />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="text-sm text-base-content/70">
            Level: {category?.level}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubcategoriesDisplay = ({ subcategories }) => {
  if (!subcategories?.length) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <LayoutList className="w-4 h-4" />
          Subcategories
        </h3>
        <div className="grid gap-4 md:grid-cols-2">
          {subcategories?.map((category, index) => (
            <div key={index} className="p-3 bg-base-300 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="font-medium">{category?.name}</span>
                <span className="badge badge-secondary">
                  {(category?.confidence * 100).toFixed(1)}%
                </span>
              </div>
              {category?.path && (
                <div className="flex flex-wrap items-center gap-2 text-sm text-base-content/70">
                  {category?.path?.map((item, index) => (
                    <React.Fragment key={index}>
                      <span>{item}</span>
                      {index < category?.path?.length - 1 && (
                        <ArrowRight className="w-4 h-4" />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ProductAnalysisDisplay = ({ analysis }) => {
  if (!analysis) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Boxes className="w-4 h-4" />
          Product Analysis
        </h3>
        <div className="space-y-4">
          {analysis.key_features?.length > 0 && (
            <div>
              <p className="text-sm font-medium mb-2">Key Features</p>
              <div className="flex flex-wrap gap-2">
                {analysis?.key_features?.map((feature, index) => (
                  <span key={index} className="badge badge-neutral">{feature}</span>
                ))}
              </div>
            </div>
          )}
          {analysis.detected_brands?.length > 0 && (
            <div>
              <p className="text-sm font-medium mb-2">Detected Brands</p>
              <div className="flex flex-wrap gap-2">
                {analysis?.detected_brands?.map((brand, index) => (
                  <span key={index} className="badge badge-primary">{brand}</span>
                ))}
              </div>
            </div>
          )}
          {analysis.related_categories?.length > 0 && (
            <div>
              <p className="text-sm font-medium mb-2">Related Categories</p>
              <div className="flex flex-wrap gap-2">
                {analysis?.related_categories?.map((category, index) => (
                  <span key={index} className="badge badge-secondary">{category}</span>
                ))}
              </div>
            </div>
          )}
          {analysis.market_segment && (
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium">Market Segment:</span>
              <span className="badge badge-accent">{analysis?.market_segment}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProductCategorizationResults = ({ productCategorization, onRerun }) => {
  if (!productCategorization) return null;

  const { status, result, agent_name } = productCategorization;
  const { classification, product_analysis } = result || {};

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={agent_name} />
            </p>
            <ResultStatus status={status} />
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('productCategorization')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(result)}
              copyKey={'product-categorization'}
            />
          </div>
        </div>

        <div className="space-y-4">
          <PrimaryCategoryDisplay category={classification?.primary_category} />
          <SubcategoriesDisplay subcategories={classification?.subcategories} />
          <ProductAnalysisDisplay analysis={product_analysis} />
        </div>
      </div>
    </div>
  );
};

export default ProductCategorizationResults;
