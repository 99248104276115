import React from 'react';
import {
  AlertTriangle,
  BarChart2,
  CheckCircle2,
  AlertCircle,
  FileText,
  User,
  Mail,
  CreditCard,
  Fingerprint,
  Globe, RotateCcw
} from 'lucide-react';
import CopyButton from '../../../common/CopyButton'
import ResultStatus from '../../common/ResultStatus'
import AIAgentCard from '../../common/AIAgentCard'

const RiskBadge = ({ level }) => {
  const config = {
    high: { color: 'text-error bg-error/10', icon: AlertTriangle },
    medium: { color: 'text-warning bg-warning/10', icon: AlertCircle },
    low: { color: 'text-success bg-success/10', icon: CheckCircle2 }
  };

  const { color, icon: Icon } = config[level] || config.low;

  return (
    <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium ${color}`}>
      <Icon className="w-3 h-3" />
      <span className="capitalize">{level} Risk</span>
    </div>
  );
};

const CategoryIcon = ({ category }) => {
  const icons = {
    personal_identifiers: User,
    contact_information: Mail,
    financial_information: CreditCard,
    biometric_data: Fingerprint,
    demographic_data: Globe
  };

  const Icon = icons[category] || FileText;
  return <Icon className="w-4 h-4" />;
};

const EntityList = ({ title, entities, category }) => {
  if (!Array.isArray(entities) || entities.length === 0) return null;

  // Get the icon component based on category
  const Icon = (() => {
    const icons = {
      personal_identifiers: User,
      contact_information: Mail,
      financial_information: CreditCard,
      biometric_data: Fingerprint,
      demographic_data: Globe
    };
    return icons[category] || FileText;
  })();

  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2">
        <div className="p-2 rounded-lg bg-primary/10">
          <Icon className="w-4 h-4 text-primary" />
        </div>
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="grid gap-3">
        {entities.map((entity, index) => (
          <div key={`entity-${index}`} className="p-3 rounded-lg bg-base-200/50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <CategoryIcon category={entity.category} />
                <span className="text-sm font-medium capitalize">
                  {entity.category.replace(/_/g, ' ')}
                </span>
              </div>
              <RiskBadge level={entity.risk_level} />
            </div>
            <div className="text-sm text-base-content/70 mb-2">{entity.value}</div>
            <div className="flex items-center gap-2">
              <div className="text-xs text-base-content/60">
                Confidence: {(entity.confidence * 100).toFixed(0)}%
              </div>
              {entity.mentions?.length > 1 && (
                <div className="text-xs text-base-content/60">
                  {entity.mentions.length} occurrences
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const SummaryStats = ({ metadata }) => {
  if (!metadata) return null;

  const { risk_summary, entity_counts, confidence_summary } = metadata;

  return (
    <div className="grid md:grid-cols-3 gap-4">
      <div className="p-4 rounded-lg bg-base-200/50">
        <div className="flex items-center gap-2 mb-3">
          <AlertTriangle className="w-4 h-4 text-warning" />
          <h4 className="font-medium">Risk Summary</h4>
        </div>
        <div className="grid gap-2">
          <div className="flex justify-between text-sm">
            <span>High Risk</span>
            <span className="font-medium">{risk_summary?.high_risk_count ?? 0}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Medium Risk</span>
            <span className="font-medium">{risk_summary?.medium_risk_count ?? 0}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span>Low Risk</span>
            <span className="font-medium">{risk_summary?.low_risk_count ?? 0}</span>
          </div>
        </div>
      </div>

      <div className="p-4 rounded-lg bg-base-200/50">
        <div className="flex items-center gap-2 mb-3">
          <BarChart2 className="w-4 h-4 text-primary" />
          <h4 className="font-medium">Entity Counts</h4>
        </div>
        <div className="text-sm">
          <div className="mb-2">
            <span className="font-medium">Total Entities: </span>
            {entity_counts?.total ?? 0}
          </div>
          <div className="grid gap-1 text-xs text-base-content/70">
            {entity_counts?.by_category && Object.entries(entity_counts?.by_category).map(([key, value]) => (
              <div key={key} className="flex justify-between">
                <span className="capitalize">{key.replace(/_/g, ' ')}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="p-4 rounded-lg bg-base-200/50">
        <div className="flex items-center gap-2 mb-3">
          <CheckCircle2 className="w-4 h-4 text-success" />
          <h4 className="font-medium">Confidence</h4>
        </div>
        <div className="text-sm">
          <div className="mb-2">
            <span className="font-medium">Average: </span>
            {((confidence_summary?.average ?? 0) * 100).toFixed(0)}%
          </div>
          <div className="grid gap-1 text-xs text-base-content/70">
            {confidence_summary?.by_category && Object.entries(confidence_summary?.by_category)
              .filter(([, value]) => value > 0)
              .map(([key, value]) => (
                <div key={key} className="flex justify-between">
                  <span className="capitalize">{key.replace(/_/g, ' ')}</span>
                  <span>{(value * 100).toFixed(0)}%</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const PIIDetectionResults = ({ piiDetections, onRerun }) => {

  const { agent_name, result: { metadata, pii_entities, status } } = piiDetections;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className={'flex gap-2'}>
            <AIAgentCard agent_name={agent_name} />
            <ResultStatus status={status} />
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('piiDetection')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw />
            </button>
            <CopyButton text={JSON.stringify(pii_entities)} copyKey={'pii-detection'} />
          </div>
        </div>

        <SummaryStats metadata={metadata} />

        <div className="grid md:grid-cols-2 gap-6">
          {pii_entities && Object.entries(pii_entities).map(([category, entities]) => (
            <EntityList
              key={category}
              title={category.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              entities={entities}
              icon={CategoryIcon({ category })}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PIIDetectionResults;
