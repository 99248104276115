import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FileText, Activity, Settings, Share2, BarChart2, Search,
  FolderOpen, Loader
} from 'lucide-react';
import ContentActionsDropdown from '../components/project/ContentActionsDropdown';
import ActivityLog from '../components/project/activity/ActivityLog';
import StatsInfo from '../components/project/stats/StatsInfo';
import FullTextSearch from '../components/project/search/FullTextSearch';
import SourcesInfo from '../components/common/SourcesInfo';
import ProjectFormName from '../components/project/settings/ProjectFormName';
import ProjectFormAgents from '../components/project/settings/ProjectFormAgents';
import ProjectFormWebhook from '../components/project/settings/ProjectFormWebhook';
import AddContentModal from '../components/project/AddContentModal';
import UppyUploadModal from '../components/project/UppyUploadModal';
import EmptyState from '../components/project/EmptyState';
import Contents from '../components/project/contents/Contents';
import SearchContent from '../components/project/contents/SearchContent';
import ContentFilter from '../components/project/ContentFilter';
import DeleteContentModal from '../components/project/contents/DeleteContentModal';
import DeleteProjectModal from '../components/project/DeleteProjectModal';
import utils from '../utils';
import { useParams } from '../contexts/ParamsProvider';
import { useAuth } from '../contexts/AuthContext';
import AIAgentSelector from '../components/common/AIAgentSelector';
import Features from '../components/common/Features';
import ActiveSources from '../components/projects/ActiveSources';

const Project = ({
  projectsURL,
  baseUrl,
  contentWidgetServerUrl,
  contentWidgetServerJs,
}) => {
  const [state, setState] = useState({
    activeTab: 'content',
    contents: [],
    project: null,
    activity: [],
    stats: [],
    results: [],
    contentToDelete: null,
    loading: true,
    error: null,
    pagination: { page: 1, pages: 1, count: 0 }
  });

  const [modals, setModals] = useState({
    addContent: false,
    addFile: false,
    addAudio: false,
    deleteContent: false,
    deleteProject: false
  });

  const params = useParams();
  const currentUser = useAuth();
  const { toast } = utils;

  const tabs = [
    { id: 'content', label: 'Contents', icon: <FileText className="h-4 w-4" /> },
    { id: 'activity', label: 'Activity', icon: <Activity className="h-4 w-4" /> },
    { id: 'settings', label: 'Settings', icon: <Settings className="h-4 w-4" /> },
    { id: 'sources', label: 'Sources', icon: <Share2 className="h-4 w-4" /> },
    { id: 'stats', label: 'Stats', icon: <BarChart2 className="h-4 w-4" /> },
    { id: 'search', label: 'Search', icon: <Search className="h-4 w-4" /> }
  ];

  const getUrls = () => {
    if (!currentUser?.id || !params?.id) return null;
    const base = `/users/${currentUser.id}/projects/${params.id}`;
    return {
      project: base,
      contents: `${base}/contents`,
      contentsList: `${base}/contents/list`,
      activity: `${base}/project_activities`,
      stats: `${base}/stats`,
      search: `${base}/search`,
      submit: `/external/api/projects/${params.id}/submit`
    };
  };

  const urls = getUrls();

  const fetchWithErrorHandling = async (url, options = {}) => {
    if (!url) throw new Error('URL is required');
    try {
      const response = await fetch(url, {
        headers: { 'Accept': 'application/json', ...options.headers },
        ...options
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error(`Fetch error for ${url}:`, error);
      throw error;
    }
  };

  const getCsrfToken = () => {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (!token) throw new Error('CSRF token not found');
    return token;
  };

  const updateState = (updates) => {
    setState(prev => ({ ...prev, ...updates }));
  };

  const toggleModal = (modalName, value) => {
    setModals(prev => ({ ...prev, [modalName]: value }));
  };

  const fetchProject = async () => {
    if (!urls) return;
    try {
      updateState({ loading: true });
      const data = await fetchWithErrorHandling(urls.project);
      updateState({ project: data, loading: false });
    } catch (error) {
      updateState({ error: 'Failed to load project', loading: false });
    }
  };

  const fetchContent = async (query, selectedSources, selectedAgents, page = 1) => {
    if (!urls) return;
    try {
      const queryParams = new URLSearchParams({ page: page.toString() });
      if (query) queryParams.append('query', query);
      selectedSources?.forEach(source => queryParams.append('content_sources[]', source));
      selectedAgents?.forEach(agent => queryParams.append('ai_agents[]', agent));

      const data = await fetchWithErrorHandling(`${urls.contentsList}?${queryParams}`);
      updateState({
        contents: data?.contents || [],
        pagination: data?.pagy || { page: 1, pages: 1, count: 0 }
      });
    } catch (error) {
      toast({ message: 'Failed to load contents', type: 'error' });
    }
  };

  const fetchActivity = async () => {
    if (!urls) return;
    try {
      const data = await fetchWithErrorHandling(urls.activity);
      updateState({ activity: data || [] });
    } catch (error) {
      toast({ message: 'Failed to load activity', type: 'error' });
    }
  };

  const fetchStats = async () => {
    if (!urls) return;
    try {
      const data = await fetchWithErrorHandling(urls.stats);
      updateState({ stats: data || [] });
    } catch (error) {
      toast({ message: 'Failed to load stats', type: 'error' });
    }
  };

  const handleSearch = async (query) => {
    if (!urls) return;
    try {
      const data = await fetchWithErrorHandling(`${urls.search}?q=${encodeURIComponent(query)}`);
      updateState({ results: data || [] });
    } catch (error) {
      toast({ message: 'Search failed', type: 'error' });
    }
  };

  const createContent = async (content) => {
    if (!urls) return;
    try {
      const data = await fetchWithErrorHandling(urls.contents, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        },
        body: JSON.stringify(content)
      });
      toggleModal('addContent', false);
      toast({ message: 'Content created successfully!' });
      window.location.href = data.redirect_url;
    } catch (error) {
      toast({ message: 'Failed to create content', type: 'error' });
    }
  };

  const deleteContent = async () => {
    if (!urls || !state.contentToDelete?.id) return;
    try {
      await fetchWithErrorHandling(`${urls.contents}/${state.contentToDelete.id}`, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': getCsrfToken() }
      });
      toggleModal('deleteContent', false);
      updateState({ contentToDelete: null });
      await fetchContent();
      toast({ message: 'Content deleted successfully!' });
    } catch (error) {
      toast({ message: 'Failed to delete content', type: 'error' });
    }
  };

  const handleDeleteProject = async () => {
    if (!urls) return;
    try {
      const data = await fetchWithErrorHandling(urls.project, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': getCsrfToken() }
      });
      toggleModal('deleteProject', false);
      window.location.href = data.redirect_url;
      toast({ message: 'Project deleted successfully!', duration: 5000 });
    } catch (error) {
      toast({ message: 'Failed to delete project', type: 'error' });
    }
  };

  const updateProject = async (data) => {
    if (!urls) return;
    try {
      const updatedProject = await fetchWithErrorHandling(urls.project, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        },
        body: JSON.stringify({ project: data })
      });
      updateState({ project: { ...state.project, ...updatedProject } });
      toast({ message: 'Project updated successfully!' });
    } catch (error) {
      toast({ message: 'Failed to update project', type: 'error' });
    }
  };

  useEffect(() => {
    if (urls) {
      fetchProject();
      fetchContent();
    }
  }, [currentUser?.id, params?.id]);

  useEffect(() => {
    if (!urls) return;

    if (state.activeTab === 'activity') {
      fetchActivity();
    } else if (state.activeTab === 'stats') {
      fetchStats();
    }
  }, [state.activeTab]);

  if (state.loading) {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-base-100/50 backdrop-blur-sm z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4"
          >
            <motion.div
              animate={{
                rotate: 360
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              <Loader className="w-8 h-8 text-primary" />
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-base-content/70"
            >
              Loading project...
            </motion.p>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  }

  if (state.error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-4 text-error text-center"
      >
        {state.error}
      </motion.div>
    );
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="w-full"
      >
        <DeleteContentModal
          contentToDelete={state.contentToDelete}
          isOpen={modals.deleteContent}
          onClose={() => toggleModal('deleteContent', false)}
          onDelete={deleteContent}
        />
        <DeleteProjectModal
          projectToDelete={state.project}
          isOpen={modals.deleteProject}
          onClose={() => toggleModal('deleteProject', false)}
          onDelete={handleDeleteProject}
        />
        <AddContentModal
          isOpen={modals.addContent}
          onClose={() => toggleModal('addContent', false)}
          onSave={createContent}
        />
        <UppyUploadModal
          onComplete={() => fetchContent()}
          projectId={state.project?.id}
          userId={currentUser?.id}
          id="file-uploader"
          isAudioOnly={false}
          isOpen={modals.addFile}
          onClose={() => toggleModal('addFile', false)}
        />
        <UppyUploadModal
          onComplete={() => fetchContent()}
          projectId={state.project?.id}
          userId={currentUser?.id}
          id="audio-uploader"
          isAudioOnly={true}
          isOpen={modals.addAudio}
          onClose={() => toggleModal('addAudio', false)}
        />

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="flex items-center justify-between px-2 py-2">
            <div className="breadcrumbs text-md">
              <ul>
                <li className="text-primary">
                  <FolderOpen className="w-4 h-4 mr-1" />
                  <a href={projectsURL} className="link link-primary">
                    All Projects
                  </a>
                </li>
                <li className="text-primary">
                  <FileText className="w-4 h-4 mr-1" />
                  {state.project?.name} {state.pagination?.count > 0 && `(${state.pagination.count})`}
                </li>
              </ul>
            </div>
            <div className="flex justify-between items-center gap-4">
              <div className="flex justify-between items-center gap-2">
                <div className="text-sm font-medium">AI Agents:</div>
                <Features project={state?.project} />
              </div>
              <div className="flex justify-between items-center gap-4">
                <div className="text-sm font-medium">Sources:</div>
                <ActiveSources project={state?.project} />
              </div>
              <ContentActionsDropdown
                onAddContent={() => toggleModal('addContent', true)}
                onAddFile={() => toggleModal('addFile', true)}
                onAddImage={() => toggleModal('addImage', true)}
                onAddAudio={() => toggleModal('addAudio', true)}
                onDelete={() => toggleModal('deleteProject', true)}
              />
            </div>
          </div>

          <div className="tabs tabs-lifted">
            {tabs.map((tab) => (
              <motion.button
                key={tab.id}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className={`tab tab-bordered gap-2 ${state.activeTab === tab.id ? 'tab-active' : ''}`}
                onClick={() => updateState({ activeTab: tab.id })}
              >
                {tab.icon}
                {tab.label}
              </motion.button>
            ))}
          </div>
        </motion.div>

        <motion.div
          className="mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {state.activeTab === 'content' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
            >
              <div className="flex items-center gap-4 p-4">
                <div className="flex gap-4">
                  <AIAgentSelector onAgentChange={(agents) => fetchContent(null, null, agents)} />
                  <ContentFilter onSourceChange={(sources) => fetchContent(null, sources, null)} />
                </div>
                <SearchContent handleSearchQueryChange={(query) => fetchContent(query)} />
              </div>
              {state.contents.length === 0 ? (
                <EmptyState
                  addContent={() => toggleModal('addContent', true)}
                  addFile={() => toggleModal('addFile', true)}
                  addAudio={() => toggleModal('addAudio', true)}
                />
              ) : (
                <Contents
                  onPaginate={(page) => fetchContent(null, null, null, page)}
                  pagination={state.pagination}
                  contents={state.contents}
                  onSearchContent={(query) => fetchContent(query)}
                  currentUser={currentUser}
                  project={state.project}
                  onDelete={(content) => {
                    updateState({ contentToDelete: content });
                    toggleModal('deleteContent', true);
                  }}
                />
              )}
            </motion.div>
          )}

          {state.activeTab === 'activity' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
            >
              {state.activity.length === 0 ? (
                <EmptyState
                  addContent={() => toggleModal('addContent', true)}
                  addFile={() => toggleModal('addFile', true)}
                  addAudio={() => toggleModal('addAudio', true)}
                />
              ) : (
                <ActivityLog activities={state.activity} />
              )}
            </motion.div>
          )}

          {state.activeTab === 'settings' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="p-4"
            >
              <div className="flex flex-col gap-4">
                <ProjectFormName project={state.project} onUpdate={updateProject} />
                <ProjectFormAgents project={state.project} onUpdate={updateProject} />
                <ProjectFormWebhook project={state.project} onUpdate={updateProject} />
              </div>
            </motion.div>
          )}

          {state.activeTab === 'sources' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="p-4"
            >
              <SourcesInfo
                baseUrl={baseUrl}
                project={state.project}
                onUpdate={updateProject}
                contentWidgetServerId={state.project?.id}
                contentWidgetServerUrl={contentWidgetServerUrl}
                contentWidgetServerJs={contentWidgetServerJs}
                submitApiV1ProjectUrl={urls?.submit}
              />
            </motion.div>
          )}

          {state.activeTab === 'stats' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="p-4"
            >
              <StatsInfo stats={state.stats} />
            </motion.div>
          )}

          {state.activeTab === 'search' && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="p-4"
            >
              <FullTextSearch
                project={state.project}
                results={state?.results}
                onSearch={(query) => handleSearch(query)}
              />
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Project;
