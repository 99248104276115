import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import {
  Tag,
  List,
  Heart,
  FileText,
  Lightbulb,
  AlertTriangle,
  Database,
  Users,
  ChevronDown,
  Copy,
  Calendar,
  Lock,
  MailQuestion,
  Smile,
  ShoppingCart,
  MapPin,
  Link,
  CalendarClock,
  Clock, ChartArea, Pill,
} from 'lucide-react'
import TagsResult from './result/tags/TagsResult'
import CategoriesResult from './result/categories/CategoriesResult'
import SentimentsResult from './result/sentiments/SentimentsResult'
import SummariesResult from './result/summaries/SummariesResult'
import ActionsResult from './result/actions/ActionsResult'
import AnomaliesResult from './result/anomalies/AnomaliesResult'
import MetadataResults from './result/metadata/MetadataResults'
import NamedEntitiesResult from './result/entities/NamedEntitiesResult'
import DuplicateDetectionResults from './result/duplicate/DuplicateDetectionResults'
import DateExtractionResults from './result/date-extraction/DateExtractionResults'
import PiiDetectionResults from './result/pii-detection/PiiDetectionResults'
import SpamExtractionResults from './result/spam/SpamExtractionResults'
import EmotionExtractionResults from './result/emotion/EmotionExtractionResults'
import ProductCategorizationResults from './result/product-categorization/ProductCategorizationResults'
import AddressValidationResults from './result/address-validation/AddressValidationResults'
import CitationDetectionResults from './result/citations/CitationDetectionResults'
import KpiExtractionResults from './result/kpi/KpiExtractionResults'
import UrgencyDetectionResults from './result/urgency/UrgencyDetectionResults'
import DeadlineDetectionResults from './result/deadlines/DeadlineDetectionResults'
import MedicalTermExtractionResults from './result/medical/MedicalTermExtractionResults'


const AccordionItem = ({
  title,
  icon: Icon,
  children,
  isOpen,
  onToggle,
  iconColor,
  status,
}) => {
  if (!title || !Icon || !onToggle) return null

  return (
    <div className="border rounded-lg mb-2">
      <motion.div
        className={`${status
          ? 'bg-base-200/80 hover:bg-base-200 cursor-pointer'
          : 'bg-gray-100 opacity-60 cursor-not-allowed'}`}
        onClick={status ? onToggle : undefined}
        role="button"
        aria-expanded={isOpen}
        aria-disabled={!status}
        whileHover={status ? { scale: 1.01 } : {}}
        transition={{ type: "spring", stiffness: 400, damping: 30 }}
      >
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Icon className={`w-5 h-5 transition-colors duration-300 ${status
              ? (isOpen ? iconColor : 'text-gray-600')
              : 'text-gray-400'
              }`} />
            <span className={`text-xl font-medium ${status ? '' : 'text-gray-400'
              }`}>{title}</span>
          </div>
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className={`w-6 h-6 ${status ? '' : 'text-gray-400'}`} />
          </motion.div>
        </div>
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ type: "spring", duration: 0.4, bounce: 0 }}
            style={{ overflow: "hidden" }}
          >
            <div className="p-4 bg-base-100">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function CompetitorMentionDetectionResults(props) {
  return null
}

const ContentResult = ({ contentAgentResults, onRerun, project }) => {
  if (!contentAgentResults) return null

  const [openSections, setOpenSections] = useState({
    tags: false,
    categories: false,
    sentiments: false,
    summaries: false,
    actions: false,
    anomalies: false,
    metadata: false,
    entities: false,
    duplicate: false,
    dateExtraction: false,
    piiDetection: false,
    emotion: false,
    spam: false,
    productCategorization: false,
    addressValidation: false,
    citationDetector: false,
    kpiExtractor: false,
    competitorMentionDetector: false,
    urgencyDetector: false,
    deadlineDetector: false,
    medicalTermExtractor: false
  })

  const toggleSection = (sectionKey) => {
    if (sectionKey === 'tags') {
      if (project?.has_tags) {
        setOpenSections(prev => ({
          ...prev,
          tags: !prev.tags,
        }))
      }
    } else if (sectionKey === 'categories') {
      if (project?.has_category) {
        setOpenSections(prev => ({
          ...prev,
          categories: !prev.categories,
        }))
      }
    } else if (sectionKey === 'entities') {
      if (project?.has_named_entity_recognition) {
        setOpenSections(prev => ({
          ...prev,
          entities: !prev.entities,
        }))
      }
    } else if (sectionKey === 'duplicate') {
      if (project?.has_duplicate_detection) {
        setOpenSections(prev => ({
          ...prev,
          duplicate: !prev.duplicate,
        }))
      }
    } else if (sectionKey === 'dateExtraction') {
      if (project?.has_date_extraction) {
        setOpenSections(prev => ({
          ...prev,
          dateExtraction: !prev.dateExtraction,
        }))
      }
    } else if (sectionKey === 'piiDetection') {
      if (project?.has_pii_detection) {
        setOpenSections(prev => ({
          ...prev,
          piiDetection: !prev.piiDetection,
        }))
      }
    } else if (sectionKey === 'sentiments') {
      if (project?.has_sentiment) {
        setOpenSections(prev => ({
          ...prev,
          sentiments: !prev.sentiments,
        }))
      }
    } else if (sectionKey === 'summaries') {
      if (project?.has_summary) {
        setOpenSections(prev => ({
          ...prev,
          summaries: !prev.summaries,
        }))
      }
    } else if (sectionKey === 'actions') {
      if (project?.has_actionable_insights) {
        setOpenSections(prev => ({
          ...prev,
          actions: !prev.actions,
        }))
      }
    } else if (sectionKey === 'metadata') {
      if (project?.has_metadata_extractor) {
        setOpenSections(prev => ({
          ...prev,
          metadata: !prev.metadata,
        }))
      }
    } else if (sectionKey === 'anomalies') {
      if (project?.has_anomaly_detector) {
        setOpenSections(prev => ({
          ...prev,
          anomalies: !prev.anomalies,
        }))
      }
    } else if (sectionKey === 'spam') {
      if (project?.has_spam_extraction) {
        setOpenSections(prev => ({
          ...prev,
          spam: !prev.spam,
        }))
      }
    } else if (sectionKey === 'emotion') {
      if (project?.has_emotion) {
        setOpenSections(prev => ({
          ...prev,
          emotion: !prev.emotion,
        }))
      }
    } else if (sectionKey === 'productCategorization') {
      if (project?.has_product_categorization) {
        setOpenSections(prev => ({
          ...prev,
          productCategorization: !prev.productCategorization,
        }))
      }
    } else if (sectionKey === 'addressValidation') {
      if (project?.has_address_validation) {
        setOpenSections(prev => ({
          ...prev,
          addressValidation: !prev.addressValidation,
        }))
      }
    } else if (sectionKey === 'citationDetector') {
      if (project?.has_citation_detector) {
        setOpenSections(prev => ({
          ...prev,
          citationDetector: !prev.citationDetector,
        }))
      }
    } else if (sectionKey === 'kpiExtractor') {
      if (project?.has_kpi_extractor) {
        setOpenSections(prev => ({
          ...prev,
          kpiExtractor: !prev.kpiExtractor,
        }))
      }
    } else if (sectionKey === 'competitorMentionDetector') {
      if (project?.has_competitor_mention_detector) {
        setOpenSections(prev => ({
          ...prev,
          competitorMentionDetector: !prev.competitorMentionDetector,
        }))
      }
    } else if (sectionKey === 'urgencyDetector') {
      if (project?.has_urgency_detector) {
        setOpenSections(prev => ({
          ...prev,
          urgencyDetector: !prev.urgencyDetector,
        }))
      }
    } else if (sectionKey === 'deadlineDetector') {
      if (project?.has_deadline_detector) {
        setOpenSections(prev => ({
          ...prev,
          deadlineDetector: !prev.deadlineDetector,
        }))
      }
    } else if (sectionKey === 'medicalTermExtractor') {
      if (project?.has_medical_term_extractor) {
        setOpenSections(prev => ({
          ...prev,
          medicalTermExtractor: !prev.medicalTermExtractor,
        }))
      }
    }
  }

  const {
    tagging,
    categorization,
    sentiment_analysis,
    summary,
    actionable_insight,
    anomaly_detection,
    metadata_extraction,
    named_entity,
    date_extraction,
    pii_detection,
    duplicate_detection,
    spam_extraction,
    emotion_extraction,
    product_categorization,
    address_validation,
    citation_detector,
    kpi_extractor,
    competitor_mention_detector,
    urgency_detector,
    deadline_detector,
    medical_term_extractor
  } = contentAgentResults

  const sections = [
    {
      key: 'tags',
      title: 'Tags',
      icon: Tag,
      status: project?.has_tags,
      iconColor: project?.has_tags ? 'text-green-500' : 'text-gray-400',
      component: tagging && <TagsResult tagging={tagging} onRerun={onRerun} />,
      hasData: !!tagging,
    },
    {
      key: 'categories',
      title: 'Categories',
      icon: List,
      status: project?.has_category,
      iconColor: project?.has_category ? 'text-green-500' : 'text-gray-400',
      component: categorization &&
        <CategoriesResult categories={categorization} onRerun={onRerun} />,
      hasData: !!categorization,
    },
    {
      key: 'sentiments',
      title: 'Sentiments',
      icon: Heart,
      status: project?.has_sentiment,
      iconColor: project?.has_sentiment ? 'text-red-500' : 'text-gray-400',
      component: sentiment_analysis &&
        <SentimentsResult sentiments={sentiment_analysis} onRerun={onRerun} />,
      hasData: !!sentiment_analysis,
    },
    {
      key: 'summaries',
      title: 'Summaries',
      icon: FileText,
      status: project?.has_summary,
      iconColor: project?.has_summary ? 'text-purple-500' : 'text-gray-400',
      component: summary &&
        <SummariesResult summaries={summary} onRerun={onRerun} />,
      hasData: !!summary,
    },
    {
      key: 'actions',
      title: 'Actions',
      icon: Lightbulb,
      status: project?.has_actionable_insights,
      iconColor: project?.has_actionable_insights ? 'text-yellow-500' : 'text-gray-400',
      component: actionable_insight &&
        <ActionsResult actionableInsight={actionable_insight} onRerun={onRerun} />,
      hasData: !!actionable_insight,
    },
    {
      key: 'anomalies',
      title: 'Anomalies',
      icon: AlertTriangle,
      status: project?.has_anomaly_detector,
      iconColor: project?.has_anomaly_detector ? 'text-orange-500' : 'text-gray-400',
      component: anomaly_detection &&
        <AnomaliesResult anomalyDetections={anomaly_detection} onRerun={onRerun} />,
      hasData: !!anomaly_detection,
    },
    {
      key: 'metadata',
      title: 'Metadata',
      icon: Database,
      status: project?.has_metadata_extractor,
      iconColor: project?.has_metadata_extractor ? 'text-indigo-500' : 'text-gray-400',
      component: metadata_extraction &&
        <MetadataResults metadata={metadata_extraction} onRerun={onRerun} />,
      hasData: !!metadata_extraction,
    },
    {
      key: 'entities',
      title: 'Named Entities',
      icon: Users,
      status: project?.has_named_entity_recognition,
      iconColor: project?.has_named_entity_recognition ? 'text-teal-500' : 'text-gray-400',
      component: named_entity &&
        <NamedEntitiesResult namedEntity={named_entity} onRerun={onRerun} />,
      hasData: !!named_entity,
    },
    {
      key: 'duplicate',
      title: 'Duplicate Detection',
      icon: Copy,
      status: project?.has_duplicate_detection,
      iconColor: project?.has_duplicate_detection ? 'text-purple-500' : 'text-gray-400',
      component: duplicate_detection &&
        <DuplicateDetectionResults duplicateDetection={duplicate_detection} onRerun={onRerun} />,
      hasData: !!duplicate_detection,
    },
    {
      key: 'dateExtraction',
      title: 'Date Extraction',
      icon: Calendar,
      status: project?.has_date_extraction,
      iconColor: project?.has_date_extraction ? 'text-purple-500' : 'text-gray-400',
      component: date_extraction &&
        <DateExtractionResults dateExtractions={date_extraction} onRerun={onRerun} />,
      hasData: !!date_extraction,
    },
    {
      key: 'piiDetection',
      title: 'PII Detection',
      icon: Lock,
      status: project?.has_pii_detection,
      iconColor: project?.has_pii_detection ? 'text-purple-500' : 'text-gray-400',
      component: pii_detection &&
        <PiiDetectionResults piiDetections={pii_detection} onRerun={onRerun} />,
      hasData: !!pii_detection,
    },
    {
      key: 'spam',
      title: 'Spam Detection',
      icon: MailQuestion,
      status: project?.has_spam_extraction,
      iconColor: project?.has_spam_extraction ? 'text-purple-500' : 'text-gray-400',
      component: spam_extraction &&
        <SpamExtractionResults spamExtraction={spam_extraction} onRerun={onRerun} />,
      hasData: !!spam_extraction,
    },
    {
      key: 'emotion',
      title: 'Emotion Detection',
      icon: Smile,
      status: project?.has_emotion,
      iconColor: project?.has_emotion ? 'text-purple-500' : 'text-gray-400',
      component: emotion_extraction &&
        <EmotionExtractionResults emotionExtraction={emotion_extraction} onRerun={onRerun} />,
      hasData: !!emotion_extraction,
    },
    {
      key: 'productCategorization',
      title: 'Product Categorization',
      icon: ShoppingCart,
      status: project?.has_product_categorization,
      iconColor: project?.has_product_categorization ? 'text-purple-500' : 'text-gray-400',
      component: product_categorization &&
        <ProductCategorizationResults productCategorization={product_categorization} onRerun={onRerun} />,
      hasData: !!product_categorization,
    },
    {
      key: 'addressValidation',
      title: 'Address Validation',
      icon: MapPin,
      status: project?.has_address_validation,
      iconColor: project?.has_address_validation ? 'text-purple-500' : 'text-gray-400',
      component: address_validation &&
        <AddressValidationResults addressValidation={address_validation} onRerun={onRerun} />,
      hasData: !!address_validation,
    },
    {
      key: 'citationDetector',
      title: 'Citation Detection',
      icon: Link,
      status: project?.has_citation_detector,
      iconColor: project?.has_citation_detector ? 'text-purple-500' : 'text-gray-400',
      component: citation_detector &&
        <CitationDetectionResults citationDetection={citation_detector} onRerun={onRerun} />,
      hasData: !!citation_detector,
    },
    {
      key: 'kpiExtractor',
      title: 'KPI Extraction',
      icon: ChartArea,
      status: project?.has_kpi_extractor,
      iconColor: project?.has_kpi_extractor ? 'text-purple-500' : 'text-gray-400',
      component: kpi_extractor &&
        <KpiExtractionResults kpiExtraction={kpi_extractor} onRerun={onRerun} />,
      hasData: !!kpi_extractor,
    },
    {
      key: 'competitorMentionDetector',
      title: 'Competitor Mention Detection',
      icon: Users,
      status: project?.has_competitor_mention_detector,
      iconColor: project?.has_competitor_mention_detector ? 'text-purple-500' : 'text-gray-400',
      component: competitor_mention_detector &&
        <CompetitorMentionDetectionResults competitorMentionDetection={competitor_mention_detector} onRerun={onRerun} />,
      hasData: !!competitor_mention_detector,
    },
    {
      key: 'urgencyDetector',
      title: 'Urgency Detection',
      icon: Clock,
      status: project?.has_urgency_detector,
      iconColor: project?.has_urgency_detector ? 'text-purple-500' : 'text-gray-400',
      component: urgency_detector &&
        <UrgencyDetectionResults urgencyDetection={urgency_detector} onRerun={onRerun} />,
      hasData: !!urgency_detector,
    },
    {
      key: 'deadlineDetector',
      title: 'Deadline Detection',
      icon: CalendarClock,
      status: project?.has_deadline_detector,
      iconColor: project?.has_deadline_detector ? 'text-purple-500' : 'text-gray-400',
      component: deadline_detector &&
        <DeadlineDetectionResults deadlineDetection={deadline_detector} onRerun={onRerun} />,
      hasData: !!deadline_detector,
    },
    {
      key: 'medicalTermExtractor',
      title: 'Medical Term Extraction',
      icon: Pill,
      status: project?.has_medical_term_extractor,
      iconColor: project?.has_medical_term_extractor ? 'text-purple-500' : 'text-gray-400',
      component: medical_term_extractor &&
        <MedicalTermExtractionResults medicalTermExtraction={medical_term_extractor} onRerun={onRerun} />,
      hasData: !!medical_term_extractor,
    },
  ]

  const filteredSections = sections.filter(section => section.hasData)

  if (filteredSections.length === 0) return null

  return (
    <div className="space-y-2">
      {filteredSections.map((section) => (
        <AccordionItem
          status={section.status}
          key={section.key}
          title={section.title}
          icon={section.icon}
          iconColor={section.iconColor}
          isOpen={openSections[section.key]}
          onToggle={() => toggleSection(section.key)}
        >
          {section.component}
        </AccordionItem>
      ))}
    </div>
  )
}

export default ContentResult
