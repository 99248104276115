import React from 'react';
import { BookOpen, FileCog, FileCode, Files, ScrollText } from 'lucide-react';
import EvidenceList from './EvidenceList';
import ConfidenceIndicator from './ConfidenceIndicator';

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

const getDocumentIcon = (docType) => {
  if (!isValidString(docType)) return <Files className="h-4 w-4" />;

  switch (docType.toLowerCase()) {
    case 'policy': return <ScrollText className="h-4 w-4" />;
    case 'guideline': return <BookOpen className="h-4 w-4" />;
    case 'technical': return <FileCode className="h-4 w-4" />;
    case 'procedure': return <FileCog className="h-4 w-4" />;
    default: return <Files className="h-4 w-4" />;
  }
};

const DocumentTypeItem = ({ type, data }) => {
  if (!isValidString(type) || !isValidObject(data)) return null;

  const isPrimary = type.toLowerCase() === 'primary';
  const safeValue = isValidString(data.value) ? data.value : 'Unknown Document Type';

  return (
    <div className="card bg-base-100 shadow-sm w-full">
      <div className="card-body p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {getDocumentIcon(safeValue)}
            <h3 className="text-sm font-medium">
              {isPrimary ? 'Primary Document' : 'Alternative Document'}
            </h3>
          </div>
          <ConfidenceIndicator confidence={data?.confidence} />
        </div>

        <div className="divider my-2"></div>

        <div className="flex items-center gap-2">
          <span className="text-sm">{safeValue}</span>
        </div>

        <EvidenceList
          evidence={data?.evidence}
          isPrimary={isPrimary}
        />
      </div>
    </div>
  );
};

export default DocumentTypeItem;
