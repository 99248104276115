import React from 'react';
import { Star, ListTree, BookOpen } from 'lucide-react';
import EvidenceList from './EvidenceList';
import ConfidenceIndicator from './ConfidenceIndicator';

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

const CategoryItem = ({ type, data }) => {
  if (!isValidString(type) || !isValidObject(data)) return null;

  const isPrimary = type.toLowerCase() === 'primary';
  const safeValue = isValidString(data.value) ? data.value : 'No value provided';

  return (
    <div className="card bg-base-100 shadow-sm">
      <div className="card-body p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {isPrimary ? (
              <Star className="h-4 w-4 text-primary" />
            ) : (
              <ListTree className="h-4 w-4 text-secondary" />
            )}
            <h3 className="text-sm font-medium">
              {isPrimary ? 'Primary' : 'Alternative'}
            </h3>
          </div>
          <ConfidenceIndicator confidence={data?.confidence} />
        </div>

        <div className="divider my-2"></div>

        <div className="flex items-center gap-2">
          <BookOpen className="h-4 w-4" />
          <span className="text-sm">{safeValue}</span>
        </div>

        <EvidenceList
          evidence={data?.evidence}
          isPrimary={isPrimary}
        />
      </div>
    </div>
  );
};

export default CategoryItem;
