import React from 'react';
import { Link } from 'lucide-react';
import CopyButton from '../../common/CopyButton';

const PublicFolderSource = ({ disabled, folderLink, onToggle, project }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between bg-base-200 p-4 rounded-lg opacity-[.85]">
        <div className="flex items-center gap-3">
          <Link className={`h-5 w-5 ${disabled ? 'text-base-content/50' : 'text-primary'}`} />
          <div>
            <p className="font-medium">Folder URL</p>
            <p className="text-sm text-base-content/70">{folderLink}</p>
          </div>
        </div>
        {project?.has_public_access && <CopyButton text={folderLink} copyKey="folder" />}
      </div>

      <div className="flex justify-end">
        <button
          onClick={onToggle}
          className="btn btn-sm btn-primary"
        >
          {project?.has_public_access ? 'Disable Public Access' : 'Enable Public Access'}
        </button>
      </div>
    </div>
  )
}

export default PublicFolderSource;
