import SettingsModal from './SettingsModal'
import React from 'react'
import {
  Database,
  AlertTriangle,
  FileSignature,
  Heart,
  Tags,
  User,
  Calendar,
  Lock,
  Copy,
  Bot,
  FileText,
  MailQuestion,
  Smile,
  ShoppingCart,
  MapPin,
  Pill,
  ChartBar,
  CalendarClock,
  Users,
  Clock
} from "lucide-react";

const StatsModal = ({ isOpen, onClose, stats }) => {
  if (!isOpen || !stats) return null;

  const statSections = [
    {
      title: null,
      stats: [
        { icon: Bot, label: "Total Tokens", key: "total_tokens", format: true },
        { icon: FileText, label: "Projects", key: "number_of_projects" }
      ]
    },
    {
      title: "Processing Stats",
      stats: [
        { icon: FileText, label: "Contents", key: "number_of_contents" },
        { icon: Tags, label: "Categories", key: "categorization" },
        { icon: Database, label: "Metadata Extracted", key: "metadata_extraction" },
        { icon: AlertTriangle, label: "Anomalies", key: "anomalies_detected" }
      ]
    },
    {
      title: "Analysis Stats",
      stats: [
        { icon: User, label: "Entities", key: "entities" },
        { icon: FileSignature, label: "Summaries", key: "summaries" },
        { icon: Heart, label: "Sentiment Analysis", key: "sentiment" },
        { icon: Tags, label: "Tags Generated", key: "tags" },
        { icon: Calendar, label: "Date Extraction", key: "date_extraction" },
        { icon: Lock, label: "PII Detection", key: "pii_detection" },
        { icon: Copy, label: "Duplicate Detection", key: "duplicate_detection" },
        { icon: MailQuestion, label: "Spam Detection", key: "spam_extraction" },
        { icon: Smile, label: "Emotion Detection", key: "emotion" },
        { icon: ShoppingCart, label: "Product Categorization", key: "product_categorization" },
        { icon: MapPin, label: "Address Validation", key: "address_validation" },
        { icon: Pill, label: "Medical Term Extraction", key: "medical_term_extraction" },
        { icon: ChartBar, label: "KPI Extraction", key: "kpi_extraction" },
        { icon: Users, label: "Competitor Mention Detection", key: "competitor_mention_detection" },
        { icon: Clock, label: "Urgency Detection", key: "urgency_detection" },
        { icon: CalendarClock, label: "Deadline Detection", key: "deadline_detection" }
      ]
    }
  ];

  const StatBlock = ({ icon: Icon, label, value }) => (
    <div className="flex items-center gap-3">
      <div className="p-2 bg-base-100 rounded-lg">
        <Icon className="w-4 h-4 text-primary" strokeWidth={1.5} />
      </div>
      <div>
        <div className="text-sm font-medium">{label}</div>
        <div className="text-xl">{value}</div>
      </div>
    </div>
  );

  return (
    <SettingsModal isOpen={isOpen} onClose={onClose} title="Statistics">
      <div className="space-y-6">
        {statSections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="bg-base-200 p-4 rounded-lg space-y-4">
            {section.title && (
              <h3 className="font-medium text-lg">{section.title}</h3>
            )}
            <div className={`grid grid-cols-2 gap-4 ${!section.title ? 'grid-cols-2' : ''}`}>
              {section.stats.map((stat, statIndex) => (
                <StatBlock
                  key={statIndex}
                  icon={stat.icon}
                  label={stat.label}
                  value={stat.format
                    ? stats[stat.key]?.toLocaleString() || '0'
                    : stats[stat.key] || '0'
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </SettingsModal>
  );
};

export default StatsModal;
