import {
  AlertTriangle,
  BookDown,
  Calendar,
  Copy,
  DatabaseBackup,
  FileText,
  HeartPulse,
  Lightbulb,
  Lock,
  Tags,
  User,
  Smile,
  MailQuestion,
  ShoppingCart,
  MapPin,
  Link,
  ChartBar,
  Users,
  Clock,
  CalendarClock,
  Hospital
} from 'lucide-react'

const SelectionActions = (project) => {
  return [
    {
      id: 'has_tags',
      Icon: Tags,
      label: 'Tags',
      disabled: !project.has_tags,
      color: 'text-purple-500',
      ariaLabel: 'Tag Detection Feature',
      action: (text) => console.log('Tags:', text)
    },
    {
      id: 'has_anomaly_detector',
      Icon: AlertTriangle,
      label: 'Anomaly Detector',
      disabled: !project.has_anomaly_detector,
      color: 'text-purple-500',
      ariaLabel: 'Anomaly Detection Feature',
      action: (text) => console.log('Anomaly Detection:', text)
    },
    {
      id: 'has_category',
      Icon: BookDown,
      label: 'Category',
      disabled: !project.has_category,
      color: 'text-blue-500',
      ariaLabel: 'Category Detection Feature',
      action: (text) => console.log('Category:', text)
    },
    {
      id: 'has_sentiment',
      Icon: HeartPulse,
      label: 'Sentiment',
      disabled: !project.has_sentiment,
      color: 'text-red-500',
      ariaLabel: 'Sentiment Analysis Feature',
      action: (text) => console.log('Sentiment:', text)
    },
    {
      id: 'has_summary',
      Icon: FileText,
      label: 'Summary',
      disabled: !project.has_summary,
      color: 'text-orange-500',
      ariaLabel: 'Summary Generation Feature',
      action: (text) => console.log('Summary:', text)
    },
    {
      id: 'has_actionable_insights',
      Icon: Lightbulb,
      label: 'Insights',
      disabled: !project.has_summary,
      color: 'text-yellow-500',
      ariaLabel: 'Actionable Insights Feature',
      action: (text) => console.log('Insights:', text)
    },
    {
      id: 'has_metadata_extractor',
      Icon: DatabaseBackup,
      label: 'Metadata Extractor',
      disabled: !project.has_metadata_extractor,
      color: 'text-cyan-500',
      ariaLabel: 'Metadata Extraction Feature',
      action: (text) => console.log('Metadata:', text)
    },
    {
      id: 'has_named_entity_recognition',
      Icon: User,
      label: 'Named Entity Recognition',
      disabled: !project.has_named_entity_recognition,
      color: 'text-emerald-500',
      ariaLabel: 'Named Entity Recognition Feature',
      action: (text) => console.log('Named Entities:', text)
    },
    {
      id: 'has_date_extraction',
      Icon: Calendar,
      label: 'Date Extraction',
      disabled: !project.has_date_extraction,
      color: 'text-blue-500',
      ariaLabel: 'Date Extraction Feature',
      action: (text) => console.log('Dates:', text)
    },
    {
      id: 'has_pii_detection',
      Icon: Lock,
      label: 'PII Detection',
      disabled: !project.has_pii_detection,
      color: 'text-emerald-500',
      ariaLabel: 'PII Detection Feature',
      action: (text) => console.log('PII:', text)
    },
    {
      id: 'has_duplicate_detection',
      Icon: Copy,
      label: 'Duplicate Detection',
      disabled: !project.has_duplicate_detection,
      color: 'text-cyan-500',
      ariaLabel: 'Duplicate Detection Feature',
      action: (text) => console.log('Duplicates:', text)
    },
    {
      id: 'has_emotion',
      Icon: Smile,
      label: 'Emotion Detection',
      disabled: !project.has_emotion,
      color: 'text-emerald-500',
      ariaLabel: 'Emotion Detection Feature',
      action: (text) => console.log('Emotion:', text)
    },
    {
      id: 'has_spam_extraction',
      Icon: MailQuestion,
      label: 'Spam Detection',
      disabled: !project.has_spam_extraction,
      color: 'text-red-500',
      ariaLabel: 'Spam Detection Feature',
      action: (text) => console.log('Spam:', text)
    },
    {
      id: 'has_address_validation',
      Icon: MapPin,
      label: 'Address Validation',
      disabled: !project.has_address_validation,
      color: 'text-purple-500',
      ariaLabel: 'Address Validation Feature',
      action: (text) => console.log('Address:', text)
    },
    {
      id: 'has_product_categorization',
      Icon: ShoppingCart,
      label: 'Product Categorization',
      disabled: !project.has_product_categorization,
      color: 'text-purple-500',
      ariaLabel: 'Product Categorization Feature',
      action: (text) => console.log('Product:', text)
    },
    {
      id: 'has_citation_detector',
      Icon: Link,
      label: 'Citation Detection',
      disabled: !project.has_citation_detector,
      color: 'text-purple-500',
      ariaLabel: 'Citation Detection Feature',
      action: (text) => console.log('Citation:', text)
    },
    {
      id: 'has_kpi_extractor',
      Icon: ChartBar,
      label: 'KPI Extraction',
      disabled: !project.has_kpi_extractor,
      color: 'text-purple-500',
      ariaLabel: 'KPI Extraction Feature',
      action: (text) => console.log('KPI:', text)
    },
    {
      id: 'has_competitor_mention_detector',
      Icon: Users,
      label: 'Competitor Mention Detection',
      disabled: !project.has_competitor_mention_detector,
      color: 'text-purple-500',
      ariaLabel: 'Competitor Mention Detection Feature',
      action: (text) => console.log('Competitor Mention:', text)
    },
    {
      id: 'has_urgency_detector',
      Icon: Clock,
      label: 'Urgency Detection',
      disabled: !project.has_urgency_detector,
      color: 'text-purple-500',
      ariaLabel: 'Urgency Detection Feature',
      action: (text) => console.log('Urgency:', text)
    },
    {
      id: 'has_deadline_detector',
      Icon: CalendarClock,
      label: 'Deadline Detection',
      disabled: !project.has_deadline_detector,
      color: 'text-purple-500',
      ariaLabel: 'Deadline Detection Feature',
      action: (text) => console.log('Deadline:', text)
    },
    {
      id: 'has_medical_term_extractor',
      Icon: Hospital,
      label: 'Medical Term Extraction',
      disabled: !project.has_medical_term_extractor,
      color: 'text-purple-500',
      ariaLabel: 'Medical Term Extraction Feature',
      action: (text) => console.log('Medical Term:', text)
    },
  ];
};

export default SelectionActions;
