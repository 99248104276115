import React from 'react';
import CategoryResult from './CategoryResult';
import IndustryResult from './IndustryResult';
import DocumentTypesResult from './DocumentTypesResult';
import CopyButton from '../../../common/CopyButton'
import { RotateCcw } from "lucide-react";
import ResultStatus from '../../common/ResultStatus'
import AIAgentCard from '../../common/AIAgentCard'
const CategoriesResult = ({ categories, onRerun }) => {
  const discovered_classification = categories?.result?.response?.discovered_classification ||
    categories?.result?.discovered_classification || {};
  const status = categories?.result?.response?.status ||
    categories?.result?.status || {};
  const agent_name = categories?.agent_name || '';
  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
            <ResultStatus status={status} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('categories')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(categories)} copyKey={'categories'} />
        </div>
      </div>

      <div className="flex gap-6 justify-start w-full flex-col">
        <CategoryResult category={discovered_classification?.category} />
        <IndustryResult industry={discovered_classification?.industry} />
        <DocumentTypesResult documentTypes={discovered_classification?.document_type} />
      </div>
    </div>
  );
};
export default CategoriesResult;
