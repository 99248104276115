import { Code, FolderOpen, Mail, Radio } from 'lucide-react';
import React, { memo } from 'react';
import IconWithTooltip from './IconWithTooltip';

const ActiveSources = memo(({ project }) => {
  const sourceConfigs = [
    {
      condition: 'has_js_access',
      Icon: Code,
      tooltip: 'JS Snippet',
      color: 'text-pink-500',
      ariaLabel: 'JavaScript Snippet Integration Active'
    },
    {
      condition: 'has_public_access',
      Icon: FolderOpen,
      tooltip: 'Public Folder',
      color: 'text-yellow-500',
      ariaLabel: 'Public Folder Access Active'
    },
    {
      condition: 'has_email_access',
      Icon: Mail,
      tooltip: 'Email',
      color: 'text-blue-500',
      ariaLabel: 'Email Integration Active'
    },
    {
      condition: 'has_api_access',
      Icon: Radio,
      tooltip: 'API',
      color: 'text-green-500',
      ariaLabel: 'API Access Active'
    }
  ];

  if (!project) return null;

  return (
    <div className="flex gap-2" role="group" aria-label="Active Data Sources">
      {sourceConfigs.map(({ condition, Icon, tooltip, color, ariaLabel }) =>
        project[condition] && (
          <IconWithTooltip
            key={condition}
            icon={Icon}
            tooltip={tooltip}
            color={color}
            ariaLabel={ariaLabel}
          />
        )
      )}
    </div>
  );
});

ActiveSources.displayName = 'ActiveSources';

export default ActiveSources;
