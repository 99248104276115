import React, { createContext, useContext } from 'react'

const ParamsContext = createContext(null)

export const ParamsProvider = ({ params, children }) => {
  return (
    <ParamsContext.Provider value={params}>
      {children}
    </ParamsContext.Provider>
  )
}

export const useParams = () => {
  return useContext(ParamsContext)
}
