import React from 'react';
import { Shield, AlertTriangle, Link, RotateCcw } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';
const SpamIndicatorsDisplay = ({ indicators }) => {
  if (!indicators) return null;


  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <AlertTriangle className="w-4 h-4" />
          Spam Indicators
        </h3>
        <div className="space-y-4">
          {indicators.suspicious_elements?.map((element, index) => (
            <div key={index} className="p-3 bg-base-300 rounded-lg">
              <p className="text-sm font-medium">{element.type}</p>
              <p className="text-sm text-base-content/70">{element.details}</p>
              <div className="mt-2 flex items-center gap-2">
                <div className="text-xs">Severity:</div>
                <div className="flex-1 h-2 bg-base-100 rounded-full">
                  <div
                    className="h-full bg-error rounded-full"
                    style={{ width: `${element.severity * 100}%` }}
                  />
                </div>
                <div className="text-xs">{(element.severity * 100).toFixed(1)}%</div>
              </div>
            </div>
          ))}

          {indicators.spam_signatures && (
            <div className="space-y-2">
              {indicators.known_patterns?.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium mb-1">Known Patterns</h4>
                  <div className="flex flex-wrap gap-2">
                    {indicators.spam_signatures.known_patterns.map((pattern, index) => (
                      <span key={index} className="badge badge-warning badge-sm">
                        {pattern}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {indicators.spam_signatures.suspicious_urls?.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium mb-1 flex items-center gap-2">
                    <Link className="w-3 h-3" />
                    Suspicious URLs
                  </h4>
                  <div className="flex flex-wrap gap-2">
                    {indicators.spam_signatures.suspicious_urls.map((url, index) => (
                      <span key={index} className="badge badge-error badge-sm">
                        {url}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SpamScoreDisplay = ({ spamScore }) => {
  if (!spamScore) return null;

  const getScoreColor = (score) => {
    if (score > 0.7) return 'error';
    if (score > 0.4) return 'warning';
    return 'success';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Shield className="w-4 h-4" />
          Spam Score Analysis
        </h3>
        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="h-4 w-full bg-base-300 rounded-full">
                <div
                  className={`h-full bg-${getScoreColor(spamScore.value)} rounded-full`}
                  style={{ width: `${spamScore.value * 100}%` }}
                />
              </div>
            </div>
            <span className={`text-${getScoreColor(spamScore.value)} font-medium`}>
              {(spamScore.value * 100).toFixed(1)}%
            </span>
          </div>
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <span className="text-base-content/60">Confidence:</span>
              <span className="ml-2 font-medium">{(spamScore.confidence * 100).toFixed(1)}%</span>
            </div>
            <div>
              <span className="text-base-content/60">Risk Level:</span>
              <span className={`ml-2 font-medium text-${getScoreColor(spamScore.value)}`}>
                {spamScore.risk_level}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SpamExtractionResults = ({ spamExtraction, onRerun }) => {

  const { status, result, agent_name } = spamExtraction;
  const spamAnalysis = result?.spam_analysis;

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={agent_name} />
            </p>
            <ResultStatus status={status} />
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('spamExtraction')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(spamAnalysis)}
              copyKey={'spam-extraction'}
            />
          </div>
        </div>

        <div className="space-y-4">
          <div className={`alert ${spamAnalysis?.is_spam ? 'alert-error' : 'alert-success'}`}>
            <Shield className="w-5 h-5" />
            <span>
              This content is {spamAnalysis?.is_spam ? 'likely spam' : 'likely not spam'}
            </span>
          </div>

          <SpamScoreDisplay spamScore={spamAnalysis?.spam_score} />

          <SpamIndicatorsDisplay indicators={spamAnalysis?.detection_factors} />

          {result?.metadata && (
            <div className="text-sm text-base-content/60">
              <p>False positive risk: {(result?.metadata?.analysis_metrics?.false_positive_risk * 100).toFixed(1)}%</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpamExtractionResults;
