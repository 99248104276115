import React, { memo } from 'react';
import ActiveSources from './ActiveSources';
import Features from '../common/Features';

const ProjectCard = memo(({ project, currentUser }) => {
  if (!project || !currentUser) return null;

  return (
    <article className="card bg-base-100 shadow-xl border border-base-400">
      <div className="card-body p-6">
        <header className="flex justify-between items-start">
          <h2 className="card-title text-lg">
            <a
              href={`/users/${currentUser.id}/projects/${project.id}`}
              className="btn btn-primary btn-sm"
              aria-label={`Open ${project.name} project`}
            >
              {project.name}
            </a>
          </h2>
        </header>

        <p className="text-base-content/70 text-sm mb-4">
          {project.description}
        </p>

        <div className="text-sm text-base-content/70 mb-2" aria-label="Content count">
          You have: {project.content_count || 0} Contents
        </div>

        <div className="divider my-2" role="separator"></div>

        <div className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">Active sources:</div>
            <ActiveSources project={project} />
          </div>

          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">Features:</div>
            <Features project={project} />
          </div>
        </div>
      </div>
    </article>
  );
});

export default ProjectCard;
