import React from "react";
import EntitiesResultMetadata from "./EntitiesResultMetadata";
import EntitiesResult from "./EntitiesResult";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import AIAgentCard from '../../common/AIAgentCard';

const extractData = (namedEntity) => {
  try {
    // Case 1: With result.response structure
    if (namedEntity?.result?.response) {
      return {
        agent_name: namedEntity?.agent_name,
        result: {
          entities: namedEntity?.result?.response?.entities || {},
          metadata: namedEntity?.result?.response?.metadata || {},
          status: namedEntity?.result?.response?.status
        }
      };
    }
    // Case 2: Direct result structure
    else if (namedEntity?.result) {
      return {
        agent_name: namedEntity?.agent_name,
        result: {
          entities: namedEntity?.result?.entities || {},
          metadata: namedEntity?.result?.metadata || {},
          status: namedEntity?.status
        }
      };
    }
    throw new Error('Invalid data structure');
  } catch (error) {
    console.error('Error parsing named entities:', error);
    return null;
  }
};

const NamedEntitiesResult = ({ namedEntity, onRerun }) => {
  const data = extractData(namedEntity);
  if (!data) return null;

  const { agent_name, result: { entities, metadata } } = data;

  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            {agent_name && <AIAgentCard agent_name={agent_name} />}
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('namedEntities')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton
            text={JSON.stringify(namedEntity)}
            copyKey={'named_entities'}
          />
        </div>
      </div>

      <div className="flex gap-6 justify-start w-full flex-col">
        {Object.keys(entities || {}).length > 0 && (
          <EntitiesResult entities={entities} />
        )}
        {Object.keys(metadata || {}).length > 0 && (
          <EntitiesResultMetadata metadata={metadata} />
        )}
      </div>
    </div>
  );
};

export default NamedEntitiesResult;
