import React from 'react';
import { CheckCircle2, XCircle, AlertCircle } from 'lucide-react';

const ResultStatus = ({ status }) => {
  if (!status) return null;

  const { success, error } = status;

  if (!success && !error) return null;

  return (
    <div className="flex flex-wrap items-center gap-2" aria-live="polite">
      {success !== undefined && (
        <div className={`badge badge-lg gap-2 ${success ? 'badge-success' : 'badge-error'}`}>
          {success ? (
            <CheckCircle2 className="h-3 w-3" aria-label="Success" />
          ) : (
            <AlertCircle className="h-3 w-3" aria-label="Error" />
          )}
          {success ? 'Success' : 'Error'}
        </div>
      )}

      {error && (
        <div className="badge badge-lg badge-error badge-outline gap-2">
          <XCircle className="h-3 w-3" aria-label="Error Details" />
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(ResultStatus);
