import React from 'react';
import DocumentTypeItem from './DocumentTypeItem';
const DocumentTypesResult = ({ documentTypes }) => {
  if (!documentTypes) return null;

  const hasValidPrimary = documentTypes.primary && Object.keys(documentTypes.primary).length > 0;
  const hasValidAlternatives = Array.isArray(documentTypes?.alternatives) && documentTypes?.alternatives?.length > 0;

  if (!hasValidPrimary && !hasValidAlternatives) return null;

  return (
    <div className="flex gap-4 flex-col border shadow-md">
      {hasValidPrimary && (
        <DocumentTypeItem type="primary" data={documentTypes.primary} />
      )}
      {hasValidAlternatives &&
        documentTypes.alternatives.map((alt, index) => (
          <DocumentTypeItem
            key={`alt-${index}-${alt.id || index}`}
            type="alternative"
            data={alt}
          />
        ))
      }
    </div>
  );
};

export default DocumentTypesResult;
