import React, { useState } from 'react'
import { Search, X } from 'lucide-react'
const SearchContent = ({handleSearchQueryChange}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const isSearchActive = searchQuery.length >= 3

  const handleSearchQuery = (e) => {
    e.preventDefault()
    setSearchQuery(e.target.value)
    handleSearchQueryChange(e.target.value)
  }

  const resetSearchQuery = () => {
    setSearchQuery('')
    handleSearchQueryChange('')
  }

  return (
    <div className="flex-1 relative">
      <input
        type="text"
        placeholder="Please Enter at least 3 characters"
        className={`input input-bordered w-full pl-10 ${searchQuery.length >
        0 && !isSearchActive ? 'input-warning' : isSearchActive
          ? 'input-success'
          : ''
        }`}
        value={searchQuery}
        onChange={(e) => handleSearchQuery(e)}
      />
      <Search
        className={`absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 ${isSearchActive
          ? 'text-success'
          : 'text-base-content/50'
        }`}
      />
      {searchQuery?.length > 0 && (
        <button
          className="btn btn-xs btn-error absolute right-3 top-1/2 -translate-y-1/2"
          onClick={resetSearchQuery}>
          Cancel
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
}

export default SearchContent
