import React from 'react';
import { FileText } from 'lucide-react';

const EvidenceList = ({ evidence, isPrimary }) => {
  if (!Array.isArray(evidence) || evidence.length === 0) return null;

  const textColorClass = isPrimary ? 'text-primary' : 'text-secondary';

  return (
    <div className="mt-4">
      <div className="flex items-center gap-2 text-sm font-medium mb-2">
        <FileText className="h-4 w-4" />
        <span>Evidence</span>
      </div>
      <div className="overflow-x-auto">
        <table className="table table-lg w-full">
          <thead className="sr-only">
            <tr>
              <th>Evidence Item</th>
            </tr>
          </thead>
          <tbody>
            {evidence.map((item, index) => (
              <tr key={`evidence-${index}-${String(item).slice(0, 10)}`}>
                <td className="whitespace-normal">
                  <span className={`text-xs ${textColorClass}`}>
                    {String(item)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EvidenceList;
