import React, { useState, useEffect } from 'react'
import { X, Upload, AlertCircle } from 'lucide-react'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import XHRUpload from '@uppy/xhr-upload'

const UppyUploadModal = ({
  id,
  isOpen,
  onClose,
  isAudioOnly = false,
  onComplete,
  userId,
  projectId
}) => {
  const [error, setError] = useState('')
  const [uppy, setUppy] = useState(null)

  useEffect(() => {
    const uppyInstance = new Uppy({
      id: `uppy-${id}`,
      maxNumberOfFiles: 1,
      debug: false,
      inline: false,
      showProgressDetails: true,
      allowedFileTypes: isAudioOnly
        ? ['audio/flac', 'audio/mp3', 'audio/mp4', 'audio/mpeg', 'audio/mpga', 'audio/m4a', 'audio/ogg', 'audio/wav', 'audio/webm']
        : ['application/pdf', 'image/jpeg', 'image/png', 'image/gif', 'image/webp'],
      allowMultipleUploadBatches: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 25 * 1024 * 1024,
        maxNumberOfFiles: 1,
      },
    })
      .use(XHRUpload, {
        endpoint: `/users/${userId}/projects/${projectId}/create_file`,
        fieldName: 'document[files][]',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
          'Accept': 'application/json'
        },
        formData: true,
        bundle: true,
        getResponseError: (responseText, response) => {
          if (response.status === 422) {
            try {
              const jsonResponse = JSON.parse(responseText)
              return jsonResponse.error || 'Upload failed'
            } catch (e) {
              return 'Upload failed'
            }
          }
          return 'Server error occurred'
        }
      })

    uppyInstance.on('file-added', (file) => {
      setError('') // Clear any previous errors
      file.meta = {
        ...file.meta,
        relativePath: file.relativePath || file.name
      }
    })

    uppyInstance.on('upload-success', (file, response) => {
      if (response.status === 200) {
        onComplete?.(response.body)
        handleClose()
      } else {
        setError('Upload completed but server returned an unexpected response')
      }
    })

    uppyInstance.on('upload-error', (file, error, response) => {
      const errorMessage = error?.message || 'Upload failed'
      setError(errorMessage)

      // If there's a response with an error message, use that
      if (response?.body?.error) {
        setError(response.body.error)
      }
    })

    setUppy(uppyInstance)

    return () => {
      uppyInstance.clear()
      setTimeout(() => {
        uppyInstance.destroy()
      }, 0)
    }
  }, [userId, projectId])

  useEffect(() => {
    const modalElement = document.getElementById(`uppy_upload_modal_${id}`)
    if (modalElement) {
      if (isOpen) {
        modalElement.showModal()
        setError('') // Clear any previous errors when opening
      } else {
        modalElement.close()
      }
    }
  }, [isOpen, id])

  const handleClose = () => {
    setError('')
    onClose()
  }

  return (
    <dialog id={`uppy_upload_modal_${id}`} className="modal border border-primary">
      <div className="modal-box relative max-w-4xl max-h-full">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          <X className="h-4 w-4" />
        </button>

        <h3 className="font-bold text-lg flex items-center gap-2 mb-6">
          <Upload className="h-5 w-5" />
          Upload {isAudioOnly ? 'Audio' : 'Files'}
        </h3>

        {error && (
          <div className="alert alert-error mb-4">
            <AlertCircle className="h-4 w-4" />
            <span>{error}</span>
          </div>
        )}

        <div className="uppy-dashboard-container">
          {uppy && (
            <Dashboard
              uppy={uppy}
              width="100%"
              height="450px"
              showProgressDetails={true}
              proudlyDisplayPoweredByUppy={false}
              note={isAudioOnly
                ? 'Audio files only. Maximum file size: 25MB'
                : 'Images and PDFs only. Maximum file size: 25MB'
              }
            />
          )}
        </div>

        <div className="flex justify-end gap-2 mt-4">
          <button
            type="button"
            className="btn"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  )
}

export default UppyUploadModal
