import React from 'react';
import { Calendar } from 'lucide-react';
import CopyButton from '../common/CopyButton';
import utils from '../../utils';
import { useTextSelection } from '../../contexts/TextSelectionContext'

const ContentBody = ({ content }) => {
  if (!content?.body) return null;

  const { formatDate } = utils;
  const { text } = useTextSelection();
  const getWordCount = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const getCharCount = (text) => text.length;

  const safeDate = content?.created_at ? formatDate(content?.created_at) : '';
  const wordCount = getWordCount(content?.body);
  const charCount = getCharCount(content?.body);

  return (
    <div className="card bg-base-100 shadow-lg border border-primary rounded-lg">
      <div className="card-body p-4">
        <div className="flex flex-col gap-2 mb-4">
          {content.title && (
            <h2 className="card-title text-lg font-semibold">
              {String(content?.title)}
            </h2>
          )}
          {safeDate && (
            <div className="flex items-center gap-2 text-sm text-base-content/70">
              <Calendar className="h-4 w-4" />
              <span>{safeDate}</span>
            </div>
          )}
        </div>

        <div className="mb-4">
          <div className="bg-base-200 rounded-lg p-4 relative">
            <div className="prose max-w-none mb-4 text-sm whitespace-pre-wrap break-words">
              {content.body}
            </div>

            <div className="flex flex-wrap justify-between items-center mt-4 pt-3 border-t border-base-300 gap-4">
              <div className="flex items-center gap-2 flex-wrap">
                <div className="badge badge-neutral">
                  {charCount?.toLocaleString()} characters
                </div>
                <div className="badge badge-neutral">
                  {wordCount?.toLocaleString()} words
                </div>
                {typeof content?.token_len === 'number' && (
                  <div className="badge badge-neutral">
                    {content?.token_len?.toLocaleString()} tokens
                  </div>
                )}
                {content?.source && (
                  <div className="badge badge-primary">
                    {String(content?.source)}
                  </div>
                )}
              </div>

              <div className="flex items-center gap-2">
                <CopyButton
                  text={content?.body}
                  copyKey={`content-${content?.id || Date.now()}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentBody;
