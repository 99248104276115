import React from "react";
import {
  FileText, Building2, MapPin, Cog, ChevronRight, LinkIcon, Tag,
  Box, Info, Globe, Building, User, Calendar, Phone, Mail, MoreHorizontal
} from "lucide-react";

const MetadataSection = ({ title, icon: Icon, isEmpty, children }) => {
  if (isEmpty) return null;
  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2">
        <div className="p-2 rounded-lg bg-primary/10">
          <Icon className="w-4 h-4 text-primary" />
        </div>
        <h3 className="font-medium">{title}</h3>
      </div>
      <div className="pl-10">{children}</div>
    </div>
  );
};

const MetadataList = ({ items, icon: Icon }) => {
  if (!Array.isArray(items) || items.length === 0) return null;
  return (
    <div className="grid gap-2">
      {items.map((item, index) => (
        <div key={`item-${index}`} className="flex items-center gap-2 p-2 rounded-lg bg-base-200/50">
          <Icon className="w-4 h-4 text-base-content/60" />
          <span className="text-sm">{String(item)}</span>
        </div>
      ))}
    </div>
  );
};

const KeyValuePairs = ({ pairs }) => {
  if (!pairs || typeof pairs !== 'object' || Object.keys(pairs).length === 0) return null;
  return (
    <div className="grid gap-2">
      {Object.entries(pairs).map(([key, value]) => (
        <div key={`pair-${key}`} className="p-2 rounded-lg bg-base-200/50">
          <div className="text-xs text-base-content/60 mb-1">
            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </div>
          <div className="text-sm font-medium">{String(value)}</div>
        </div>
      ))}
    </div>
  );
};

const DateSection = ({ dates }) => {
  if (!dates || typeof dates !== 'object') return null;
  return (
    <div className="grid gap-2">
      {Object.entries(dates).map(([key, value]) => {
        if (!value) return null;
        try {
          const date = new Date(value);
          if (isNaN(date.getTime())) return null;
          return (
            <div key={`date-${key}`} className="p-2 rounded-lg bg-base-200/50">
              <div className="text-xs text-base-content/60 mb-1">
                {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </div>
              <div className="text-sm font-medium">{date.toLocaleDateString()}</div>
            </div>
          );
        } catch {
          return null;
        }
      })}
    </div>
  );
};

const ValidityPeriod = ({ validity }) => {
  if (!validity || typeof validity !== 'object') return null;
  const { start, end } = validity;

  const formatDate = (dateStr) => {
    try {
      const date = new Date(dateStr);
      return isNaN(date.getTime()) ? null : date.toLocaleDateString();
    } catch {
      return null;
    }
  };

  const startDate = start ? formatDate(start) : null;
  const endDate = end ? formatDate(end) : null;

  if (!startDate && !endDate) return null;

  return (
    <div className="grid gap-2">
      {startDate && (
        <div className="p-2 rounded-lg bg-base-200/50">
          <div className="text-xs text-base-content/60 mb-1">Start Date</div>
          <div className="text-sm font-medium">{startDate}</div>
        </div>
      )}
      {endDate && (
        <div className="p-2 rounded-lg bg-base-200/50">
          <div className="text-xs text-base-content/60 mb-1">End Date</div>
          <div className="text-sm font-medium">{endDate}</div>
        </div>
      )}
    </div>
  );
};

const MetadataResultExtractedMetadataResult = ({ extractedMetadata }) => {
  if (!extractedMetadata || typeof extractedMetadata !== 'object') return null;

  const {
    document_info,
    primary_metadata,
    entity_metadata,
    temporal_metadata,
    technical_metadata
  } = extractedMetadata;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center gap-2">
          <FileText className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Extracted Metadata</h2>
        </div>

        <div className="grid gap-6">
          <MetadataSection
            title="Document Information"
            icon={Info}
            isEmpty={!document_info || Object.keys(document_info).length === 0}
          >
            <div className="grid md:grid-cols-3 gap-3">
              {document_info && Object.entries(document_info).map(([key, value]) => (
                <div key={`doc-${key}`} className="p-3 rounded-lg bg-base-200/50">
                  <div className="text-xs text-base-content/60 mb-1">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </div>
                  <div className="text-sm font-medium">{String(value)}</div>
                </div>
              ))}
            </div>
          </MetadataSection>

          <MetadataSection
            title="Primary Information"
            icon={Tag}
            isEmpty={!primary_metadata?.key_value_pairs}
          >
            <KeyValuePairs pairs={primary_metadata?.key_value_pairs} />
          </MetadataSection>

          {entity_metadata && (
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  title: "Organizations",
                  icon: Building2,
                  content: (
                    <div className="space-y-4">
                      <MetadataList items={entity_metadata.organizations?.names} icon={Building} />
                      <MetadataList items={entity_metadata.organizations?.identifiers} icon={LinkIcon} />
                    </div>
                  ),
                  isEmpty: !entity_metadata.organizations?.names?.length && !entity_metadata.organizations?.identifiers?.length
                },
                {
                  title: "People",
                  icon: User,
                  content: (
                    <div className="space-y-4">
                      <MetadataList items={entity_metadata.people?.names} icon={User} />
                      <MetadataList items={entity_metadata.people?.roles} icon={ChevronRight} />
                    </div>
                  ),
                  isEmpty: !entity_metadata.people?.names?.length && !entity_metadata.people?.roles?.length
                },
                {
                  title: "Locations",
                  icon: MapPin,
                  content: (
                    <div className="space-y-4">
                      <MetadataList items={entity_metadata.locations?.regions} icon={Globe} />
                      <MetadataList items={entity_metadata.locations?.addresses} icon={MapPin} />
                    </div>
                  ),
                  isEmpty: !entity_metadata.locations?.addresses?.length && !entity_metadata.locations?.regions?.length
                },
                {
                  title: "Dates",
                  icon: Calendar,
                  content: <MetadataList items={entity_metadata.dates?.dates} icon={Calendar} />,
                  isEmpty: !entity_metadata.dates?.dates?.length
                },
                {
                  title: "Identifiers",
                  icon: LinkIcon,
                  content: <MetadataList items={entity_metadata.identifiers?.identifiers} icon={LinkIcon} />,
                  isEmpty: !entity_metadata.identifiers?.identifiers?.length
                },
                {
                  title: "Contact Information",
                  icon: Phone,
                  content: <MetadataList items={entity_metadata.contact_info?.contact_info} icon={Mail} />,
                  isEmpty: !entity_metadata.contact_info?.contact_info?.length
                },
                {
                  title: "Other Information",
                  icon: MoreHorizontal,
                  content: <MetadataList items={entity_metadata.other?.other} icon={Box} />,
                  isEmpty: !entity_metadata.other?.other?.length
                }
              ].map((section, index) => (
                <MetadataSection
                  key={`section-${index}`}
                  title={section.title}
                  icon={section.icon}
                  isEmpty={section.isEmpty}
                >
                  {section.content}
                </MetadataSection>
              ))}
            </div>
          )}

          {temporal_metadata && (
            <div className="grid md:grid-cols-2 gap-6">
              <MetadataSection
                title="Dates"
                icon={Calendar}
                isEmpty={!temporal_metadata.dates}
              >
                <DateSection dates={temporal_metadata.dates} />
              </MetadataSection>

              <MetadataSection
                title="Validity Period"
                icon={Calendar}
                isEmpty={!temporal_metadata.validity_periods?.start && !temporal_metadata.validity_periods?.end}
              >
                <ValidityPeriod validity={temporal_metadata.validity_periods} />
              </MetadataSection>
            </div>
          )}

          <MetadataSection
            title="Technical Information"
            icon={Cog}
            isEmpty={!technical_metadata?.standards?.length && !technical_metadata?.certifications?.length && !technical_metadata?.identifiers?.length}
          >
            <div className="grid md:grid-cols-3 gap-4">
              <MetadataList items={technical_metadata?.standards} icon={Box} />
              <MetadataList items={technical_metadata?.certifications} icon={ChevronRight} />
              <MetadataList items={technical_metadata?.identifiers} icon={LinkIcon} />
            </div>
          </MetadataSection>
        </div>
      </div>
    </div>
  );
};

export default MetadataResultExtractedMetadataResult;
