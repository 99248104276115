import React from 'react';
import {
  ChevronLeft,
  ChevronRight,
} from 'lucide-react'
import ContentRow from "./ContentRow";

const Contents = ({ contents, pagination, currentUser, project, onDelete, onPaginate }) => {
  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead className="bg-base-200">
            <tr>
              <th className="font-medium">Title</th>
              <th className="font-medium">Created At</th>
              <th className="font-medium">Quick Results</th>
              <th className="font-medium">Sources</th>
              <th className="font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {contents?.map((item, index) => (
              <ContentRow key={index} item={item} currentUser={currentUser} project={project} onDelete={onDelete} />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center px-4 py-2">
        <div className="text-sm text-base-content/70">
          Showing {pagination?.from} to {pagination?.to} of {pagination?.count} items
        </div>
        <div className="flex gap-2">
          <button
            className="btn btn-sm gap-1"
            onClick={() => onPaginate(pagination?.prev)}
            disabled={!pagination?.prev}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </button>
          <button
            className="btn btn-sm gap-1"
            onClick={() => onPaginate(pagination?.next)}
            disabled={!pagination?.next}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contents;
