import React from 'react';
import EvidenceList from './EvidenceList';
import ConfidenceIndicator from './ConfidenceIndicator';
import { Building2 } from 'lucide-react';

const IndustryItem = ({ type, data }) => {
  if (!data) return null;

  const isPrimary = type === 'primary';
  const iconColorClass = isPrimary ? 'text-primary' : 'text-secondary';
  const title = isPrimary ? 'Primary Industry' : 'Alternative Industry';

  return (
    <div className="card bg-base-100 shadow-sm">
      <div className="card-body p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Building2 className={`h-4 w-4 ${iconColorClass}`} />
            <h3 className="text-sm font-medium">{title}</h3>
          </div>
          {data.confidence !== undefined && (
            <ConfidenceIndicator confidence={data.confidence} />
          )}
        </div>

        {data.value && (
          <>
            <div className="divider my-2" />
            <div className="flex items-center gap-2">
              <span className="text-sm">{data.value}</span>
            </div>
          </>
        )}

        {Array.isArray(data.evidence) && data.evidence.length > 0 && (
          <EvidenceList evidence={data.evidence} isPrimary={isPrimary} />
        )}
      </div>
    </div>
  );
};

export default IndustryItem;
