import React, { useEffect, useRef } from 'react';
import { AlertCircle, Trash2, X } from 'lucide-react';

const DeleteContentModal = ({ isOpen, onClose, onDelete, contentToDelete }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (!dialogRef.current) return;

    if (isOpen) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof onDelete === 'function') {
      onDelete();
    }
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <dialog ref={dialogRef} className="modal border border-primary">
      <div className="modal-box relative max-w-lg">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          aria-label="Close modal"
        >
          <X className="h-4 w-4" />
        </button>

        <h3 className="font-bold text-lg flex items-center gap-2 mb-6">
          <Trash2 className="h-5 w-5" />
          Delete Content
        </h3>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-start gap-2">
            <AlertCircle className="h-5 w-5 text-error flex-shrink-0 mt-1" />
            <div>
              <p className="font-medium">Are you sure you want to delete this content?</p>
              {contentToDelete?.title && (
                <p className="text-sm text-gray-600">
                  "{contentToDelete.title}"
                </p>
              )}
              <p className="text-sm text-error mt-1">
                This action cannot be undone.
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-error gap-2"
              aria-label="Delete content"
            >
              <Trash2 className="h-5 w-5" />
              Delete
            </button>
          </div>
        </form>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  );
};

export default DeleteContentModal;
