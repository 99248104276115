import CopyButton from '../../common/CopyButton';
import React from 'react';
import { Mail } from 'lucide-react';

const EmailSource = ({ disabled, emailToken, onToggle, baseUrl, project }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between bg-base-200 p-4 rounded-lg opacity-[.85]">
        <div className="flex items-center gap-3">
          <Mail className={`h-5 w-5 ${disabled ? 'text-base-content/50' : 'text-primary'}`} />
          <div>
            <p className="font-medium">Email Address</p>
            <p className="text-sm text-base-content/70">{`project-${emailToken}@${baseUrl}`}</p>
          </div>
        </div>
        {project?.has_email_access && <CopyButton text={`project-${emailToken}@${baseUrl}`} copyKey="email" />}
      </div>

      <div className="flex justify-end">
        <button
          onClick={onToggle}
          className="btn btn-sm btn-primary"
        >
          {project?.has_email_access ? 'Disable Email Access' : 'Enable Email Access'}
        </button>
      </div>
    </div>
  )
}

export default EmailSource;
