import Toastify from 'toastify-js'

export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const toast = ({message, duration = 3000}) => {
  Toastify({
    close: true,
    gravity: "top", 
    position: "center",
    text: message,
    duration: duration,
    className: "alert alert-info z-[100] shadow-lg rounded-lg border-2 border-primary",
  }).showToast();
}
const utils = {
  formatDate: formatDate,
  toast: toast
};

export default utils;
