import React from "react";
import {
  AlertTriangle,
  ShieldAlert,
  Gauge,
  BarChart2
} from "lucide-react";

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidNumber = num => typeof num === 'number' && !isNaN(num) && num >= 0 && num <= 100;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

const getRiskColor = (level) => {
  if (!isValidString(level)) return "text-gray-500";

  const colors = {
    critical: "text-red-500",
    high: "text-orange-500",
    medium: "text-yellow-500",
    low: "text-green-500"
  };
  return colors[level.toLowerCase()] || "text-gray-500";
};

const getRiskBackground = (level) => {
  if (!isValidString(level)) return "bg-gray-50";

  const colors = {
    critical: "bg-red-50",
    high: "bg-orange-50",
    medium: "bg-yellow-50",
    low: "bg-green-50"
  };
  return colors[level.toLowerCase()] || "bg-gray-50";
};

const getLevelMessage = (level) => {
  if (!isValidString(level)) return { title: "", description: "" };

  const messages = {
    critical: {
      title: "Immediate action required",
      description: "Critical risks detected that require immediate intervention."
    },
    high: {
      title: "Prompt attention needed",
      description: "Significant risks identified that should be addressed promptly."
    },
    medium: {
      title: "Monitor and address soon",
      description: "Moderate risks present that should be monitored and addressed."
    },
    low: {
      title: "Minor concerns identified",
      description: "Low-level risks detected that should be reviewed when convenient."
    }
  };
  return messages[level.toLowerCase()] || { title: "", description: "" };
};

const CircularProgress = ({ value, level }) => {
  const safeValue = isValidNumber(value) ? value : 0;
  const dashArray = safeValue * 3.77;

  return (
    <div className="relative w-32 h-32">
      <svg className="w-full h-full transform -rotate-90">
        <circle
          cx="64"
          cy="64"
          r="60"
          fill="none"
          stroke="currentColor"
          strokeWidth="8"
          className="text-base-200"
        />
        <circle
          cx="64"
          cy="64"
          r="60"
          fill="none"
          stroke="currentColor"
          strokeWidth="8"
          strokeDasharray={`${dashArray} 377`}
          className={getRiskColor(level)}
        />
      </svg>
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <span className="text-3xl font-bold">{safeValue}</span>
        <span className="text-sm text-base-content/60">score</span>
      </div>
    </div>
  );
};

const ConfidenceBar = ({ confidence }) => {
  const safeConfidence = isValidNumber(confidence) ? confidence : 0;

  return (
    <div className="space-y-2">
      <div className="flex justify-between text-sm">
        <span className="text-base-content/60">Confidence</span>
        <span className="font-medium">{safeConfidence}%</span>
      </div>
      <div className="h-2 bg-base-200 rounded-full overflow-hidden">
        <div
          className="h-full bg-primary rounded-full transition-all"
          style={{ width: `${safeConfidence}%` }}
        />
      </div>
    </div>
  );
};

const AnomaliesResultRiskAssessment = ({ riskAssessment }) => {
  if (!isValidObject(riskAssessment)) return null;

  const {
    level = 'medium',
    score = 0,
    confidence = 0
  } = riskAssessment;

  const safeScore = isValidNumber(score) ? score : 0;
  const safeConfidence = isValidNumber(confidence) ? confidence : 0;
  const levelMessage = getLevelMessage(level);

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center gap-2">
          <ShieldAlert className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Risk Assessment</h2>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div className={`p-6 rounded-lg ${getRiskBackground(level)} flex flex-col items-center justify-center gap-4`}>
            <CircularProgress value={safeScore} level={level} />
            <div className="flex items-center gap-2">
              <AlertTriangle className={`w-4 h-4 ${getRiskColor(level)}`} />
              <span className={`font-medium capitalize ${getRiskColor(level)}`}>
                {level} Risk Level
              </span>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <Gauge className="w-4 h-4 text-base-content/60" />
                <h3 className="font-medium">Risk Metrics</h3>
              </div>
              <div className="grid gap-4">
                <div className="p-3 rounded-lg bg-base-200/50">
                  <div className="text-sm text-base-content/60 mb-1">Score Assessment</div>
                  <div className="text-2xl font-bold">{safeScore}<span className="text-base font-normal text-base-content/60">/100</span></div>
                </div>
                <div className="p-3 rounded-lg bg-base-200/50">
                  <div className="text-sm text-base-content/60 mb-1">Risk Level</div>
                  <div className={`text-2xl font-bold capitalize ${getRiskColor(level)}`}>{level}</div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center gap-2 mb-3">
                <BarChart2 className="w-4 h-4 text-base-content/60" />
                <h3 className="font-medium">Confidence Score</h3>
              </div>
              <ConfidenceBar confidence={safeConfidence} />
            </div>
          </div>
        </div>

        <div className={`p-4 rounded-lg ${getRiskBackground(level)} flex items-start gap-3`}>
          <AlertTriangle className={`w-5 h-5 ${getRiskColor(level)} mt-0.5`} />
          <div>
            <p className={`font-medium ${getRiskColor(level)} mb-1`}>
              {levelMessage.title}
            </p>
            <p className="text-sm text-base-content/70">
              {levelMessage.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnomaliesResultRiskAssessment;
