import React, { useState } from 'react'
import { Copy, Check } from 'lucide-react'
import utils from '../../utils'
const CopyButton = ({ text, copyKey }) => {
  const [copiedStates, setCopiedStates] = useState({ [copyKey]: false })
  const { toast } = utils

  const handleCopy = async (text, key) => {
    try {
      await navigator.clipboard.writeText(text)
      setCopiedStates(prev => ({ ...prev, [key]: true }))
      toast({ message: 'Copied to clipboard' })
      setTimeout(() => {
        setCopiedStates(prev => ({ ...prev, [key]: false }))
      }, 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  return (
    <button
      className={`inline-flex items-center px-3 py-1 text-sm font-medium rounded-md ${copiedStates[copyKey]
        ? 'bg-green-500 text-white'
        : 'border border-green-500 text-green-500 hover:bg-green-50'
        }`}
      onClick={() => handleCopy(text, copyKey)}
    >
      {copiedStates[copyKey] ? (
        <>
          <Check className="w-4 h-4 mr-1" />
          Copied!
        </>
      ) : (
        <>
          <Copy className="w-4 h-4 mr-1" />
          Copy
        </>
      )}
    </button>
  )
}

export default CopyButton
