import React from "react";
import { FileText, List, BarChart2, ArrowDownIcon, CheckCircle2 } from "lucide-react";

const SummaryResult = ({ summary }) => {
  if (!summary) return null;

  const hasValidLength = summary.length && typeof summary.length === 'object' && (
    summary.length.original ||
    summary.length.summary ||
    summary.length.reduction_percentage
  );

  const hasValidBullets = Array.isArray(summary.bullets) && summary.bullets.length > 0;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-8">
        {summary.main && (
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <FileText className="w-4 h-4 text-primary" />
              </div>
              <h3 className="font-semibold">Main Summary</h3>
            </div>
            <div className="p-4 rounded-lg bg-base-200/50">
              <p className="text-base leading-relaxed">{String(summary.main)}</p>
            </div>
          </div>
        )}

        {hasValidLength && (
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <BarChart2 className="w-4 h-4 text-primary" />
              </div>
              <h3 className="font-semibold">Summary Statistics</h3>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {summary.length.original !== undefined && (
                <div className="flex flex-col items-center p-4 rounded-lg bg-base-200/50">
                  <span className="text-sm text-base-content/60 mb-1">Original Length</span>
                  <span className="text-2xl font-bold text-primary">
                    {Number(summary.length.original).toLocaleString()}
                  </span>
                  <span className="text-xs text-base-content/60 mt-1">characters</span>
                </div>
              )}
              {summary.length.summary !== undefined && (
                <div className="flex flex-col items-center p-4 rounded-lg bg-base-200/50">
                  <span className="text-sm text-base-content/60 mb-1">Summary Length</span>
                  <span className="text-2xl font-bold text-primary">
                    {Number(summary.length.summary).toLocaleString()}
                  </span>
                  <span className="text-xs text-base-content/60 mt-1">characters</span>
                </div>
              )}
              {summary.length.reduction_percentage !== undefined && (
                <div className="flex flex-col items-center p-4 rounded-lg bg-success/10">
                  <span className="text-sm text-success/80 mb-1">Reduction</span>
                  <div className="flex items-center gap-1">
                    <ArrowDownIcon className="w-4 h-4 text-success" />
                    <span className="text-2xl font-bold text-success">
                      {Number(summary.length.reduction_percentage).toLocaleString()}%
                    </span>
                  </div>
                  <span className="text-xs text-success/80 mt-1">shorter</span>
                </div>
              )}
            </div>
          </div>
        )}

        {hasValidBullets && (
          <div className="space-y-3">
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <List className="w-4 h-4 text-primary" />
              </div>
              <h3 className="font-semibold">Key Points</h3>
            </div>
            <div className="grid gap-2">
              {summary.bullets.map((bullet, index) => (
                <div
                  key={`bullet-${index}-${String(bullet).slice(0, 10)}`}
                  className="flex items-start gap-3 p-3 rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors"
                >
                  <CheckCircle2 className="w-4 h-4 mt-1 text-success" />
                  <span className="text-sm leading-relaxed">{String(bullet)}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryResult;
