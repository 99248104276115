import {
  AlertTriangle,
  BookDown, Code, DatabaseBackup,
  FileText, FolderOpen,
  HeartPulse, Lightbulb, Mail, Radio,
  Tags, User, Calendar, Copy, Lock, FileSignature
} from 'lucide-react'
import SettingsModal from './SettingsModal'
import React from 'react'

const SubscriptionInfoModal = ({ isOpen, onClose, currentUser }) => {

  const planNames = {
    free: 'Free',
    startup: 'Startup',
    professional: 'Professional',
    enterprise: 'Enterprise'
  };

  const featureConfigs = [
    {
      condition: 'has_tags',
      Icon: Tags,
      tooltip: 'Tags',
      color: 'text-purple-500',
      ariaLabel: 'Tag Detection Feature'
    },
    {
      condition: 'has_anomaly_detector',
      Icon: AlertTriangle,
      tooltip: 'Anomaly Detector',
      color: 'text-purple-500',
      ariaLabel: 'Anomaly Detection Feature'
    },
    {
      condition: 'has_category',
      Icon: BookDown,
      tooltip: 'Category',
      color: 'text-blue-500',
      ariaLabel: 'Category Detection Feature'
    },
    {
      condition: 'has_sentiment',
      Icon: HeartPulse,
      tooltip: 'Sentiment',
      color: 'text-red-500',
      ariaLabel: 'Sentiment Analysis Feature'
    },
    {
      condition: 'has_summary',
      Icon: FileText,
      tooltip: 'Summary',
      color: 'text-orange-500',
      ariaLabel: 'Summary Generation Feature'
    },
    {
      condition: 'has_actionable_insights',
      Icon: Lightbulb,
      tooltip: 'Insights',
      color: 'text-yellow-500',
      ariaLabel: 'Actionable Insights Feature'
    },
    {
      condition: 'has_metadata_extractor',
      Icon: DatabaseBackup,
      tooltip: 'Metadata Extractor',
      color: 'text-cyan-500',
      ariaLabel: 'Metadata Extraction Feature'
    },
    {
      condition: 'has_named_entity_recognition',
      Icon: User,
      tooltip: 'Named Entity Recognition',
      color: 'text-emerald-500',
      ariaLabel: 'Named Entity Recognition Feature'
    },
    {
      condition: 'has_duplicate_detection',
      Icon: Copy,
      tooltip: 'Duplicate Detection',
      color: 'text-purple-500',
      ariaLabel: 'Duplicate Detection Feature'
    },
    {
      condition: 'has_pii_detection',
      Icon: Lock,
      tooltip: 'PII Detection',
      color: 'text-purple-500',
      ariaLabel: 'PII Detection Feature'
    },
    {
      condition: 'has_date_extraction',
      Icon: Calendar,
      tooltip: 'Date Extraction',
      color: 'text-purple-500',
      ariaLabel: 'Date Extraction Feature'
    },
  ];

  const sourceConfigs = [
    {
      id: 'has_public_access',
      label: 'Public Folder',
      Icon: FolderOpen,
      color: 'text-orange-500'
    },
    {
      id: 'has_email_access',
      label: 'Email',
      Icon: Mail,
      color: 'text-blue-500'
    },
    {
      id: 'has_js_access',
      label: 'JS Snippet',
      Icon: Code,
      color: 'text-emerald-500'
    },
    {
      id: 'has_api_access',
      label: 'API',
      Icon: Radio,
      color: 'text-purple-500'
    }
  ];

  return (
    <SettingsModal isOpen={isOpen} onClose={onClose} title="Subscription Information">
      <div className="space-y-4">
        <div className="bg-base-200 p-4 rounded-lg">
          <h4 className="font-medium">Current Plan</h4>
          <p className="text-sm text-gray-600">{planNames[currentUser?.plan_type] || 'Unknown'} Plan</p>
          <p className="text-xs text-gray-500">Status: {currentUser?.stripe_subscription || 'Not Available'}</p>
        </div>

        <div className="bg-base-200 p-4 rounded-lg space-y-3">
          <h4 className="font-medium">Usage Limits</h4>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>
              <p className="text-gray-600">
                Projects: {currentUser?.last_project_id || 0}/{currentUser?.limit_projects || 'Unlimited'}
              </p>
              <p className="text-gray-600">
                Content: {currentUser?.current_limit_content || 0}/{currentUser?.limit_content || 'Unlimited'}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-base-200 p-4 rounded-lg space-y-2">
          <h4 className="font-medium">Agents</h4>
          <div className="grid grid-cols-4 gap-2 mt-2">
            {featureConfigs.map(({ condition, Icon, tooltip, color, ariaLabel }) => {
              const isEnabled = currentUser?.limit_plugins?.includes(condition);
              return (
                <div
                  key={condition}
                  className={`flex flex-col items-center p-2 rounded-lg ${isEnabled ? 'opacity-100' : 'opacity-40'}`}
                  title={tooltip}
                  aria-label={ariaLabel}
                >
                  <Icon className={`w-6 h-6 ${isEnabled ? color : 'text-gray-400'}`} />
                  <span className="text-xs text-center mt-1">{tooltip}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bg-base-200 p-4 rounded-lg">
          <h4 className="font-medium mb-2">Sources</h4>
          <div className="grid grid-cols-4 gap-2 mt-2">
            {sourceConfigs.map(({ id, label, Icon, color }) => {
              const isEnabled = currentUser?.limit_sources?.includes(id);
              return (
                <div
                  key={id}
                  className={`flex flex-col items-center p-2 rounded-lg ${isEnabled ? 'opacity-100' : 'opacity-40'}`}
                  title={label}
                >
                  <Icon className={`w-6 h-6 ${isEnabled ? color : 'text-gray-400'}`} />
                  <span className="text-xs text-center mt-1">{label}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </SettingsModal>
  );
};

export default SubscriptionInfoModal;
