import React from 'react';
import {
  PlusCircle,
  File,
  Image,
  AudioLines,
  Trash2,
  MoreVertical
} from 'lucide-react';

const ContentActionsDropdown = ({ onAddContent, onAddFile, onAddImage, onAddAudio, onDelete }) => {
  return (
    <div className="dropdown dropdown-end">
      <label
        tabIndex={0}
        className="btn btn-circle btn-primary"
      >
        <MoreVertical className="h-5 w-5" />
      </label>

      <ul
        tabIndex={0}
        className="dropdown-content z-[1] menu p-2 shadow-lg bg-base-100 rounded-box w-52 border-2 border-base-200"
      >
        <li>
          <button
            onClick={onAddContent}
            className="flex items-center gap-3 p-3 hover:bg-base-200 rounded-lg"
          >
            <PlusCircle className="h-5 w-5" />
            <span>Add Content</span>
          </button>
        </li>

        <li>
          <button
            onClick={onAddFile}
            className="flex items-center gap-3 p-3 hover:bg-base-200 rounded-lg"
          >
            <File className="h-5 w-5" />
            <span>Add File</span>
          </button>
        </li>

        <li>
          <button
            onClick={onAddImage}
            className="flex items-center gap-3 p-3 hover:bg-base-200 rounded-lg"
          >
            <Image className="h-5 w-5" />
            <span>Add Image</span>
          </button>
        </li>

        <li>
          <button
            onClick={onAddAudio}
            className="flex items-center gap-3 p-3 hover:bg-base-200 rounded-lg"
          >
            <AudioLines className="h-5 w-5 text-gray-400" />
            <span>Add Audio</span>
          </button>
        </li>

        <div className="divider my-1"></div>

        <li>
          <button
            onClick={onDelete}
            className="flex items-center gap-3 p-3 hover:bg-base-200 rounded-lg text-error"
          >
            <Trash2 className="h-5 w-5" />
            <span>Delete</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ContentActionsDropdown;
