import React, { useState } from 'react';
import { Bell, Eye, Loader } from 'lucide-react';
import ActivityItem from './ActivityItem';

const LatestNotifications = ({ latestActivitiesPath, newNotifications }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadActivities = async () => {
    if (!latestActivitiesPath) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(latestActivitiesPath, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setActivities(Array.isArray(data) ? data : []);

    } catch (err) {
      console.error('Failed to load activities:', err);
      setError('Failed to load notifications');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        className="tooltip tooltip-bottom"
        data-tip="Notifications"
      >
        <Bell
          onClick={loadActivities}
          className={`w-5 h-5 ${newNotifications
            ? 'text-blue-500 animate-bounce'
            : loading
              ? 'text-primary'
              : 'text-gray-600'
            } hover:text-primary cursor-pointer`}
        />
      </div>

      <div
        tabIndex={0}
        className="dropdown-content z-20 menu bg-base-100 rounded-box min-w-80 shadow-xl border"
      >
        <div className="max-h-[60vh] overflow-y-auto">
          {loading && (
            <div className="flex justify-center items-center p-4">
              <Loader className="w-6 h-6 animate-spin text-primary" />
            </div>
          )}

          {error && (
            <div className="p-4 text-error text-sm text-center">
              {error}
            </div>
          )}

          {!loading && !error && activities.length === 0 && (
            <div className="p-4 text-gray-500 text-sm text-center">
              No notifications
            </div>
          )}

          {!loading && !error && activities.length > 0 && (
            <ul className="menu">
              {activities.map((activity) => (
                <ActivityItem
                  key={activity?.id || Math.random().toString()}
                  activity={activity}
                />
              ))}
            </ul>
          )}
        </div>

        <div className="pt-1 border-t border-base-300 sticky bottom-0 bg-base-100">
          <button
            onClick={() => { }}
            className="btn btn-block btn-sm btn-ghost gap-2"
            disabled={loading}
          >
            <Eye className="w-4 h-4" />
            View All Activities
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestNotifications;
