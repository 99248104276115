import React from "react";
import MetadataResultQualityMetricsResult from "./MetadataResultQualityMetricsResult";
import MetadataResultExtractedMetadataResult from "./MetadataResultExtractedMetadataResult";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import AIAgentCard from '../../common/AIAgentCard'

const MetadataResults = ({ metadata, onRerun }) => {
  if (!metadata?.result || !metadata?.agent_name) return null;

  const { agent_name, result: { quality_metrics, extracted_metadata } } = metadata;
  const hasQualityMetrics = quality_metrics && Object.keys(quality_metrics).length > 0;
  const hasExtractedMetadata = extracted_metadata && Object.keys(extracted_metadata).length > 0;

  if (!hasQualityMetrics && !hasExtractedMetadata) return null;

  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('metadata')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(metadata)} copyKey={`metadata-${agent_name}`} />
        </div>
      </div>

      <div className="flex gap-6 justify-start w-full flex-col">
        {hasQualityMetrics && (
          <MetadataResultQualityMetricsResult qualityMetrics={quality_metrics} />
        )}
        {hasExtractedMetadata && (
          <MetadataResultExtractedMetadataResult extractedMetadata={extracted_metadata} />
        )}
      </div>
    </div>
  );
};

export default MetadataResults;
