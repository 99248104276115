import React from 'react';
import { LineChart, RotateCcw, PieChart, GitBranch } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const MetricsDisplay = ({ metrics = [] }) => {
  if (!Array.isArray(metrics) || metrics.length === 0) return null;

  const getTrendBadgeColor = (trend) => {
    switch (trend) {
      case 'increasing': return 'success';
      case 'decreasing': return 'error';
      case 'stable': return 'warning';
      default: return 'neutral';
    }
  };

  const getBadgeColor = (confidence) => {
    const score = parseFloat(confidence);
    if (isNaN(score)) return 'neutral';
    if (score >= 0.8) return 'success';
    if (score >= 0.5) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <LineChart className="w-4 h-4" />
          Metrics Found
        </h3>
        <div className="space-y-4">
          {metrics.map((metric, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <span className="badge badge-neutral capitalize">
                  {metric?.category || 'Uncategorized'}
                </span>
                <span className={`badge badge-${getBadgeColor(metric?.confidence)}`}>
                  {metric?.confidence ?
                    `${(metric.confidence * 100).toFixed(1)}% Confidence` :
                    'No Score'}
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-sm font-medium">{metric?.name || 'Unnamed Metric'}</p>
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div>
                    <span className="text-xs text-base-content/70">Value: </span>
                    <span className="font-medium">
                      {metric?.value} {metric?.unit || ''}
                    </span>
                  </div>
                  <div>
                    <span className="text-xs text-base-content/70">Timeframe: </span>
                    <span>{metric?.timeframe || 'N/A'}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <span className={`badge badge-${getTrendBadgeColor(metric?.context?.trend)}`}>
                    {metric?.context?.trend || 'No trend'}
                  </span>
                  {metric?.context?.benchmark && (
                    <span className="text-xs">
                      Benchmark: {metric?.context?.benchmark} {metric?.unit || ''}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const RelationshipsDisplay = ({ relationships = [] }) => {
  if (!Array.isArray(relationships) || relationships.length === 0) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <GitBranch className="w-4 h-4" />
          Metric Relationships
        </h3>
        <div className="space-y-4">
          {relationships.map((relation, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="space-y-2">
                <div>
                  <p className="text-xs text-base-content/70">Primary Metric</p>
                  <p className="text-sm font-medium">{relation?.primary_metric}</p>
                </div>
                <div>
                  <p className="text-xs text-base-content/70">Correlation Type</p>
                  <span className="badge badge-neutral">
                    {relation?.correlation_type || 'Unknown'}
                  </span>
                </div>
                {relation?.related_metrics && relation?.related_metrics?.length > 0 && (
                  <div>
                    <p className="text-xs text-base-content/70">Related Metrics:</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {relation?.related_metrics?.map((metric, idx) => (
                        <span key={idx} className="badge badge-sm badge-ghost">
                          {metric}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.extraction_quality) return null;

  const getContextClarityColor = (clarity) => {
    switch (clarity) {
      case 'high': return 'success';
      case 'medium': return 'warning';
      case 'low': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Extraction Quality
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Overall Confidence</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(metadata?.extraction_quality?.overall_confidence || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Completeness</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-secondary rounded-full"
                style={{ width: `${(metadata?.extraction_quality?.completeness || 0) * 100}%` }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">Context Clarity:</span>
            <span className={`badge badge-${getContextClarityColor(metadata?.extraction_quality?.context_clarity)}`}>
              {metadata?.extraction_quality?.context_clarity || 'unknown'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const KpiExtractionResults = ({ kpiExtraction, onRerun }) => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={kpiExtraction?.agent_name || 'Unknown Agent'} />
            </p>
            <div className="flex items-center gap-2">
              <ResultStatus status={kpiExtraction?.status || 'unknown'} />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('kpiExtraction')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(kpiExtraction?.result || {})}
              copyKey="kpi-extraction"
            />
          </div>
        </div>

        {kpiExtraction?.result && (
          <div className="space-y-4">
            <MetricsDisplay metrics={kpiExtraction?.result?.kpi_analysis?.metrics_found} />
            <RelationshipsDisplay relationships={kpiExtraction?.result?.kpi_analysis?.relationships} />
            <MetadataDisplay metadata={kpiExtraction?.result?.metadata} />
          </div>
        )}
      </div>
    </div>
  );
};

export default KpiExtractionResults;
