import React from 'react';
import { FolderOpen, Mail, Code, Radio, House, QrCode, FileAudio } from 'lucide-react';

const SourceDisplay = ({ sourceType }) => {
  const getSourceInfo = (type) => {
    switch (type) {
      case 'public_folder':
        return { label: 'Public Folder', Icon: FolderOpen };
      case 'email':
        return { label: 'Email', Icon: Mail };
      case 'js_snippet':
        return { label: 'JS Snippet', Icon: Code };
      case 'api':
        return { label: 'API', Icon: Radio };
      case 'app':
        return { label: 'Application', Icon: House };
      case 'audio':
        return { label: 'Audio', Icon: FileAudio };
      case 'ocr':
        return { label: 'OCR', Icon: QrCode };
      default:
        return { label: 'Unknown', Icon: Radio };
    }
  };

  const { label, Icon } = getSourceInfo(sourceType);

  return (
    <div className="flex items-center gap-1 text-primary">
      <span>{label}</span>
      <Icon className="h-4 w-4" />
    </div>
  );
};

export default SourceDisplay;
