import React, { useState } from "react";
import {
  PlayCircle,
  Tag,
  Clock,
  AlertCircle,
  BarChart2,
  MessageCircle,
  FileText,
  ChevronDown,
  ChevronUp
} from "lucide-react";

// Utility function to safely check if value is an object
const isObject = (value) => value !== null && typeof value === 'object' && !Array.isArray(value);

// Utility function to safely check if value is an array
const isArray = (value) => Array.isArray(value);

// Utility function to safely replace underscores
const safeReplace = (str) => {
  if (typeof str !== 'string') return '';
  return str.replace(/_/g, ' ');
};

const getCategoryIcon = (category) => {
  if (typeof category !== 'string') return PlayCircle;

  const icons = {
    strategic: AlertCircle,
    operational: Clock,
    customer_service: MessageCircle,
    product: Tag,
    marketing: BarChart2,
    risk: AlertCircle,
    compliance: FileText
  };
  return icons[category] || PlayCircle;
};

const getCategoryColor = (category) => {
  if (typeof category !== 'string') return "bg-gray-100 text-gray-800";

  const colors = {
    strategic: "bg-blue-100 text-blue-800",
    operational: "bg-purple-100 text-purple-800",
    customer_service: "bg-green-100 text-green-800",
    product: "bg-yellow-100 text-yellow-800",
    marketing: "bg-pink-100 text-pink-800",
    risk: "bg-red-100 text-red-800",
    compliance: "bg-indigo-100 text-indigo-800"
  };
  return colors[category] || "bg-gray-100 text-gray-800";
};

const getUrgencyColor = (urgency) => {
  if (typeof urgency !== 'string') return "bg-primary/10 text-primary";

  const colors = {
    immediate: "bg-error/10 text-error",
    short_term: "bg-warning/10 text-warning",
    long_term: "bg-success/10 text-success"
  };
  return colors[urgency] || "bg-primary/10 text-primary";
};

const getImportanceColor = (score) => {
  if (typeof score !== 'string') return "bg-primary/10 text-primary";

  const colors = {
    high: "bg-error/10 text-error",
    medium: "bg-warning/10 text-warning",
    low: "bg-success/10 text-success"
  };
  return colors[score] || "bg-primary/10 text-primary";
};

const ActionCard = ({ action }) => {
  const [expanded, setExpanded] = useState(false);

  // Defensive check for action prop
  if (!isObject(action)) {
    return null;
  }

  const CategoryIcon = getCategoryIcon(action.category);
  const confidence = typeof action.confidence === 'number' ? action.confidence : 0;
  const evidence = isArray(action.evidence) ? action.evidence : [];

  return (
    <div className="rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors p-4 space-y-4">
      {/* Header Section */}
      <div className="flex items-start justify-between gap-4">
        <div className="flex items-start gap-3 flex-grow">
          <div className={`p-2 rounded-lg ${getCategoryColor(action.category)}`}>
            <CategoryIcon className="w-4 h-4" />
          </div>
          <div className="space-y-1 flex-grow">
            <p className="font-medium">{action.action || 'Unnamed Action'}</p>
            <p className="text-sm text-base-content/60">{action.context || 'No context provided'}</p>
          </div>
        </div>
        <button
          onClick={() => setExpanded(!expanded)}
          className="p-1 hover:bg-base-300 rounded-lg transition-colors"
        >
          {expanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </button>
      </div>

      {/* Tags Section */}
      <div className="flex flex-wrap gap-2">
        <span className={`px-2 py-1 rounded-md text-xs font-medium ${getUrgencyColor(action.urgency)}`}>
          {safeReplace(action.urgency || 'unknown')}
        </span>
        <span className={`px-2 py-1 rounded-md text-xs font-medium ${getCategoryColor(action.category)}`}>
          {safeReplace(action.category || 'unknown')}
        </span>
        <span className={`px-2 py-1 rounded-md text-xs font-medium ${getImportanceColor(action.frequency_importance_score)}`}>
          {action.frequency_importance_score || 'unknown'} importance
        </span>
        <span className="px-2 py-1 rounded-md text-xs font-medium bg-primary/10 text-primary">
          {(confidence * 100).toFixed(0)}% confidence
        </span>
      </div>

      {/* Expanded Content */}
      {expanded && evidence.length > 0 && (
        <div className="mt-4 pt-4 border-t border-base-300">
          <div className="space-y-2">
            <h4 className="text-sm font-medium">Evidence:</h4>
            {evidence.map((item, index) => (
              <div
                key={index}
                className="text-sm p-2 rounded bg-base-300/50"
              >
                {String(item)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ActionsResult = ({ actions }) => {
  // Defensive check for actions prop
  if (!isArray(actions) || actions.length === 0) {
    return null;
  }

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center gap-2">
          <PlayCircle className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Recommended Actions</h2>
        </div>

        {/* Actions List */}
        <div className="grid gap-4">
          {actions.map((action, index) => (
            <ActionCard key={index} action={action} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionsResult;
