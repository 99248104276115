import React, { useState } from 'react';
import {
  Tags, ListOrdered, Heart, FileText, AlertTriangle,
  User, Database, Lightbulb, Save, Calendar, Lock, Copy,
  MailQuestion, Smile, ShoppingCart, MapPin, Link, ChartBar,
  Users, Clock, CalendarClock, Hospital, Info
} from 'lucide-react';
import { useAuth } from '../../../contexts/AuthContext';

const ProjectFormAgents = ({ project, onUpdate }) => {
  const currentUser = useAuth();
  const features = [
    {
      id: 'has_tags',
      icon: Tags,
      label: 'Automatically generate relevant keywords and tags to categorize your contents',
      shortLabel: 'Tag Detection',
      enabled: project?.has_tags
    },
    {
      id: 'has_category',
      icon: ListOrdered,
      label: 'Create concise summaries of your contents for quick understanding',
      shortLabel: 'Categorization',
      enabled: project?.has_category
    },
    {
      id: 'has_sentiment',
      icon: Heart,
      label: 'Analyze the emotional tone and sentiment of your contents',
      shortLabel: 'Sentiment Analysis',
      enabled: project?.has_sentiment
    },
    {
      id: 'has_summary',
      icon: FileText,
      label: 'Automatically classify contents into predefined categories',
      shortLabel: 'Summarization',
      enabled: project?.has_summary
    },
    {
      id: 'has_anomaly_detector',
      icon: AlertTriangle,
      label: 'Extract and organize key metadata from your contents',
      shortLabel: 'Anomaly Detection',
      enabled: project?.has_anomaly_detector
    },
    {
      id: 'has_named_entity_recognition',
      icon: User,
      label: 'Identify and classify named entities like people, organizations, and locations',
      shortLabel: 'Entity Recognition',
      enabled: project?.has_named_entity_recognition
    },
    {
      id: 'has_metadata_extractor',
      icon: Database,
      label: 'Extract and organize key metadata from your contents',
      shortLabel: 'Metadata Extraction',
      enabled: project?.has_metadata_extractor
    },
    {
      id: 'has_actionable_insights',
      icon: Lightbulb,
      label: 'Extract actionable insights and recommendations from your contents',
      shortLabel: 'Insights Extraction',
      enabled: project?.has_actionable_insights
    },
    {
      id: 'has_date_extraction',
      icon: Calendar,
      label: 'Extract dates and times from your contents',
      shortLabel: 'Date Extraction',
      enabled: project?.has_date_extraction
    },
    {
      id: 'has_pii_detection',
      icon: Lock,
      label: 'Detect and mask personally identifiable information in your contents',
      shortLabel: 'PII Detection',
      enabled: project?.has_pii_detection
    },
    {
      id: 'has_duplicate_detection',
      icon: Copy,
      label: 'Detect duplicate contents in your project',
      shortLabel: 'Duplicate Detection',
      enabled: project?.has_duplicate_detection
    },
    {
      id: 'has_spam_extraction',
      icon: MailQuestion,
      label: 'Detect spam contents in your project',
      shortLabel: 'Spam Detection',
      enabled: project?.has_spam_extraction
    },
    {
      id: 'has_emotion',
      icon: Smile,
      label: 'Detect emotions in your contents',
      shortLabel: 'Emotion Detection',
      enabled: project?.has_emotion
    },
    {
      id: 'has_product_categorization',
      icon: ShoppingCart,
      label: 'Categorize products in your contents',
      shortLabel: 'Product Categorization',
      enabled: project?.has_product_categorization
    },
    {
      id: 'has_address_validation',
      icon: MapPin,
      label: 'Validate addresses in your contents',
      shortLabel: 'Address Validation',
      enabled: project?.has_address_validation
    },
    {
      id: 'has_citation_detector',
      icon: Link,
      label: 'Detect citations in your contents',
      shortLabel: 'Citation Detection',
      enabled: project?.has_citation_detector
    },
    {
      id: 'has_kpi_extractor',
      icon: ChartBar,
      label: 'Extract key performance indicators from your contents',
      shortLabel: 'KPI Extraction',
      enabled: project?.has_kpi_extractor
    },
    {
      id: 'has_competitor_mention_detector',
      icon: Users,
      label: 'Detect competitor mentions in your contents',
      shortLabel: 'Competitor Mention Detection',
      enabled: project?.has_competitor_mention_detector
    },
    {
      id: 'has_urgency_detector',
      icon: Clock,
      label: 'Detect urgency in your contents',
      shortLabel: 'Urgency Detection',
      enabled: project?.has_urgency_detector
    },
    {
      id: 'has_deadline_detector',
      icon: CalendarClock,
      label: 'Detect deadlines in your contents',
      shortLabel: 'Deadline Detection',
      enabled: project?.has_deadline_detector
    },
    {
      id: 'has_medical_term_extractor',
      icon: Hospital,
      label: 'Extract medical terms from your contents',
      shortLabel: 'Medical Term Extraction',
      enabled: project?.has_medical_term_extractor
    }
  ];

  const [selectedFeatures, setSelectedFeatures] = useState(
    new Set(
      features
        .filter(f => f.enabled && currentUser.limit_plugins.includes(f.id))
        .map(f => f.id)
    )
  );

  const toggleFeature = (featureId) => {
    if (!currentUser.limit_plugins.includes(featureId)) return;
    const newSelected = new Set(selectedFeatures);
    if (newSelected.has(featureId)) {
      newSelected.delete(featureId);
    } else {
      newSelected.add(featureId);
    }
    setSelectedFeatures(newSelected);
  };

  const handleSubmit = () => {
    const updates = {};
    features.forEach(feature => {
      updates[feature.id] = currentUser.limit_plugins.includes(feature.id)
        ? selectedFeatures.has(feature.id)
        : feature.enabled;
    });
    onUpdate(updates);
  };

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-8">
        <div className="grid gap-6">
          {features.map((feature) => {
            const isSelected = selectedFeatures.has(feature.id);
            const Icon = feature.icon;
            const isAllowed = currentUser.limit_plugins.includes(feature.id);

            return (
              <div
                key={feature.id}
                className={`
                  relative flex items-center gap-6 p-6 rounded-xl border-2 transition-all duration-200
                  ${isAllowed ? 'cursor-pointer hover:bg-base-200' : 'opacity-50 cursor-not-allowed'}
                  ${isSelected ? 'border-success/50 bg-success/5' : 'border-base-200'}
                `}
                onClick={() => isAllowed && toggleFeature(feature.id)}
              >
                <div className={`
                  p-3 rounded-lg
                  ${isSelected ? 'bg-success/20 text-success' : 'bg-base-200 text-base-content'}
                `}>
                  <Icon className="w-6 h-6" />
                </div>

                <div className="flex-grow space-y-1">
                  <h3 className={`font-medium text-lg
                    ${isSelected ? 'text-success' : 'text-base-content'}
                  `}>
                    {feature.shortLabel}
                  </h3>
                  <p className="text-base-content/70 text-sm leading-relaxed">
                    {feature.label}
                  </p>
                </div>

                <div className="flex-none">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-success checkbox-lg"
                    checked={isSelected}
                    disabled={!isAllowed}
                    onChange={() => { }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>

                {!isAllowed && (
                  <div className="absolute top-2 right-2">
                    <div className="tooltip" data-tip="Feature not available in your plan">
                      <Info className="w-4 h-4 text-base-content/50" />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="card-actions justify-end mt-8">
          <button
            className="btn btn-primary gap-3"
            onClick={handleSubmit}
          >
            <Save className="w-5 h-5" />
            Update Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectFormAgents;
