import React from 'react';
import { ThumbsUp, ThumbsDown, Minus, AlertTriangle, BarChart, List } from 'lucide-react';

const SentimentResult = ({ sentiment }) => {
  if (!sentiment?.value) return null;

  const getSentimentIcon = (value) => {
    const icons = {
      positive: <ThumbsUp className="w-6 h-6" />,
      negative: <ThumbsDown className="w-6 h-6" />,
      neutral: <Minus className="w-6 h-6" />
    };
    return icons[value] || icons.neutral;
  };

  const getSentimentColor = (value) => {
    const colors = {
      positive: 'bg-success/20 text-success border-success/30',
      negative: 'bg-error/20 text-error border-error/30',
      neutral: 'bg-base-200 text-base-content border-base-300'
    };
    return colors[value] || colors.neutral;
  };

  const getIntensityWidth = (intensity) => {
    const widths = {
      low: 'w-1/3',
      moderate: 'w-2/3',
      high: 'w-full'
    };
    return widths[intensity] || widths.low;
  };

  const hasValidConfidence = typeof sentiment.confidence === 'number' &&
    !isNaN(sentiment.confidence) &&
    sentiment.confidence >= 0 &&
    sentiment.confidence <= 1;

  const hasValidIndicators = Array.isArray(sentiment.primary_indicators) &&
    sentiment.primary_indicators.length > 0;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="flex items-center justify-between mb-6">
        <div className={`flex items-center gap-3 px-4 py-2 rounded-lg border ${getSentimentColor(sentiment.value)}`}>
          {getSentimentIcon(sentiment.value)}
          <div>
            <p className="text-sm font-medium opacity-70">Sentiment</p>
            <p className="text-lg font-semibold capitalize">{sentiment.value}</p>
          </div>
        </div>

        {hasValidConfidence && (
          <div className="text-right">
            <div className="text-sm font-medium opacity-70">Confidence</div>
            <div className="text-lg font-semibold">
              {Math.round(sentiment.confidence * 100)}%
            </div>
          </div>
        )}
      </div>

      {sentiment.intensity && (
        <div className="mb-6">
          <div className="flex items-center gap-2 mb-2">
            <BarChart className="w-4 h-4" />
            <span className="font-medium">Intensity</span>
            <span className="ml-auto font-medium capitalize">{sentiment.intensity}</span>
          </div>
          <div className="w-full h-2 rounded-full bg-base-200">
            <div
              className={`h-full rounded-full bg-primary transition-all ${getIntensityWidth(sentiment.intensity)}`}
            />
          </div>
        </div>
      )}

      {hasValidIndicators && (
        <div>
          <div className="flex items-center gap-2 mb-3 border-b border-base-200 pb-3">
            <List className="w-4 h-4" />
            <span className="font-medium">Primary Indicators</span>
          </div>
          <div className="space-y-2">
            {sentiment.primary_indicators.map((indicator, index) => (
              <div
                key={`indicator-${index}-${String(indicator).slice(0, 10)}`}
                className="flex items-start gap-2 p-3 rounded-lg bg-base-200"
              >
                <AlertTriangle className="w-4 h-4 mt-1 flex-shrink-0" />
                <p className="text-sm">{String(indicator)}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SentimentResult;
