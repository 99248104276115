import React, { useState } from 'react';
import { FolderOpen, Mail, Code, Radio, ChevronDown, AppWindow, FileType, Image, Mic } from 'lucide-react';
import PublicFolderSource from '../project/sources/PublicFolderSource';
import EmailSource from '../project/sources/EmailSource';
import JSSnippetSource from '../project/sources/JSSnippetSource';
import APISource from '../project/sources/APISource';
import { useAuth } from '../../contexts/AuthContext';

// Placeholder components for new sources
const AppSource = ({ project }) => (
  <div className="space-y-4">
    <div className="alert alert-info">
      <p>App source is always enabled and cannot be disabled.</p>
    </div>
    {/* Add your app source specific content here */}
  </div>
);

const DocumentSource = ({ project, disabled, onToggle }) => (
  <div className="space-y-4">
    <div className="form-control">
      <label className="label cursor-pointer justify-start gap-4">
        <input
          type="checkbox"
          className="toggle toggle-primary"
          checked={project?.has_document_access}
          onChange={onToggle}
          disabled={disabled}
        />
        <span className="label-text">Enable PDF/Text processing</span>
      </label>
    </div>
    {project?.has_document_access && (
      <div className="alert alert-info">
        {/* Add your document source specific content here */}
        <p>Configure PDF and text document processing settings</p>
      </div>
    )}
  </div>
);

const ImageSource = ({ project, disabled, onToggle }) => (
  <div className="space-y-4">
    <div className="form-control">
      <label className="label cursor-pointer justify-start gap-4">
        <input
          type="checkbox"
          className="toggle toggle-primary"
          checked={project?.has_image_access}
          onChange={onToggle}
          disabled={disabled}
        />
        <span className="label-text">Enable Image processing</span>
      </label>
    </div>
    {project?.has_image_access && (
      <div className="alert alert-info">
        {/* Add your image source specific content here */}
        <p>Configure image processing settings</p>
      </div>
    )}
  </div>
);

const AudioSource = ({ project, disabled, onToggle }) => (
  <div className="space-y-4">
    <div className="form-control">
      <label className="label cursor-pointer justify-start gap-4">
        <input
          type="checkbox"
          className="toggle toggle-primary"
          checked={project?.has_audio_access}
          onChange={onToggle}
          disabled={disabled}
        />
        <span className="label-text">Enable Audio processing</span>
      </label>
    </div>
    {project?.has_audio_access && (
      <div className="alert alert-info">
        {/* Add your audio source specific content here */}
        <p>Configure audio processing settings</p>
      </div>
    )}
  </div>
);

const SourcesInfo = ({
  project,
  onUpdate,
  submitApiV1ProjectUrl,
  contentWidgetServerId,
  contentWidgetServerUrl,
  contentWidgetServerJs,
  baseUrl
}) => {
  const [expandedSource, setExpandedSource] = useState(null);
  const currentUser = useAuth();

  const hasSourceAccess = (sourceId) => {
    if (sourceId === 'has_app_access') return true; // App source is always enabled
    return currentUser?.limit_sources?.includes(sourceId);
  };

  const sources = [
    {
      id: 'has_app_access',
      label: 'App',
      icon: <AppWindow className="h-5 w-5" />,
      content: <AppSource project={project} />,
      ariaLabel: 'App source settings',
      alwaysEnabled: true
    },
    {
      id: 'has_public_access',
      label: 'Public Folder',
      icon: <FolderOpen className="h-5 w-5" />,
      content: <PublicFolderSource
        project={project}
        disabled={!hasSourceAccess('has_public_access')}
        folderLink={`${baseUrl}/public/projects/${project?.id}`}
        onToggle={() => onUpdate({ has_public_access: !project?.has_public_access })}
      />,
      ariaLabel: 'Toggle public folder access'
    },
    {
      id: 'has_document_access',
      label: 'PDF/Text',
      icon: <FileType className="h-5 w-5" />,
      content: <DocumentSource
        project={project}
        disabled={!hasSourceAccess('has_document_access')}
        onToggle={() => onUpdate({ has_document_access: !project?.has_document_access })}
      />,
      ariaLabel: 'Toggle document access'
    },
    {
      id: 'has_image_access',
      label: 'Images',
      icon: <Image className="h-5 w-5" />,
      content: <ImageSource
        project={project}
        disabled={!hasSourceAccess('has_image_access')}
        onToggle={() => onUpdate({ has_image_access: !project?.has_image_access })}
      />,
      ariaLabel: 'Toggle image access'
    },
    {
      id: 'has_audio_access',
      label: 'Audio',
      icon: <Mic className="h-5 w-5" />,
      content: <AudioSource
        project={project}
        disabled={!hasSourceAccess('has_audio_access')}
        onToggle={() => onUpdate({ has_audio_access: !project?.has_audio_access })}
      />,
      ariaLabel: 'Toggle audio access'
    },
    {
      id: 'has_email_access',
      label: 'Email',
      icon: <Mail className="h-5 w-5" />,
      content: <EmailSource
        baseUrl={baseUrl}
        project={project}
        onToggle={() => onUpdate({ has_email_access: !project?.has_email_access })}
        disabled={!hasSourceAccess('has_email_access')}
        emailToken={project?.email_token}
      />,
      ariaLabel: 'Toggle email access'
    },
    {
      id: 'has_js_access',
      label: 'JS Snippet',
      icon: <Code className="h-5 w-5" />,
      content: <JSSnippetSource
        onToggle={() => onUpdate({ has_js_access: !project?.has_js_access })}
        disabled={!hasSourceAccess('has_js_access')}
        project={project}
        contentWidgetServerId={contentWidgetServerId}
        contentWidgetServerUrl={contentWidgetServerUrl}
        contentWidgetServerJs={contentWidgetServerJs}
        projectToken={project?.js_snippet}
      />,
      ariaLabel: 'Toggle JavaScript snippet access'
    },
    {
      id: 'has_api_access',
      label: 'API',
      icon: <Radio className="h-5 w-5" />,
      content: <APISource
        baseUrl={baseUrl}
        onToggle={() => onUpdate({ has_api_access: !project?.has_api_access })}
        disabled={!hasSourceAccess('has_api_access')}
        apiToken={project?.api_token}
        project={project}
        submitApiV1ProjectUrl={submitApiV1ProjectUrl}
      />,
      ariaLabel: 'Toggle API access'
    }
  ];

  const handleSourceToggle = (sourceId) => {
    if (hasSourceAccess(sourceId)) {
      setExpandedSource(prevId => prevId === sourceId ? null : sourceId);
    }
  };

  if (!project) return null;

  return (
    <div
      className="flex flex-col gap-2 w-full"
      role="region"
      aria-label="Project Sources"
    >
      {sources.map((source) => {
        const isAccessible = hasSourceAccess(source.id);

        return (
          <div key={source.id} className="card bg-base-100 shadow-sm">
            <button
              onClick={() => handleSourceToggle(source.id)}
              className={`w-full flex items-center justify-between p-4
                ${isAccessible ? 'hover:bg-base-200' : 'cursor-not-allowed opacity-60'}
                transition-colors duration-200
                ${source.alwaysEnabled ? 'bg-base-200' : ''}`}
              disabled={!isAccessible}
              aria-expanded={expandedSource === source.id}
              aria-label={source.ariaLabel}
            >
              <div className="flex items-center gap-3">
                <div className={`transition-colors ${isAccessible ? 'text-primary' : 'text-gray-400'}`}>
                  {source.icon}
                </div>
                <span className="font-medium">{source.label}</span>
              </div>
              <ChevronDown
                className={`h-5 w-5 transition-transform duration-200
                  ${expandedSource === source.id ? 'rotate-180' : ''}`}
                aria-hidden="true"
              />
            </button>

            {expandedSource === source.id && isAccessible && (
              <div
                className="p-4 border-t border-base-200"
                role="region"
                aria-label={`${source.label} configuration`}
              >
                {source.content}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SourcesInfo;
