import React from "react";
import {
  Building2, Users, MapPin, Calendar, Tag, Phone, Globe, Mail, Link2,
  ChevronDown, ChevronUp, Building, Briefcase, User, Clock, FileCode,
  BarChart2, House
} from "lucide-react";

const getCategoryIcon = (type, category) => {
  const icons = {
    organizations: { company: Briefcase, institution: Building, government: House },
    people: { name: User, title: Users, role: Briefcase },
    locations: { address: MapPin, city: Building2, country: Globe, region: Globe },
    dates: { exact: Calendar, range: Clock, recurring: Calendar },
    identifiers: { certificate: FileCode, standard: Tag, registration: FileCode, code: FileCode },
    contact_info: { email: Mail, phone: Phone, website: Link2 }
  };
  return (icons[type]?.[category] || Tag) ?? Tag;
};

const getConfidenceColor = (confidence) => {
  if (!confidence && confidence !== 0) return "text-error";
  if (confidence >= 0.9) return "text-success";
  if (confidence >= 0.7) return "text-warning";
  return "text-error";
};

const EntityCard = ({ entity, type }) => {
  if (!entity?.value) return null;

  const [expanded, setExpanded] = React.useState(false);
  const CategoryIcon = getCategoryIcon(type, entity.category);
  const confidence = typeof entity.confidence === 'number' ? entity.confidence : 0;
  const confidenceColor = getConfidenceColor(confidence);
  const hasExtras = (Array.isArray(entity.mentions) && entity.mentions.length > 0) ||
    (Array.isArray(entity?.relationships) && entity?.relationships.length > 0);

  return (
    <div className="rounded-lg border bg-base-100 shadow-sm">
      <div className="p-4">
        <div className="flex items-start justify-between gap-4">
          <div className="flex items-start gap-3 flex-grow">
            <div className="p-2 rounded-lg bg-primary/10">
              <CategoryIcon className="w-4 h-4 text-primary" />
            </div>
            <div className="space-y-1 flex-grow">
              <div className="flex items-center gap-2">
                <span className="font-medium">{entity.value}</span>
                {entity.category && (
                  <span className="px-2 py-0.5 text-xs rounded-full bg-base-200 capitalize">
                    {entity.category}
                  </span>
                )}
              </div>
              <div className="flex items-center gap-2">
                <BarChart2 className={`w-3 h-3 ${confidenceColor}`} />
                <span className={`text-xs ${confidenceColor}`}>
                  {(confidence * 100).toFixed(0)}% confidence
                </span>
              </div>
            </div>
          </div>
          {hasExtras && (
            <button
              onClick={() => setExpanded(!expanded)}
              className="p-1 hover:bg-base-200 rounded-lg transition-colors"
            >
              {expanded ? (
                <ChevronUp className="w-4 h-4" />
              ) : (
                <ChevronDown className="w-4 h-4" />
              )}
            </button>
          )}
        </div>

        {expanded && hasExtras && (
          <div className="mt-4 pt-4 border-t border-base-200">
            {Array.isArray(entity.mentions) && entity.mentions.length > 0 && (
              <div className="space-y-2">
                <span className="text-sm text-base-content/60">Mentions</span>
                <div className="flex flex-wrap gap-2">
                  {entity.mentions.map((mention, index) => (
                    <span
                      key={`mention-${index}`}
                      className="px-2 py-1 text-xs rounded-lg bg-base-200"
                    >
                      {mention}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {Array.isArray(entity.relationships) && entity.relationships.length > 0 && (
              <div className="mt-3 space-y-2">
                <span className="text-sm text-base-content/60">Relationships</span>
                <div className="flex flex-wrap gap-2">
                  {entity.relationships.map((rel, index) => (
                    <span
                      key={`rel-${index}`}
                      className="px-2 py-1 text-xs rounded-lg bg-primary/10 text-primary"
                    >
                      {rel}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const EntitySection = ({ title, icon: Icon, entities, type }) => {
  if (!Array.isArray(entities) || entities.length === 0) return null;

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="p-2 rounded-lg bg-primary/10">
            <Icon className="w-4 h-4 text-primary" />
          </div>
          <h3 className="font-medium">{title}</h3>
        </div>
        <span className="px-2 py-1 text-xs rounded-lg bg-primary/10 text-primary">
          {entities.length} found
        </span>
      </div>
      <div className="grid gap-3">
        {entities.map((entity, index) => (
          <EntityCard
            key={`entity-${type}-${index}`}
            entity={entity}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

const EntitiesResult = ({ entities }) => {
  if (!entities || typeof entities !== 'object') return null;

  const sections = [
    { key: 'organizations', title: 'Organizations', icon: Building2, type: 'organizations' },
    { key: 'people', title: 'People', icon: Users, type: 'people' },
    { key: 'locations', title: 'Locations', icon: MapPin, type: 'locations' },
    { key: 'dates', title: 'Dates', icon: Calendar, type: 'dates' },
    { key: 'identifiers', title: 'Identifiers', icon: Tag, type: 'identifiers' },
    { key: 'contact_info', title: 'Contact Information', icon: Phone, type: 'contact_info' }
  ];

  const hasValidSections = sections.some(section =>
    Array.isArray(entities[section.key]) && entities[section.key].length > 0
  );

  if (!hasValidSections) return null;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center gap-2">
          <Tag className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Extracted Entities</h2>
        </div>
        <div className="grid gap-6">
          {sections.map(section => (
            <EntitySection
              key={section.key}
              title={section.title}
              icon={section.icon}
              entities={entities[section.key]}
              type={section.type}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EntitiesResult;
