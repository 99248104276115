import React, { createContext, useContext } from 'react'

const AuthContext = createContext(null)

export const AuthProvider = ({ currentUser, children }) => {
  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
