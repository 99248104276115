import React, { useState } from 'react';
import { Save } from 'lucide-react';

const ProjectFormName = ({ project, onUpdate }) => {
  const [formData, setFormData] = useState({
    projectName: project?.name || '',
    description: project?.description || ''
  });

  const [errors, setErrors] = useState({
    projectName: '',
    description: ''
  });

  const [touched, setTouched] = useState({
    projectName: false,
    description: false
  });

  const validate = (name, value) => {
    if (!value.trim()) {
      return `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validate(name, value)
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
    setErrors(prev => ({
      ...prev,
      [name]: validate(name, value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      projectName: validate('projectName', formData.projectName),
      description: validate('description', formData.description)
    };

    setErrors(newErrors);
    setTouched({
      projectName: true,
      description: true
    });

    if (!newErrors.projectName && !newErrors.description) {
      onUpdate({
        name: formData.projectName,
        description: formData.description
      })
    }
  };

  return (
    <div className="card bg-base-100 border border-success/20 shadow-xl p-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text text-base font-medium">Project Name</span>
          </label>
          <input
            type="text"
            name="projectName"
            value={formData.projectName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`input input-bordered w-full ${touched.projectName && errors.projectName ? 'input-error' : ''}`}
            placeholder="Enter project name"
          />
          {touched.projectName && errors.projectName && (
            <label className="label">
              <span className="label-text-alt text-error">{errors.projectName}</span>
            </label>
          )}
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text text-base font-medium">Description</span>
          </label>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`input input-bordered w-full ${touched.description && errors.description ? 'input-error' : ''}`}
            placeholder="Enter description"
          />
          {touched.description && errors.description && (
            <label className="label">
              <span className="label-text-alt text-error">{errors.description}</span>
            </label>
          )}
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="btn btn-primary gap-2 text-white"
          >
            <Save className="h-5 w-5" />
            Update Project
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectFormName;
