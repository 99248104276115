import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowLeft } from 'lucide-react';

const CheckoutPage = ({ subscriptionsPath, stripePublishableKey }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        // 1. Get price_id from URL
        const urlParams = new URLSearchParams(window.location.search);
        const price_id = urlParams.get('price_id');

        // 2. Create Stripe session using your existing backend
        const response = await fetch(subscriptionsPath, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          body: JSON.stringify({ price_id }) // Changed from period to price_id
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.error || 'Failed to create session');
        }

        const session = await response.json();
        const stripe = await loadStripe(stripePublishableKey);

        if (session.url) {
          window.location.href = session.url;
        }

      } catch (err) {
        console.error('Checkout error:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    initializeCheckout();
  }, [subscriptionsPath, stripePublishableKey]);

  if (error) {
    return (
      <div className="max-w-3xl mx-auto p-6">
        <div className="mb-6">
          <button
            onClick={() => window.history.back()}
            className="btn btn-ghost btn-sm flex items-center gap-2"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Plans
          </button>
        </div>
        <div className="alert alert-error">
          <p>{error}</p>
          <button
            className="btn btn-outline btn-sm"
            onClick={() => window.history.back()}
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6">
      <div className="mb-6">
        <button
          onClick={() => window.history.back()}
          className="btn btn-ghost btn-sm flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Plans
        </button>
      </div>

      <div className="text-center py-12">
        <div className="loading loading-spinner loading-lg"></div>
        <p className="mt-4">Preparing checkout...</p>
      </div>
    </div>
  );
};

export default CheckoutPage;
