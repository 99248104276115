import React from 'react';
import { Heart, Waves, Brain, TableOfContents, RotateCcw, ArrowRight } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const EmotionDisplay = ({ emotion, isPrimary = false }) => {
  if (!emotion) return null;

  const getIntensityColor = (intensity) => {
    switch (intensity) {
      case 'high': return 'error';
      case 'moderate': return 'warning';
      case 'low': return 'info';
      default: return 'neutral';
    }
  };

  return (
    <div className="p-4 bg-base-200 rounded-lg">
      <div className="flex items-center justify-between mb-2">
        <span className="font-medium capitalize">{emotion?.type}</span>
        <span className={`badge badge-${getIntensityColor(emotion?.intensity)}`}>
          {emotion?.intensity}
        </span>
      </div>
      <div className="w-full h-2 bg-base-300 rounded-full mb-2">
        <div
          className={`h-full bg-${getIntensityColor(emotion?.intensity)} rounded-full`}
          style={{ width: `${emotion?.confidence * 100}%` }}
        />
      </div>
      {isPrimary && emotion?.triggers && (
        <div className="mt-2">
          <p className="text-sm text-base-content/70 mb-1">Triggers:</p>
          <div className="flex flex-wrap gap-2">
            {emotion?.triggers.map((trigger, index) => (
              <span key={index} className="badge badge-sm">{trigger}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const EmotionalShiftsDisplay = ({ shifts }) => {
  if (!shifts?.detected) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Waves className="w-4 h-4" />
          Emotional Shifts
        </h3>
        <div className="space-y-2">
          <div className="flex flex-wrap items-center gap-2">
            {shifts?.sequence?.map((emotion, index) => (
              <React.Fragment key={index}>
                <span className="badge badge-primary">{emotion}</span>
                {index < shifts?.sequence?.length - 1 && (
                  <ArrowRight className="w-4 h-4 text-base-content/40" />
                )}
              </React.Fragment>
            ))}
          </div>
          {shifts?.transition_points?.length > 0 && (
            <div className="text-sm text-base-content/70">
              <p className="font-medium mb-1">Transition Points:</p>
              <ul className="list-disc list-inside">
                {shifts?.transition_points?.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContextAnalysisDisplay = ({ context, analysis }) => {
  if (!context && !analysis) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <TableOfContents className="w-4 h-4" />
          Contextual Analysis
        </h3>
        <div className="grid md:grid-cols-2 gap-4">
          {context && (
            <div className="space-y-2">
              <p className="text-sm font-medium">Situation:
                <span className="ml-2 capitalize badge badge-neutral">
                  {context?.situation_type}
                </span>
              </p>
              {context?.emotional_triggers?.length > 0 && (
                <div>
                  <p className="text-sm font-medium mb-1">Emotional Triggers:</p>
                  <div className="flex flex-wrap gap-2">
                    {context?.emotional_triggers?.map((trigger, index) => (
                      <span key={index} className="badge badge-sm badge-primary">{trigger}</span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {analysis && (
            <div className="space-y-2">
              <p className="text-sm font-medium">
                Complexity:
                <span className="ml-2 capitalize badge badge-secondary">
                  {analysis?.emotional_complexity}
                </span>
              </p>
              {analysis?.key_expressions?.length > 0 && (
                <div>
                  <p className="text-sm font-medium mb-1">Key Expressions:</p>
                  <div className="flex flex-wrap gap-2">
                    {analysis?.key_expressions?.map((expression, index) => (
                      <span key={index} className="badge badge-sm badge-secondary">{expression}</span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmotionExtractionResults = ({ emotionExtraction, onRerun }) => {
  if (!emotionExtraction) return null;

  const { status, result, agent_name } = emotionExtraction;
  const { emotions, context, analysis } = result || {};


  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={agent_name} />
            </p>
            <ResultStatus status={status} />
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('emotionExtraction')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(emotions)}
              copyKey={'emotion-extraction'}
            />
          </div>
        </div>

        <div className="space-y-4">
          <div className="card bg-base-200 shadow-lg">
            <div className="card-body p-4">
              <h3 className="card-title text-sm flex items-center gap-2">
                <Heart className="w-4 h-4" />
                Primary Emotion
              </h3>
              <EmotionDisplay emotion={emotions?.primary_emotion} isPrimary={true} />
            </div>
          </div>

          {emotions?.secondary_emotions?.length > 0 && (
            <div className="card bg-base-200 shadow-lg">
              <div className="card-body p-4">
                <h3 className="card-title text-sm flex items-center gap-2">
                  <Brain className="w-4 h-4" />
                  Secondary Emotions
                </h3>
                <div className="grid gap-4 md:grid-cols-2">
                  {emotions?.secondary_emotions?.map((emotion, index) => (
                    <EmotionDisplay key={index} emotion={emotion} />
                  ))}
                </div>
              </div>
            </div>
          )}

          <EmotionalShiftsDisplay shifts={emotions?.emotional_shifts} />
          <ContextAnalysisDisplay context={context} analysis={analysis} />
        </div>
      </div>
    </div>
  );
};

export default EmotionExtractionResults;
