import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, DatabaseBackup, Trash2, FolderOpen, Loader } from 'lucide-react';
import Features from '../components/common/Features';
import ContentBody from '../components/content/ContentBody';
import ContentResult from '../components/content/ContentResult';
import DeleteContentModal from '../components/content/DeleteContentModal';
import utils from '../utils';
import { useAuth } from '../contexts/AuthContext';
import { useParams } from '../contexts/ParamsProvider';
import GlobalTextSelectionPopup from '../components/GlobalTextSelectionPopup';
import { useUpdates } from '../contexts/UpdatesContext';
import QuickResultPreviewModal from '../components/content/QuickResultPrevieModal';

const Content = () => {
  const [activeTab, setActiveTab] = useState('body');
  const [content, setContent] = useState(null);
  const [project, setProject] = useState(null);
  const [contentAgentResults, setContentAgentResults] = useState(null);
  const [isDeleteContentModalOpen, setIsDeleteContentModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isQuickResultPreviewModalOpen, setIsQuickResultPreviewModalOpen] = useState(false);

  const tabs = [
    { id: 'body', label: 'Content', icon: <FileText className="h-4 w-4" /> },
    { id: 'result', label: 'Result', icon: <DatabaseBackup className="h-4 w-4" /> },
  ];

  const getCsrfToken = () => {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (!token) throw new Error('CSRF token not found');
    return token;
  };

  const { toast } = utils;
  const params = useParams();
  const currentUser = useAuth();
  const updates = useUpdates();

  const getUrls = () => {
    if (!currentUser?.id || !params?.project_id || !params?.id) return null;

    return {
      contents: `/users/${currentUser.id}/projects/${params.project_id}/contents`,
      contentUrl: `/users/${currentUser.id}/projects/${params.project_id}/contents/${params.id}`,
      contentAgentResultsUrl: `/users/${currentUser.id}/projects/${params.project_id}/contents/${params.id}/content_agents_result`,
      reRunsUrl: `/users/${currentUser.id}/projects/${params.project_id}/contents/${content?.id}/reruns`
    };
  };

  const fetchData = async () => {
    const urls = getUrls();
    if (!urls) return;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(urls.contentUrl, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setContent(data?.content || null);
      setProject(data?.project || null);
    } catch (error) {
      console.error('Error fetching content:', error);
      setError('Failed to load content');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteContent = async () => {
    const urls = getUrls();
    if (!urls) return;

    setIsDeleteContentModalOpen(false);

    try {
      const response = await fetch(urls.contentUrl, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      toast({ message: 'Content deleted successfully!' });
      if (data?.redirect_url) {
        window.location.href = data.redirect_url;
      }
    } catch (error) {
      console.error('Error deleting content:', error);
      toast({ message: 'Failed to delete content', type: 'error' });
    }
  };

  const fetchContentAgentResults = async () => {
    const urls = getUrls();
    if (!urls) return;

    try {
      const response = await fetch(urls.contentAgentResultsUrl, {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setContentAgentResults(data || null);
    } catch (error) {
      console.error('Error fetching agent results:', error);
      setError('Failed to load agent results');
    }
  };

  const onRerun = async (agents) => {
    const urls = getUrls();
    if (!urls) return;
    try {
      const response = await fetch(urls.reRunsUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        },
        body: JSON.stringify({ rerun: { agent_name: agents } })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast({ message: 'Rerun initiated successfully!' });
    } catch (error) {
      console.error('Error initiating rerun:', error);
      toast({ message: 'Failed to initiate rerun' });
    }
  };

  const onAction = (action, text) => {
    console.log(action, text);
  };

  useEffect(() => {
    const urls = getUrls();
    if (urls) {
      fetchData();
      fetchContentAgentResults();
    }
  }, [currentUser?.id, params?.project_id, params?.id]);

  useEffect(() => {
    if (updates.type === 'content_analyzer.done') {
      setIsQuickResultPreviewModalOpen(true);
    }
  }, [updates]);

  useEffect(() => {
    if (updates.type === 'content_tagging.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Tagging done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_tagging.error') {
      toast({ message: `Tagging failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_anomaly.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Anomaly detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_anomaly.error') {
      toast({ message: `Anomaly detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_intent.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Intent detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_intent.error') {
      toast({ message: `Intent detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_date_extraction.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Date extraction done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_date_extraction.error') {
      toast({ message: `Date extraction failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_category.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Category detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_category.error') {
      toast({ message: `Category detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_metadata.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Metadata extraction done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_metadata.error') {
      toast({ message: `Metadata extraction failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_named_entity.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Named entity recognition done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_named_entity.error') {
      toast({ message: `Named entity recognition failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_pii_detection.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `PII detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_pii_detection.error') {
      toast({ message: `PII detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_sentiment.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Sentiment analysis done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_sentiment.error') {
      toast({ message: `Sentiment analysis failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_summary.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Summary done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_summary.error') {
      toast({ message: `Summary failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_spam_extraction.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Spam extraction done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_spam_extraction.error') {
      toast({ message: `Spam extraction failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_emotion.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Emotion detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_emotion.error') {
      toast({ message: `Emotion detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_product_categorization.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Product categorization done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_product_categorization.error') {
      toast({ message: `Product categorization failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_address_validation.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Address validation done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_address_validation.error') {
      toast({ message: `Address validation failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'duplicate_detection.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Duplicate detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'duplicate_detection.error') {
      toast({ message: `Duplicate detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_citation_detector.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Citation detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_citation_detector.error') {
      toast({ message: `Citation detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'kpi_extractor.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `KPI extraction done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'kpi_extractor.error') {
      toast({ message: `KPI extraction failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_urgency_detector.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Urgency detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'content_urgency_detector.error') {
      toast({ message: `Urgency detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'deadline_detector.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Deadline detection done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'deadline_detector.error') {
      toast({ message: `Deadline detection failed for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'medical_term_extractor.done') {
      fetchContentAgentResults();
      setActiveTab('result');
      toast({ message: `Medical term extraction done for ${updates?.data.content?.title}` });
    }

    if (updates.type === 'medical_term_extractor.error') {
      toast({ message: `Medical term extraction failed for ${updates?.data.content?.title}` });
    }

  }, [updates]);

  const EmptyState = ({ message = 'No Information Available' }) => (
    <div className="flex flex-col items-center justify-center py-16 px-4">
      <h3 className="text-2xl text-blue-500 font-medium mb-8">
        {message}
      </h3>
    </div>
  );

  if (loading) {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-base-100/50 backdrop-blur-sm z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4"
          >
            <motion.div
              animate={{
                rotate: 360
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              <Loader className="w-8 h-8 text-primary" />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  }

  if (error) {
    return <EmptyState message={error} />;
  }

  return (
    <div className="w-full">
      <GlobalTextSelectionPopup project={project} onAction={onAction} />
      <DeleteContentModal
        isOpen={isDeleteContentModalOpen}
        onClose={() => setIsDeleteContentModalOpen(false)}
        onDelete={handleDeleteContent}
        contentToDelete={content}
      />
      <QuickResultPreviewModal
        isOpen={isQuickResultPreviewModalOpen}
        onClose={() => setIsQuickResultPreviewModalOpen(false)}
        content={content}
      />

      <div className="flex items-center justify-between px-6 py-4">
        <div className="breadcrumbs text-md">
          <ul>
            <li className="text-primary">
              <FolderOpen className="w-4 h-4 mr-1" />
              {project && (
                <a href={`/users/${currentUser?.id}/projects/${project.id}`} className="link link-primary">
                  {project.name}
                </a>
              )}
            </li>
            <li className="text-primary">
              <FileText className="w-4 h-4 mr-1" />
              {content?.title}
            </li>
          </ul>
        </div>

        <div className="inline-flex gap-3 text-sm items-center justify-end ml-3 mb-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsDeleteContentModalOpen(true)}
            className="btn btn-sm btn-outline btn-error"
            disabled={!content}
          >
            <Trash2 className="h-4 w-4" />
            Delete
          </motion.button>
          <p className="text-sm text-base-content/70">
            Active Agents
          </p>
          <Features project={project} />
        </div>
      </div>

      <div>
        <div className="tabs tabs-lifted">
          {tabs.map((tab) => (
            <motion.button
              key={tab.id}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className={`tab tab-bordered gap-2 ${activeTab === tab.id ? 'tab-active' : ''}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.icon}
              {tab.label}
            </motion.button>
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
          className="mt-4"
        >
          {activeTab === 'body' && (
            <div className="p-4">
              {!content ? <EmptyState /> : <ContentBody content={content} />}
            </div>
          )}
          {activeTab === 'result' && (
            <div className="p-4">
              {contentAgentResults ? (
                <ContentResult
                  contentAgentResults={contentAgentResults}
                  onRerun={onRerun}
                  project={project}
                />
              ) : (
                <EmptyState />
              )}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Content;
