import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Plus, Loader } from 'lucide-react';
import ProjectCard from '../components/projects/ProjectCard';
import SourceSelector from '../components/projects/SourceSelector';
import NewProjectModal from '../components/projects/NewProjectModal';
import { useAuth } from '../contexts/AuthContext';
import AIAgentSelector from '../components/common/AIAgentSelector';

const Projects = ({ projectsURL }) => {
  const currentUser = useAuth();
  const [state, setState] = useState({
    searchTerm: '',
    projects: [],
    isModalOpen: false,
    loading: true,
    error: null
  });

  const getCsrfToken = () => {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (!token) throw new Error('CSRF token not found');
    return token;
  };

  const fetchProjects = async (selectedSources = [], selectedAgents = []) => {
    if (!projectsURL) return;

    try {
      const queryParams = new URLSearchParams();
      selectedSources.forEach(source => queryParams.append('content_sources[]', source));
      selectedAgents.forEach(agent => queryParams.append('ai_agents[]', agent));

      const response = await fetch(
        `${projectsURL}${queryParams.toString() ? "?" + queryParams.toString() : ""}`,
        { headers: { 'Accept': 'application/json' } }
      );

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setState(prev => ({
        ...prev,
        projects: data || [],
        loading: false,
        error: null
      }));
    } catch (error) {
      console.error('Error fetching projects:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to load projects',
        loading: false
      }));
    }
  };

  const handleSourceChange = async (selectedSources) => {
    await fetchProjects(selectedSources, []);
  };

  const handleAgentChange = async (selectedAgents) => {
    await fetchProjects([], selectedAgents);
  };

  const createProject = async (data) => {
    try {
      const response = await fetch(projectsURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': getCsrfToken()
        },
        body: JSON.stringify({ project: data })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create project');
      }

      setState(prev => ({ ...prev, isModalOpen: false }));
      await fetchProjects();
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  };

  const filteredProjects = state.projects.filter(project => {
    const searchLower = state.searchTerm.toLowerCase();
    return (
      project?.name?.toLowerCase().includes(searchLower) ||
      project?.description?.toLowerCase().includes(searchLower)
    );
  });

  useEffect(() => {
    if (projectsURL) {
      fetchProjects();
    }
  }, [projectsURL]);

  if (state.loading) {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-base-100/50 backdrop-blur-sm z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="bg-base-100 shadow-lg rounded-lg p-6 flex flex-col items-center gap-4"
          >
            <motion.div
              animate={{
                rotate: 360
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              <Loader className="w-8 h-8 text-primary" />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    );
  }

  if (state.error) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-4 text-error text-center"
      >
        {state.error}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full mx-auto p-3"
    >
      <NewProjectModal
        isOpen={state.isModalOpen}
        onClose={() => setState(prev => ({ ...prev, isModalOpen: false }))}
        onCreate={createProject}
      />
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="flex items-center justify-between gap-4 mb-4"
      >
        <div className="flex items-center gap-4">
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="relative w-96 max-w-4xl"
          >
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-base-content/50" />
            <input
              type="text"
              placeholder="Search"
              className="input input-md input-bordered w-full pl-9"
              value={state.searchTerm}
              onChange={(e) => setState(prev => ({ ...prev, searchTerm: e.target.value }))}
            />
          </motion.div>
          <AIAgentSelector onAgentChange={handleAgentChange} />
          <SourceSelector onSourceChange={handleSourceChange} />
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setState(prev => ({ ...prev, isModalOpen: true }))}
          className="btn btn-primary"
        >
          <Plus className="h-4 w-4" />
          <span className="hidden sm:inline">New Project</span>
        </motion.button>
      </motion.div>

      <AnimatePresence mode="wait">
        {filteredProjects.length === 0 ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="text-center text-gray-500 py-8"
          >
            No projects found
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4"
          >
            {filteredProjects.map((project, index) => (
              <motion.div
                key={project?.id || Math.random()}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <ProjectCard
                  project={project}
                  currentUser={currentUser}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Projects;
