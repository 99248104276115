import React, { useState, useEffect, useCallback } from 'react';
import {
  X, FileText, AlertCircle, Tags, Heart, ListOrdered,
  Database, Lightbulb, Calendar, Lock, Copy, Plus,
  MailQuestion, Smile, ShoppingCart, MapPin, Link,
  ChartBar, Users, Clock, CalendarClock, Hospital,
  AlertTriangle, User
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext'

const NewProjectModal = ({ isOpen, onClose, onCreate }) => {
  const currentUser = useAuth();
  const initialFeatures = {
    has_tags: false,
    has_category: false,
    has_sentiment: false,
    has_summary: false,
    has_anomaly_detector: false,
    has_named_entity_recognition: false,
    has_metadata_extractor: false,
    has_actionable_insights: false,
    has_date_extraction: false,
    has_pii_detection: false,
    has_duplicate_detection: false,
    has_spam_extraction: false,
    has_emotion: false,
    has_address_validation: false,
    has_product_categorization: false,
    has_citation_detector: false,
    has_kpi_extractor: false,
    has_competitor_mention_detector: false,
    has_urgency_detector: false,
    has_deadline_detector: false,
    has_medical_term_extractor: false
  };


  const initialErrors = {
    name: '',
    description: '',
    features: ''
  };

  const [formState, setFormState] = useState({
    name: '',
    description: '',
    features: initialFeatures,
    errors: initialErrors
  });

  const featuresList = [
    {
      key: 'has_tags',
      label: 'Tag Detection',
      description: 'Automatically generate relevant keywords and tags',
      icon: Tags,
      colorClass: 'text-purple-500',
      bgClass: 'bg-purple-500/20'
    },
    {
      key: 'has_category',
      label: 'Categorization',
      description: 'Classify contents into predefined categories',
      icon: ListOrdered,
      colorClass: 'text-blue-500',
      bgClass: 'bg-blue-500/20'
    },
    {
      key: 'has_sentiment',
      label: 'Sentiment Analysis',
      description: 'Analyze emotional tone and sentiment',
      icon: Heart,
      colorClass: 'text-red-500',
      bgClass: 'bg-red-500/20'
    },
    {
      key: 'has_summary',
      label: 'Summarization',
      description: 'Create concise summaries of your contents',
      icon: FileText,
      colorClass: 'text-orange-500',
      bgClass: 'bg-orange-500/20'
    },
    {
      key: 'has_anomaly_detector',
      label: 'Anomaly Detection',
      description: 'Detect unusual patterns and anomalies',
      icon: AlertTriangle,
      colorClass: 'text-purple-500',
      bgClass: 'bg-purple-500/20'
    },
    {
      key: 'has_named_entity_recognition',
      label: 'Entity Recognition',
      description: 'Identify and classify named entities',
      icon: User,
      colorClass: 'text-emerald-500',
      bgClass: 'bg-emerald-500/20'
    },
    {
      key: 'has_metadata_extractor',
      label: 'Metadata Extraction',
      description: 'Extract and organize key metadata',
      icon: Database,
      colorClass: 'text-slate-500',
      bgClass: 'bg-slate-500/20'
    },
    {
      key: 'has_actionable_insights',
      label: 'Insights',
      description: 'Extract actionable insights and recommendations',
      icon: Lightbulb,
      colorClass: 'text-yellow-500',
      bgClass: 'bg-yellow-500/20'
    },
    {
      key: 'has_date_extraction',
      label: 'Date Extraction',
      description: 'Extract dates and times from contents',
      icon: Calendar,
      colorClass: 'text-blue-500',
      bgClass: 'bg-blue-500/20'
    },
    {
      key: 'has_pii_detection',
      label: 'PII Detection',
      description: 'Detect and mask personally identifiable information',
      icon: Lock,
      colorClass: 'text-emerald-500',
      bgClass: 'bg-emerald-500/20'
    },
    {
      key: 'has_duplicate_detection',
      label: 'Duplicate Detection',
      description: 'Identify duplicate content',
      icon: Copy,
      colorClass: 'text-cyan-500',
      bgClass: 'bg-cyan-500/20'
    },
    {
      key: 'has_spam_extraction',
      label: 'Spam Detection',
      description: 'Detect and filter spam content',
      icon: MailQuestion,
      colorClass: 'text-green-500',
      bgClass: 'bg-green-500/20'
    },
    {
      key: 'has_emotion',
      label: 'Emotion Detection',
      description: 'Analyze and detect emotions in content',
      icon: Smile,
      colorClass: 'text-red-500',
      bgClass: 'bg-red-500/20'
    },
    {
      key: 'has_product_categorization',
      label: 'Product Categorization',
      description: 'Categorize products mentioned in content',
      icon: ShoppingCart,
      colorClass: 'text-pink-500',
      bgClass: 'bg-pink-500/20'
    },
    {
      key: 'has_address_validation',
      label: 'Address Validation',
      description: 'Validate and format addresses',
      icon: MapPin,
      colorClass: 'text-blue-500',
      bgClass: 'bg-blue-500/20'
    },
    {
      key: 'has_citation_detector',
      label: 'Citation Detection',
      description: 'Detect and extract citations',
      icon: Link,
      colorClass: 'text-amber-500',
      bgClass: 'bg-amber-500/20'
    },
    {
      key: 'has_kpi_extractor',
      label: 'KPI Extraction',
      description: 'Extract key performance indicators',
      icon: ChartBar,
      colorClass: 'text-green-500',
      bgClass: 'bg-green-500/20'
    },
    {
      key: 'has_competitor_mention_detector',
      label: 'Competitor Detection',
      description: 'Detect mentions of competitors',
      icon: Users,
      colorClass: 'text-blue-500',
      bgClass: 'bg-blue-500/20'
    },
    {
      key: 'has_urgency_detector',
      label: 'Urgency Detection',
      description: 'Detect urgent matters in content',
      icon: Clock,
      colorClass: 'text-red-500',
      bgClass: 'bg-red-500/20'
    },
    {
      key: 'has_deadline_detector',
      label: 'Deadline Detection',
      description: 'Extract and track deadlines',
      icon: CalendarClock,
      colorClass: 'text-purple-500',
      bgClass: 'bg-purple-500/20'
    },
    {
      key: 'has_medical_term_extractor',
      label: 'Medical Term Extraction',
      description: 'Extract medical terminology',
      icon: Hospital,
      colorClass: 'text-pink-500',
      bgClass: 'bg-pink-500/20'
    }
  ];

  useEffect(() => {
    const modal = document.getElementById('new_project_modal');
    if (isOpen) modal?.showModal();
    else modal?.close();
  }, [isOpen]);

  const resetForm = useCallback(() => {
    setFormState({
      name: '',
      description: '',
      features: initialFeatures,
      errors: initialErrors
    });
  }, []);

  const validateForm = useCallback(() => {
    const errors = { ...initialErrors };
    let isValid = true;

    if (!formState.name.trim()) {
      errors.name = 'Project name is required';
      isValid = false;
    }

    if (!formState.description.trim()) {
      errors.description = 'Project description is required';
      isValid = false;
    }

    if (!Object.values(formState.features).some(Boolean)) {
      errors.features = 'At least one feature must be selected';
      isValid = false;
    }

    setFormState(prev => ({ ...prev, errors }));
    return isValid;
  }, [formState.name, formState.description, formState.features]);

  const handleCreate = useCallback((e) => {
    e.preventDefault();
    if (!validateForm()) return;

    onCreate({
      name: formState.name.trim(),
      description: formState.description.trim(),
      ...formState.features
    });

    resetForm();
  }, [formState, onCreate, validateForm, resetForm]);

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const handleInputChange = useCallback((field, value) => {
    setFormState(prev => ({
      ...prev,
      [field]: value,
      errors: {
        ...prev.errors,
        [field]: ''
      }
    }));
  }, []);

  const handleFeatureToggle = useCallback((key) => {
    if (!currentUser?.limit_plugins?.includes(key)) return;

    setFormState(prev => ({
      ...prev,
      features: {
        ...prev.features,
        [key]: !prev.features[key]
      },
      errors: {
        ...prev.errors,
        features: ''
      }
    }));
  }, [currentUser]);

  return (
    <dialog id="new_project_modal" className="modal modal-full p-1">
      <div className="modal-box min-h-screen w-full max-w-none p-0 border-base-200 border-2">
        <div className="sticky top-0 z-30 flex h-16 w-full items-center justify-between bg-base-100 px-4">
          <button
            onClick={handleClose}
            className="btn btn-ghost btn-circle"
            aria-label="Close modal"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleCreate} className="container mx-auto max-w-7xl p-6">
          <div className="grid grid-cols-12 gap-6">
            {/* Left Column - Basic Info */}
            <div className="col-span-12 lg:col-span-4">
              <div className="sticky top-20">
                <div className="card bg-base-100 shadow border">
                  <div className="card-body">
                    <div className="space-y-4">
                      <div className="form-control">
                        <input
                          type="text"
                          placeholder="Enter project name"
                          className={`input input-bordered w-full ${formState.errors.name ? 'input-error' : ''}`}
                          value={formState.name}
                          onChange={(e) => handleInputChange('name', e.target.value)}
                        />
                        {formState.errors.name && (
                          <label className="label text-error text-sm">
                            <span className="flex items-center gap-1">
                              <AlertCircle className="h-4 w-4" />
                              {formState.errors.name}
                            </span>
                          </label>
                        )}
                      </div>

                      <div className="form-control">

                        <textarea
                          placeholder="Enter project description"
                          className={`textarea textarea-bordered h-32 ${formState.errors.description ? 'textarea-error' : ''}`}
                          value={formState.description}
                          onChange={(e) => handleInputChange('description', e.target.value)}
                        />
                        {formState.errors.description && (
                          <label className="label text-error text-sm">
                            <span className="flex items-center gap-1">
                              <AlertCircle className="h-4 w-4" />
                              {formState.errors.description}
                            </span>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Features */}
            <div className="col-span-12 lg:col-span-8">
              <div className="card bg-base-100 shadow border">
                <div className="card-body">
                  <h3 className="card-title mb-4">Select AI Agents</h3>
                  <div className="grid gap-4">
                    {featuresList.map(({ key, label, description, icon: Icon, colorClass, bgClass }) => {
                      const isEnabled = currentUser?.limit_plugins?.includes(key);
                      const isSelected = formState.features[key];

                      return (
                        <div
                          key={key}
                          onClick={() => isEnabled && handleFeatureToggle(key)}
                          className={`
                            relative flex items-center gap-6 p-6 rounded-xl border-2 transition-all duration-200
                            ${isEnabled ? 'cursor-pointer hover:bg-base-200' : 'opacity-50 cursor-not-allowed'}
                            ${isSelected ? 'border-success/50 bg-success/5' : 'border-base-200'}
                          `}
                        >
                          <div className={`p-3 rounded-lg ${isSelected ? bgClass : 'bg-base-200'}`}>
                            <Icon className={`w-6 h-6 ${isSelected ? colorClass : ''}`} />
                          </div>

                          <div className="flex-grow space-y-1">
                            <h3 className="font-medium text-lg">{label}</h3>
                            <p className="text-base-content/70 text-sm">
                              {description}
                            </p>
                          </div>

                          <div className="flex-none">
                            <input
                              type="checkbox"
                              className="checkbox checkbox-success checkbox-lg"
                              checked={isSelected}
                              onChange={() => { }}
                              disabled={!isEnabled}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>

                          {!isEnabled && (
                            <div className="absolute top-2 right-2">
                              <div className="tooltip" data-tip="Not available in your plan">
                                <AlertCircle className="w-4 h-4 text-base-content/50" />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {formState.errors.features && (
                    <div className="mt-4 text-error text-sm flex items-center gap-2">
                      <AlertCircle className="h-4 w-4" />
                      <span>{formState.errors.features}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Footer Actions */}
          <div className="sticky bottom-0 left-0 right-0 mt-6 flex items-center justify-end gap-3 bg-base-100 p-4 shadow-lg">
            <button
              type="button"
              className="btn gap-2"
              onClick={handleClose}
            >
              <X className="h-4 w-4" />
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary gap-2"
            >
              <Plus className="h-4 w-4" />
              Create Project
            </button>
          </div>
        </form>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  );
};

export default NewProjectModal;
