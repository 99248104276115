import React from 'react';
import CopyButton from '../../common/CopyButton';
import { Code } from 'lucide-react';

const JSSnippetSource = ({ disabled, onToggle, contentWidgetServerId, contentWidgetServerUrl, contentWidgetServerJs, projectToken, project }) => {
  const jsExample = (
    <>
      <pre data-prefix="1"><code>window.CONTENT_WIDGET_SERVER_ID = &apos;{contentWidgetServerId}&apos;</code></pre>
      <pre data-prefix="2"><code>window.CONTENT_WIDGET_SERVER_URL = &quot;{contentWidgetServerUrl}&quot;</code></pre>
      <pre data-prefix="3"><code>window.CONTENT_WIDGET_SERVER_JS = &quot;{contentWidgetServerJs}&quot;</code></pre>
      <pre data-prefix="4"><code>window.PROJECT_TOKEN = &apos;{projectToken}&apos;;</code></pre>
      <pre data-prefix="5"><code>{'(function(d, s) {'}</code></pre>
      <pre data-prefix="6"><code>    {'s = d.createElement("script");'}</code></pre>
      <pre data-prefix="7"><code>    {'s.src = window.CONTENT_WIDGET_SERVER_JS + "/content-widget-loader.js";'}</code></pre>
      <pre data-prefix="8"><code>    {'s.async = true;'}</code></pre>
      <pre data-prefix="9"><code>    {'s.onload = function() {'}</code></pre>
      <pre data-prefix="10"><code>        {'if (window.ContentWidget && window.ContentWidget.init) {'}</code></pre>
      <pre data-prefix="11"><code>            {'window.ContentWidget.init('}</code></pre>
      <pre data-prefix="12"><code>                {'window.CONTENT_WIDGET_SERVER_ID,'}</code></pre>
      <pre data-prefix="13"><code>                {'window.PROJECT_TOKEN,'}</code></pre>
      <pre data-prefix="14"><code>                {'{'}</code></pre>
      <pre data-prefix="15"><code>                    {'serverUrl: window.CONTENT_WIDGET_SERVER_URL,'}</code></pre>
      <pre data-prefix="16"><code>                {'}'}</code></pre>
      <pre data-prefix="17"><code>            {');'}</code></pre>
      <pre data-prefix="18"><code>        {'} else {'}</code></pre>
      <pre data-prefix="19"><code>            {'console.error("Widget initialization function not found");'}</code></pre>
      <pre data-prefix="20"><code>        {'}'}</code></pre>
      <pre data-prefix="21"><code>    {'};'}</code></pre>
      <pre data-prefix="22"><code>    {'d.head.appendChild(s);'}</code></pre>
      <pre data-prefix="23"><code>{'})(document);'}</code></pre>
    </>
  );

  const jsText = `window.CONTENT_WIDGET_SERVER_ID = '${contentWidgetServerId}'
window.CONTENT_WIDGET_SERVER_URL = "${contentWidgetServerUrl}"
window.CONTENT_WIDGET_SERVER_JS = "${contentWidgetServerJs}"
window.PROJECT_TOKEN = '${projectToken}';
(function(d, s) {
    s = d.createElement("script");
    s.src = window.CONTENT_WIDGET_SERVER_JS + "/content-widget-loader.js";
    s.async = true;
    s.onload = function() {
        if (window.ContentWidget && window.ContentWidget.init) {
            window.ContentWidget.init(
                window.CONTENT_WIDGET_SERVER_ID,
                window.PROJECT_TOKEN,
                {
                    serverUrl: window.CONTENT_WIDGET_SERVER_URL,
                }
            );
        } else {
            console.error('Widget initialization function not found');
        }
    };
    d.head.appendChild(s);
})(document);`;

  return (
    <div className={`space-y-4 ${disabled ? 'opacity-[.85]' : ''}`}>
      <div className="flex items-center justify-between bg-base-200 p-4 rounded-lg">
        <div className="flex items-center gap-3">
          <Code className={`h-5 w-5 ${disabled ? 'text-base-content/50' : 'text-primary'}`} />
          <div>
            <p className="font-medium">JS Snippet</p>
            <p className="text-sm text-base-content/70">Add this script to your website to enable the widget</p>
          </div>
        </div>
      </div>

      <div className="mockup-code text-sm overflow-y-scroll h-96 w-full">
        {jsExample}
      </div>

      <div className="flex justify-end gap-2">
        {project?.has_js_access && <CopyButton text={jsText} copyKey="jsSnippet" />}
        <button
          onClick={onToggle}
          className="btn btn-sm btn-primary"
        >
          {project?.has_js_access ? 'Disable JS Access' : 'Enable JS Access'}
        </button>
      </div>
    </div>
  );
};

export default JSSnippetSource;
