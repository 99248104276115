import {
  BookDown, DatabaseBackup, FileText, HeartPulse,
  Lightbulb, Tags, User, AlertTriangle, Calendar, Lock, Copy, MailQuestion, Smile, ShoppingCart, MapPin, Link, ChartBar, Users, Clock, CalendarClock, Hospital
} from 'lucide-react';
import IconWithTooltip from '../projects/IconWithTooltip';
import React, { memo } from 'react';

const Features = memo(({ project }) => {
  const featureConfigs = [
    {
      condition: 'has_tags',
      Icon: Tags,
      tooltip: 'Tags',
      color: 'text-purple-500',
      ariaLabel: 'Tag Detection Feature'
    },
    {
      condition: 'has_anomaly_detector',
      Icon: AlertTriangle,
      tooltip: 'Anomaly Detector',
      color: 'text-purple-500',
      ariaLabel: 'Anomaly Detection Feature'
    },
    {
      condition: 'has_category',
      Icon: BookDown,
      tooltip: 'Category',
      color: 'text-blue-500',
      ariaLabel: 'Category Detection Feature'
    },
    {
      condition: 'has_sentiment',
      Icon: HeartPulse,
      tooltip: 'Sentiment',
      color: 'text-red-500',
      ariaLabel: 'Sentiment Analysis Feature'
    },
    {
      condition: 'has_summary',
      Icon: FileText,
      tooltip: 'Summary',
      color: 'text-orange-500',
      ariaLabel: 'Summary Generation Feature'
    },
    {
      condition: 'has_actionable_insights',
      Icon: Lightbulb,
      tooltip: 'Insights',
      color: 'text-yellow-500',
      ariaLabel: 'Actionable Insights Feature'
    },
    {
      condition: 'has_metadata_extractor',
      Icon: DatabaseBackup,
      tooltip: 'Metadata Extractor',
      color: 'text-slate-500',
      ariaLabel: 'Metadata Extraction Feature'
    },
    {
      condition: 'has_named_entity_recognition',
      Icon: User,
      tooltip: 'Named Entity Recognition',
      color: 'text-emerald-500',
      ariaLabel: 'Named Entity Recognition Feature'
    },
    {
      condition: 'has_date_extraction',
      Icon: Calendar,
      tooltip: 'Date Extraction',
      color: 'text-blue-500',
      ariaLabel: 'Date Extraction Feature'
    },
    {
      condition: 'has_pii_detection',
      Icon: Lock,
      tooltip: 'PII Detection',
      color: 'text-emerald-500',
      ariaLabel: 'PII Detection Feature'
    },
    {
      condition: 'has_duplicate_detection',
      Icon: Copy,
      tooltip: 'Duplicate Detection',
      color: 'text-cyan-500',
      ariaLabel: 'Duplicate Detection Feature'
    },
    {
      condition: 'has_spam_extraction',
      Icon: MailQuestion,
      tooltip: 'Spam Detection',
      color: 'text-green-500',
      ariaLabel: 'Spam Detection Feature'
    },
    {
      condition: 'has_emotion',
      Icon: Smile,
      tooltip: 'Emotion Detection',
      color: 'text-red-500',
      ariaLabel: 'Emotion Detection Feature'
    },
    {
      condition: 'has_product_categorization',
      Icon: ShoppingCart,
      tooltip: 'Product Categorization',
      color: 'text-pink-500',
      ariaLabel: 'Product Categorization Feature'
    },
    {
      condition: 'has_address_validation',
      Icon: MapPin,
      tooltip: 'Address Validation',
      color: 'text-blue-500',
      ariaLabel: 'Address Validation Feature'
    },
    {
      condition: 'has_citation_detector',
      Icon: Link,
      tooltip: 'Citation Detection',
      color: 'text-amber-500',
      ariaLabel: 'Citation Detection Feature'
    },
    {
      condition: 'has_kpi_extractor',
      Icon: ChartBar,
      tooltip: 'KPI Extraction',
      color: 'text-green-500',
      ariaLabel: 'KPI Extraction Feature'
    },
    {
      condition: 'has_competitor_mention_detector',
      Icon: Users,
      tooltip: 'Competitor Mention Detection',
      color: 'text-blue-500',
      ariaLabel: 'Competitor Mention Detection Feature'
    },
    {
      condition: 'has_urgency_detector',
      Icon: Clock,
      tooltip: 'Urgency Detection',
      color: 'text-red-500',
      ariaLabel: 'Urgency Detection Feature'
    },
    {
      condition: 'has_deadline_detector',
      Icon: CalendarClock,
      tooltip: 'Deadline Detection',
      color: 'text-purple-500',
      ariaLabel: 'Deadline Detection Feature'
    },
    {
      condition: 'has_medical_term_extractor',
      Icon: Hospital,
      tooltip: 'Medical Term Extraction',
      color: 'text-pink-500',
      ariaLabel: 'Medical Term Extraction Feature'
    }
  ];

  if (!project) return null;

  return (
    <div
      className="flex gap-2"
      role="group"
      aria-label="Project Features"
    >
      {featureConfigs.map(({ condition, Icon, tooltip, color, ariaLabel }) =>
        project[condition] && (
          <IconWithTooltip
            key={condition}
            icon={Icon}
            tooltip={tooltip}
            color={color}
            ariaLabel={ariaLabel}
          />
        )
      )}
    </div>
  );
});

export default Features;
