import React from "react";
import ContextResult from "./ContextResult";
import AnalysisResult from "./AnalysisResult";
import SentimentResult from "./SentimentResult";
import CopyButton from "../../../common/CopyButton";
import { RotateCcw } from "lucide-react";
import ResultStatus from "../../common/ResultStatus";
import AIAgentCard from "../../common/AIAgentCard";
const SentimentsResult = ({ sentiments, onRerun }) => {
  // Safely extract data handling both structures
  const result = sentiments?.result || {};
  const response = result?.response || {};
  const status = response?.status || result?.status || {};
  const agent_name = sentiments?.agent_name || '';

  // Safely extract sentiment data
  const context = response?.context || result?.context;
  const analysis = response?.analysis || result?.analysis;
  const sentiment = response?.sentiment || result?.sentiment;

  const isError = status === "error" || result?.error;

  if (isError) return null;

  return (
    <div className="space-y-4 p-6 rounded-lg bg-base-100 shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div>
          <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
            <AIAgentCard agent_name={agent_name} />
            <ResultStatus status={status} />
          </p>
        </div>
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => onRerun('sentiments')}
            className="btn btn-sm btn-outline btn-primary">
            Rerun
            <RotateCcw />
          </button>
          <CopyButton text={JSON.stringify(sentiments)} copyKey={`sentiments-${agent_name}`} />
        </div>
      </div>

      {isError ? (
        <div className="text-red-600 text-sm">
          {result?.error || "An error occurred during sentiment analysis"}
        </div>
      ) : (
        <div className="flex gap-6 justify-start w-full flex-col">
          {context && <ContextResult context={context} />}
          {analysis && <AnalysisResult analysis={analysis} />}
          {sentiment && <SentimentResult sentiment={sentiment} />}
        </div>
      )}
    </div>
  );
};

export default SentimentsResult;
