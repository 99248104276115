import React from 'react';
import { Copy, Sparkles, FileText, RotateCcw, PieChart } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

const MatchesDisplay = ({ matches = [], title, icon: Icon }) => {
  if (!Array.isArray(matches) || matches.length === 0) return null;

  const getBadgeColor = (score) => {
    const numScore = parseFloat(score);
    if (isNaN(numScore)) return 'neutral';
    if (numScore >= 0.8) return 'success';
    if (numScore >= 0.5) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Icon className="w-4 h-4" />
          {title}
        </h3>
        <div className="space-y-4">
          {matches.map((match, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <span className="badge badge-neutral capitalize">
                  {match?.category || 'Unknown'}
                </span>
                <span className={`badge badge-${getBadgeColor(match?.similarity_score)}`}>
                  {match?.similarity_score ?
                    `${(match.similarity_score * 100).toFixed(1)}% Similar` :
                    'No Score'}
                </span>
              </div>
              <p className="text-sm mb-2 font-medium">{match?.value || 'No value provided'}</p>
              {Array.isArray(match?.matches) && match.matches.length > 0 && (
                <div>
                  <p className="text-xs text-base-content/70 mb-1">Matches Found:</p>
                  <div className="flex flex-wrap gap-2">
                    {match.matches.map((item, idx) => (
                      <span key={idx} className="badge badge-sm badge-ghost">
                        {item || 'Unnamed match'}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex items-center gap-4 mt-2">
                <div className="text-xs text-base-content/70">
                  Confidence: {match?.confidence ?
                    `${(match.confidence * 100).toFixed(1)}%` :
                    'Unknown'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.match_counts || !metadata?.confidence_summary || !metadata?.similarity_summary) {
    return null;
  }

  const getSimilarityBadgeColor = (type) => {
    switch (type) {
      case 'high_similarity': return 'success';
      case 'medium_similarity': return 'warning';
      case 'low_similarity': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Analysis Summary
        </h3>
        <div className="grid md:grid-cols-3 gap-4">
          <div className="space-y-2">
            <h4 className="text-sm font-medium">Match Counts</h4>
            <div className="flex flex-col gap-1 text-sm">
              <div className="flex justify-between">
                <span>Total:</span>
                <span className="font-medium">
                  {metadata?.match_counts?.total || 0}
                </span>
              </div>
              <div className="flex justify-between text-base-content/70">
                <span>Exact:</span>
                <span>{metadata?.match_counts?.by_category?.exact_matches || 0}</span>
              </div>
              <div className="flex justify-between text-base-content/70">
                <span>Partial:</span>
                <span>{metadata?.match_counts?.by_category?.partial_matches || 0}</span>
              </div>
              <div className="flex justify-between text-base-content/70">
                <span>Semantic:</span>
                <span>{metadata?.match_counts?.by_category?.semantic_matches || 0}</span>
              </div>
            </div>
          </div>

          <div className="space-y-2">
            <h4 className="text-sm font-medium">Confidence Averages</h4>
            <div className="space-y-2">
              <div>
                <p className="text-xs text-base-content/70">Overall</p>
                <div className="w-full h-2 bg-base-300 rounded-full">
                  <div
                    className="h-full bg-primary rounded-full"
                    style={{
                      width: `${(metadata?.confidence_summary?.average || 0) * 100}%`
                    }}
                  />
                </div>
              </div>
              {metadata?.confidence_summary?.by_category &&
                Object.entries(metadata.confidence_summary.by_category)
                  .map(([key, value]) => (
                    <div key={key}>
                      <p className="text-xs text-base-content/70 capitalize">
                        {key.replace('_', ' ')}
                      </p>
                      <div className="w-full h-2 bg-base-300 rounded-full">
                        <div
                          className="h-full bg-secondary rounded-full"
                          style={{ width: `${(value || 0) * 100}%` }}
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>

          <div className="space-y-2">
            <h4 className="text-sm font-medium">Similarity Distribution</h4>
            <div className="flex flex-col gap-2">
              {metadata?.similarity_summary &&
                Object.entries(metadata.similarity_summary)
                  .map(([key, value]) => (
                    <div key={key} className="flex items-center justify-between">
                      <span className="text-sm capitalize">
                        {key.replace('_', ' ')}:
                      </span>
                      <span className={`badge badge-${getSimilarityBadgeColor(key)}`}>
                        {value || 0}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DuplicateDetectionResults = ({ duplicateDetection, onRerun }) => {
  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={duplicateDetection?.agent_name || 'Unknown Agent'} />
            </p>
            <div className="flex items-center gap-2">
              <ResultStatus status={duplicateDetection?.status || 'unknown'} />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('duplicateDetection')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(duplicateDetection?.result || {})}
              copyKey="duplicate-detection"
            />
          </div>
        </div>

        {duplicateDetection?.result && (
          <div className="space-y-4">
            <MetadataDisplay metadata={duplicateDetection.result.metadata} />
            <MatchesDisplay
              matches={duplicateDetection.result.duplicate_analysis?.exact_matches}
              title="Exact Matches"
              icon={Copy}
            />
            <MatchesDisplay
              matches={duplicateDetection.result.duplicate_analysis?.partial_matches}
              title="Partial Matches"
              icon={FileText}
            />
            <MatchesDisplay
              matches={duplicateDetection.result.duplicate_analysis?.semantic_matches}
              title="Semantic Matches"
              icon={Sparkles}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DuplicateDetectionResults;
