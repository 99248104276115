import React, { useState, useEffect } from 'react';
import { AlertCircle, Save, X } from 'lucide-react';

const AddContentModal = ({ isOpen, onClose, onSave }) => {
  const [content, setContent] = useState({
    title: '',
    body: ''
  });

  const [errors, setErrors] = useState({
    title: '',
    body: ''
  });

  const isButtonDisabled = content.title.trim().length <= 3 || content.body.trim().length <= 50;

  useEffect(() => {
    if (isOpen) {
      document.getElementById('add_content_modal').showModal();
    } else {
      document.getElementById('add_content_modal').close();
    }
  }, [isOpen]);

  const validateForm = () => {
    const newErrors = {
      title: '',
      body: ''
    };
    let isValid = true;

    if (!content.title.trim()) {
      newErrors.title = 'Title is required';
      isValid = false;
    } else if (content.title.trim().length <= 3) {
      newErrors.title = 'Title must be longer than 3 characters';
      isValid = false;
    }

    if (!content.body.trim()) {
      newErrors.body = 'Content is required';
      isValid = false;
    } else if (content.body.trim().length <= 50) {
      newErrors.body = 'Content must be longer than 50 characters';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSave(content);
      handleClose();
    }
  };

  const handleClose = () => {
    setContent({
      title: '',
      body: ''
    });
    setErrors({
      title: '',
      body: ''
    });
    onClose();
  };

  const handleChange = (field) => (e) => {
    setContent(prev => ({
      ...prev,
      [field]: e.target.value
    }));
    setErrors(prev => ({
      ...prev,
      [field]: ''
    }));
  };

  return (
    <dialog id="add_content_modal" className="modal">
      <div className="modal-box relative max-w-3xl max-h-full border border-primary">
        <button
          onClick={handleClose}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          <X className="h-4 w-4" />
        </button>

        <h3 className="font-bold text-lg flex items-center gap-2 mb-6">
          <Save className="h-5 w-5" />
          Add New Content
        </h3>

        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="form-control w-full">
            <input
              type="text"
              placeholder="Enter your title here (minimum 4 characters)..."
              className={`input input-md input-bordered w-full font-medium ${errors.title ? 'input-error' : ''}`}
              value={content.title}
              onChange={handleChange('title')}
            />
            {errors.title && (
              <label className="label">
                <span className="label-text-alt text-error flex items-center gap-1">
                  <AlertCircle className="h-4 w-4" />
                  {errors.title}
                </span>
              </label>
            )}
          </div>

          <div className="form-control w-full">
            <textarea
              placeholder="Enter your content here (minimum 50 characters)..."
              className={`textarea textarea-bordered min-h-[400px] leading-relaxed ${errors.body ? 'textarea-error' : ''}`}
              value={content.body}
              onChange={handleChange('body')}
            />
            {errors.body && (
              <label className="label">
                <span className="label-text-alt text-error flex items-center gap-1">
                  <AlertCircle className="h-4 w-4" />
                  {errors.body}
                </span>
              </label>
            )}
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-success gap-2"
              disabled={isButtonDisabled}
            >
              <Save className="h-5 w-5" />
              Save
            </button>
          </div>
        </form>
      </div>

      <form method="dialog" className="modal-backdrop">
        <button onClick={handleClose}>close</button>
      </form>
    </dialog>
  );
};

export default AddContentModal;
