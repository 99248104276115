// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "@rails/actioncable"
import "./channels"
import "./controllers"

import Toastify from "toastify-js"


Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target)
}

Turbo.StreamActions.toast = function () {
    const text = this.getAttribute("text")
    const duration = Number(this.getAttribute("duration"))
    const gravity = this.getAttribute("gravity")
    const position = this.getAttribute("position")
    const close = this.getAttribute("close") === "true"

    const toast = Toastify({
        text,
        duration,
        gravity,
        position,
        close
    })

    toast.showToast()
}

