import React, { useState } from 'react'
import SettingsModal from './SettingsModal'
import { Loader } from 'lucide-react'

const DownloadModal = ({ isOpen, onClose }) => {
  const [options, setOptions] = useState({
    includeAnalytics: false,
    includeFiles: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    if (!options.includeAnalytics && !options.includeFiles) return;

    setLoading(true);
    setError(null);
    try {
      // Download logic would go here
      throw new Error('Download functionality not implemented');
    } catch (error) {
      console.error('Download failed:', error);
      setError('Failed to download data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsModal isOpen={isOpen} onClose={onClose} title="Download Data">
      <div className="space-y-4">
        <div className="alert">
          <span>Your data will be exported as a ZIP file</span>
        </div>

        {error && (
          <div className="alert alert-error">
            <span>{error}</span>
          </div>
        )}

        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Include analytics data</span>
            <input
              type="checkbox"
              className="checkbox"
              checked={options.includeAnalytics}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                includeAnalytics: e.target.checked
              }))}
            />
          </label>
        </div>

        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text">Include project files</span>
            <input
              type="checkbox"
              className="checkbox"
              checked={options.includeFiles}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                includeFiles: e.target.checked
              }))}
            />
          </label>
        </div>

        <button
          className="btn btn-primary w-full"
          onClick={handleDownload}
          disabled={loading || (!options.includeAnalytics && !options.includeFiles)}
        >
          {loading && <Loader className="w-4 h-4 mr-2 animate-spin" />}
          Download ZIP
        </button>
      </div>
    </SettingsModal>
  );
};

export default DownloadModal;
