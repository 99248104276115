import React, { useState } from 'react';
import {
  FolderOpen,
  Mail,
  Code,
  Radio,
  Share2,
} from 'lucide-react';

const SourceSelector = ({ onSourceChange }) => {
  const [selectedSources, setSelectedSources] = useState([]);

  const sources = [
    {
      id: 'has_public_access',
      label: 'Public Folder',
      icon: <FolderOpen className="h-5 w-5" />
    },
    {
      id: 'has_email_access',
      label: 'Email',
      icon: <Mail className="h-5 w-5" />
    },
    {
      id: 'has_js_access',
      label: 'JS Snippet',
      icon: <Code className="h-5 w-5" />
    },
    {
      id: 'has_api_access',
      label: 'API',
      icon: <Radio className="h-5 w-5" />
    }
  ];

  const handleSourceToggle = (sourceId) => {
    const newSelectedSources = selectedSources.includes(sourceId)
      ? selectedSources.filter(id => id !== sourceId)
      : [...selectedSources, sourceId];

    setSelectedSources(newSelectedSources);
    if (onSourceChange) {
      onSourceChange(newSelectedSources);
    }
  };

  return (
    <div className="relative">
      <div className="flex gap-2">
        <div className="dropdown">
          <label
            tabIndex={0}
            className="btn btn-md btn-circle bg-base-200 hover:bg-base-300"
          >
            {selectedSources.length > 0 ? <Share2 className="h-5 w-5 text-primary" /> : <Share2 className="h-5 w-5 text-gray-500" />}
          </label>
          <div
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow-lg bg-base-100 rounded-box w-52 mt-2 border"
          >
            {sources.map((source) => (
              <label
                key={source.id}
                className="flex items-center px-4 py-2 hover:bg-base-200 rounded-lg cursor-pointer"
              >
                <div className="flex items-center flex-1 gap-3">
                  {source.icon}
                  <span className="text-sm font-medium">{source.label}</span>
                </div>
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm checkbox-primary"
                  checked={selectedSources.includes(source.id)}
                  onChange={() => handleSourceToggle(source.id)}
                />
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceSelector;
