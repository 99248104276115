import React from "react";
import {
  AlertTriangle,
  FileText,
  Fingerprint,
  Layers,
  BarChart2,
  MessageSquare,
  Brain,
  UserCheck,
  Check
} from "lucide-react";

const isValidString = str => typeof str === 'string' && str.trim().length > 0;
const isValidArray = arr => Array.isArray(arr) && arr.length > 0;
const isValidObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);
const safeNumber = num => typeof num === 'number' && !isNaN(num) ? num : 0;

const getTypeIcon = (type) => {
  if (!isValidString(type)) return AlertTriangle;

  const icons = {
    content: FileText,
    pattern: Fingerprint,
    contextual: Layers,
    statistical: BarChart2,
    linguistic: MessageSquare,
    semantic: Brain,
    behavioral: UserCheck
  };
  return icons[type.toLowerCase()] || AlertTriangle;
};

const getTypeColor = (type) => {
  if (!isValidString(type)) return "bg-gray-100 text-gray-800 border-gray-200";

  const colors = {
    content: "bg-blue-100 text-blue-800 border-blue-200",
    pattern: "bg-purple-100 text-purple-800 border-purple-200",
    contextual: "bg-green-100 text-green-800 border-green-200",
    statistical: "bg-yellow-100 text-yellow-800 border-yellow-200",
    linguistic: "bg-pink-100 text-pink-800 border-pink-200",
    semantic: "bg-indigo-100 text-indigo-800 border-indigo-200",
    behavioral: "bg-orange-100 text-orange-800 border-orange-200"
  };
  return colors[type.toLowerCase()] || "bg-gray-100 text-gray-800 border-gray-200";
};

const formatAnomalyText = (text) => {
  if (!isValidString(text)) return "Unknown Anomaly";
  return text.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const AnomalyTypeSection = ({ type, anomalies, total }) => {
  if (!isValidString(type) || !isValidArray(anomalies) || !total) return null;

  const TypeIcon = getTypeIcon(type);
  const safeTotal = safeNumber(total);
  const percentage = safeTotal > 0 ? ((anomalies.length / safeTotal) * 100).toFixed(0) : 0;

  return (
    <div className="rounded-lg border bg-base-100 p-4 shadow-sm">
      <div className="flex items-start gap-4">
        <div className={`p-2 rounded-lg ${getTypeColor(type)}`}>
          <TypeIcon className="w-4 h-4" />
        </div>

        <div className="flex-grow">
          <div className="flex justify-between items-center mb-2">
            <div>
              <h3 className="font-medium capitalize">{formatAnomalyText(type)}</h3>
              <p className="text-sm text-base-content/60">
                {anomalies.length} detected ({percentage}%)
              </p>
            </div>
          </div>

          <div className="mt-3 space-y-2">
            {anomalies.map((anomaly, index) => (
              <div
                key={`${type}-${index}`}
                className="flex items-center gap-2 p-2 rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors"
              >
                <AlertTriangle className="w-4 h-4 text-base-content/60" />
                <span className="text-sm">{formatAnomalyText(anomaly)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const AnomaliesResultAnomalies = ({ anomalies }) => {
  if (!isValidObject(anomalies)) return null;

  const {
    detected = false,
    count = 0,
    types = {}
  } = anomalies;

  const validTypes = Object.entries(types)
    .filter(([_, items]) => isValidArray(items))
    .sort(([_, a], [__, b]) => b.length - a.length);

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <AlertTriangle className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Anomalies Summary</h2>
          </div>

          <div className="flex items-center gap-2">
            <span className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg ${detected ? 'bg-error/10 text-error' : 'bg-success/10 text-success'
              }`}>
              {detected ? (
                <>
                  <AlertTriangle className="w-4 h-4" />
                  <span className="font-medium">{count} Anomalies Detected</span>
                </>
              ) : (
                <>
                  <Check className="w-4 h-4" />
                  <span className="font-medium">No Anomalies</span>
                </>
              )}
            </span>
          </div>
        </div>

        {detected && validTypes.length > 0 && (
          <div className="grid gap-4">
            {validTypes.map(([type, items]) => (
              <AnomalyTypeSection
                key={type}
                type={type}
                anomalies={items}
                total={count}
              />
            ))}
          </div>
        )}

        {!detected && (
          <div className="flex items-center justify-center p-8 bg-base-200/50 rounded-lg">
            <div className="flex items-center gap-2 text-success">
              <Check className="w-5 h-5" />
              <span className="font-medium">No anomalies detected in the analysis</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnomaliesResultAnomalies;
