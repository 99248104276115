import React, { createContext, useContext, useEffect, useState } from 'react';

const TextSelectionContext = createContext(null);

export const TextSelectionProvider = ({ children }) => {
  const [selection, setSelection] = useState({
    text: '',
    position: { x: 0, y: 0 },
    isVisible: false,
    isDropdownOpen: false
  });

  useEffect(() => {
    const handleSelection = () => {
      const windowSelection = window.getSelection();
      const selectedText = windowSelection?.toString() || '';

      if (selectedText.length > 0) {
        const range = windowSelection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        setSelection({
          text: selectedText,
          position: {
            // Change to use left position instead of right
            x: rect.left + window.scrollX - 30, // Offset to the left
            y: rect.top + window.scrollY - 30
          },
          isVisible: true,
          isDropdownOpen: false
        });
      } else {
        setSelection(prev => ({
          ...prev,
          isVisible: false,
          text: '',
          isDropdownOpen: false
        }));
      }
    };

    const handleClickOutside = (e) => {
      if (!window.getSelection()?.toString()) {
        setSelection(prev => ({
          ...prev,
          isVisible: false,
          text: '',
          isDropdownOpen: false
        }));
      }
    };

    document.addEventListener('mouseup', handleSelection);
    document.addEventListener('selectionchange', handleSelection);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleSelection);
      document.removeEventListener('selectionchange', handleSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setSelection(prev => ({
      ...prev,
      isDropdownOpen: !prev.isDropdownOpen
    }));
  };

  return (
    <TextSelectionContext.Provider value={{ ...selection, toggleDropdown }}>
      {children}
    </TextSelectionContext.Provider>
  );
};

export const useTextSelection = () => {
  return useContext(TextSelectionContext);
};
