import React from 'react';
import { Calendar } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus'
import { RotateCcw } from "lucide-react";
import AIAgentCard from '../../common/AIAgentCard'
const DatesDisplay = ({ found, standardized }) => {
  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Calendar className="w-4 h-4" />
          Extracted Dates
        </h3>
        <div className="overflow-x-auto">
          <table className="table table-zebra table-sm w-full">
            <thead>
              <tr>
                <th>Original</th>
                <th>Standardized</th>
              </tr>
            </thead>
            <tbody>
              {found?.map((date, index) => (
                <tr key={index}>
                  <td className="text-sm">{date}</td>
                  <td className="font-mono text-sm text-primary">
                    {standardized[index]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const DateExtractionResults = ({ dateExtractions, onRerun }) => {
  if (!dateExtractions) return null;

  const { status, result: { dates }, agent_name } = dateExtractions;

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={agent_name} />
            </p>
            <ResultStatus status={status} />
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun('dateExtraction')}
              className="btn btn-sm btn-outline btn-primary">
              Rerun
              <RotateCcw />
            </button>
            <CopyButton
              text={JSON.stringify(dates?.found)}
              copyKey={'date-extraction'}
            />
          </div>
        </div>

        <div className="space-y-4">
          {dates?.found?.length > 0 && (
            <DatesDisplay
              found={dates?.found}
              standardized={dates?.standardized}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateExtractionResults;
