import React from "react";
import { BarChart2, CheckCircle2, AlertCircle, ChartBar, ListChecks } from "lucide-react";

const ScoreBar = ({ value, label, colorClass = "bg-primary" }) => {
  if (typeof value !== 'number' || isNaN(value)) return null;

  const formatValue = (val) => {
    const normalizedValue = val > 1 ? val : val * 100;
    return Math.max(0, Math.min(100, normalizedValue)).toFixed(0);
  };

  const getWidthPercentage = (val) => {
    const percentage = val > 1 ? val : val * 100;
    return Math.max(0, Math.min(100, percentage));
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center text-sm">
        <span className="text-base-content/60">{label}</span>
        <span className="font-medium">{formatValue(value)}%</span>
      </div>
      <div className="h-2 bg-base-200 rounded-full overflow-hidden">
        <div
          className={`h-full ${colorClass} rounded-full transition-all`}
          style={{ width: `${getWidthPercentage(value)}%` }}
        />
      </div>
    </div>
  );
};

const CategoryScores = ({ categories }) => {
  if (!categories || typeof categories !== 'object') return null;

  const getScoreColor = (score) => {
    if (!score && score !== 0) return "bg-error";
    if (score >= 0.9) return "bg-success";
    if (score >= 0.7) return "bg-warning";
    return "bg-error";
  };

  return (
    <div className="grid gap-3">
      {Object.entries(categories).map(([category, score]) => (
        <ScoreBar
          key={`category-${category}`}
          label={category.replace(/_/g, ' ')}
          value={score}
          colorClass={getScoreColor(score)}
        />
      ))}
    </div>
  );
};

const MetadataResultQualityMetricsResult = ({ qualityMetrics }) => {
  if (!qualityMetrics?.completeness || !qualityMetrics?.confidence) return null;

  const { completeness, confidence } = qualityMetrics;
  const hasValidScore = typeof completeness.score === 'number' && !isNaN(completeness.score);
  const hasValidConfidence = typeof confidence.average === 'number' && !isNaN(confidence.average);

  if (!hasValidScore || !hasValidConfidence) return null;

  const getQualityAssessment = (score) => {
    if (score >= 90) return "excellent";
    if (score >= 70) return "good";
    return "needs improvement";
  };

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg">
      <div className="space-y-8">
        <div className="flex items-center gap-2">
          <ChartBar className="w-5 h-5" />
          <h2 className="text-lg font-semibold">Quality Metrics</h2>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <ListChecks className="w-4 h-4 text-primary" />
              </div>
              <h3 className="font-medium">Completeness</h3>
            </div>

            <div className="p-4 rounded-lg bg-base-200/50">
              <ScoreBar label="Completeness Score" value={completeness.score} />
            </div>

            {Array.isArray(completeness.missing_fields) && completeness.missing_fields.length > 0 && (
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-base-content/60">
                  <AlertCircle className="w-4 h-4" />
                  <span>Missing Fields</span>
                </div>
                <div className="flex flex-wrap gap-2">
                  {completeness.missing_fields.map((field, index) => (
                    <span
                      key={`field-${index}`}
                      className="px-2 py-1 rounded-md text-xs bg-base-200 capitalize"
                    >
                      {field.replace(/_/g, ' ')}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <div className="p-2 rounded-lg bg-primary/10">
                <BarChart2 className="w-4 h-4 text-primary" />
              </div>
              <h3 className="font-medium">Confidence</h3>
            </div>

            <div className="p-4 rounded-lg bg-base-200/50">
              <div className="mb-4">
                <div className="flex items-center gap-2 mb-2">
                  <CheckCircle2 className="w-4 h-4 text-success" />
                  <span className="text-sm font-medium">Average Confidence</span>
                </div>
                <ScoreBar
                  label="Overall Score"
                  value={confidence.average}
                  colorClass="bg-success"
                />
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2 mb-2">
                  <ChartBar className="w-4 h-4 text-primary" />
                  <span className="text-sm font-medium">By Category</span>
                </div>
                <CategoryScores categories={confidence.by_category} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-start gap-3 p-4 rounded-lg bg-base-200/50">
          <CheckCircle2 className="w-4 h-4 mt-0.5 text-success" />
          <div className="text-sm text-base-content/70">
            <span className="font-medium text-success">Overall Quality Assessment: </span>
            Data quality is {getQualityAssessment(completeness.score)}
            with {(confidence.average * 100).toFixed(0)}% average confidence across categories.
            {Array.isArray(completeness.missing_fields) && completeness.missing_fields.length > 0 &&
              ` Consider adding ${completeness.missing_fields.length} missing fields for improved completeness.`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetadataResultQualityMetricsResult;
