import React from "react";
import { TagIcon, BarChart2 } from "lucide-react";

const TagResult = ({ tag }) => {
  if (!tag?.value) return null;

  const getConfidenceColor = (confidence) => {
    if (!confidence && confidence !== 0) return 'bg-error/10 text-error';
    if (confidence >= 0.9) return 'bg-success/10 text-success';
    if (confidence >= 0.7) return 'bg-warning/10 text-warning';
    return 'bg-error/10 text-error';
  };

  const confidencePercentage = typeof tag.confidence === 'number'
    ? Math.max(0, Math.min(100, tag.confidence * 100)).toFixed(1)
    : '0.0';

  return (
    <div className="inline-flex items-center p-3 gap-4 bg-base-100 rounded-lg shadow-lg border border-primary">
      <div className="flex items-center gap-2">
        <div className="p-2 rounded-lg bg-primary/10">
          <TagIcon className="w-4 h-4 text-primary" />
        </div>
        <div>
          <p className="text-sm font-medium text-base-content/60">Tag</p>
          <p className="text-lg font-semibold capitalize">{String(tag.value)}</p>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <div className={`flex items-center gap-2 px-3 py-1.5 rounded-lg ${getConfidenceColor(tag.confidence)}`}>
          <BarChart2 className="w-4 h-4" />
          <span className="font-medium">{confidencePercentage}% Confidence</span>
        </div>
      </div>
    </div>
  );
};

export default TagResult;
