import React from 'react'
import { AuthProvider } from '../contexts/AuthContext'
import { ParamsProvider } from '../contexts/ParamsProvider'
import { UpdatesProvider } from '../contexts/UpdatesContext'
import Projects from './Projects'
import Project from './Project'
import Header from './Header'
import Content from './Content'
import Plans from './Plans'
import CheckoutPage from './CheckoutPage'
import Settings from './Settings'
import Dashboard from './Dashboard'
import { TextSelectionProvider } from '../contexts/TextSelectionContext'

const App = ({
  currentPath,
  currentUser,
  routes,
  stripePublishableKey,
  appLogo,
}) => {
  const { controller, action, params } = currentPath
  console.table(params)
  console.log(controller, action)
  const renderContent = () => {
    if (controller === 'projects') {
      switch (action) {
        case 'index':
          return <Projects projectsURL={routes.projectsPath} />
        case 'show':
          return <Project
            baseUrl={routes.baseUrl}
            projectsURL={routes.projectsPath}
            contentWidgetServerId={routes.contentWidgetServerId}
            contentWidgetServerUrl={routes.contentWidgetServerUrl}
            contentWidgetServerJs={routes.contentWidgetServerJs}
          />
        default:
          return <></>
      }
    }
    if (controller === 'contents') {
      switch (action) {
        case 'show':
          return <Content />
        default:
          return <></>
      }
    }
    if (controller === 'subscriptions') {
      switch (action) {
        case 'new':
          return <Plans newUserCheckoutsPath={routes.newUserCheckoutsPath} />
        default:
          return <></>
      }
    }
    if (controller === 'checkouts') {
      switch (action) {
        case 'new':
          return <CheckoutPage
            subscriptionsPath={routes.subscriptionsPath}
            stripePublishableKey={stripePublishableKey}
          />
        default:
          return <></>
      }
    }
    if (controller === 'dashboard') {
      switch (action) {
        case 'settings':
          return <Settings accountStatsPath={routes.accountStatsPath} />
        case 'show':
          return <Dashboard
            projectsURL={routes.projectsPath}
            projectsStatsPath={routes.projectsStatsPath}
            integrationStatsPath={routes.integrationStatsPath}
            agentUsageStatsPath={routes.agentUsageStatsPath}
            contentTimelineStatsPath={routes.contentTimelineStatsPath}
            usageLimitsStatsPath={routes.usageLimitsStatsPath}
          />
        default:
          return <></>
      }
    }
  }

  return (
    <AuthProvider currentUser={currentUser}>
      <UpdatesProvider>
        <TextSelectionProvider>
          <ParamsProvider params={params}>
            <div>
              <Header
                dashboardPath={routes.dashboardPath}
                accountStatsPath={routes.accountStatsPath}
                appLogo={appLogo}
                logoutPath={routes.logoutPath}
                userPortalsPath={routes.userPortalsPath}
                settingsPath={routes.settingsPath}
                projectsURL={routes.projectsPath}
                latestActivitiesPath={routes.latestActivitiesPath}
                newUserSubscriptionsPath={routes.newUserSubscriptionsPath}
              />
              {renderContent()}
            </div>
          </ParamsProvider>
        </TextSelectionProvider>
      </UpdatesProvider>
    </AuthProvider>
  )
}

export default App
