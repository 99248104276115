import { File, Trash2 } from "lucide-react";
import SourceDisplay from "./SourceDisplay";
import React from "react";
import utils from "../../../utils";
import QuickResultView from "../../common/QuickResultView";
const ContentRow = ({ item, currentUser, project, onDelete }) => {
  const { formatDate } = utils
  return (
    <tr className="hover:bg-base-100">
      <td className="py-3">
        <div className="flex items-center gap-2">
          <div className="text-base-content">
            <File className="h-4 w-4" />
          </div>
          <a
            href={`/users/${currentUser?.id}/projects/${project?.id}/contents/${item?.id}`}
            className="link link-primary"
          >
            {item?.title}
          </a>
        </div>
      </td>
      <td className="py-3">{formatDate(item?.created_at)}</td>
      <td className="py-3">
        <QuickResultView quickAgentsResults={item?.quick_agents_results} />
      </td>
      <td className="py-3">
        <div className="flex items-center gap-1 text-primary">
          <SourceDisplay sourceType={item?.source} />
        </div>
      </td>
      <td className="py-3">
        <button
          onClick={() => onDelete(item)}
          className="btn btn-sm btn-error btn-outline gap-2">
          <Trash2 className="h-4 w-4" />
          Delete
        </button>
      </td>
    </tr>
  );
};


export default ContentRow
