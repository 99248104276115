import React from 'react';
import { Sparkles, Tags, List, Heart, FileText, AlertTriangle, User, Database, Lightbulb, Calendar, Lock, Copy, Smile, ShoppingCart, MapPin, MailQuestion, Link, ChartBar, Users, Clock, CalendarClock, Hospital } from 'lucide-react';

const QuickResultView = ({ quickAgentsResults }) => {
  const statusMap = {
    has_tags: quickAgentsResults?.tagging,
    has_category: quickAgentsResults?.categorization,
    has_sentiment: quickAgentsResults?.sentiment_analysis,
    has_summary: quickAgentsResults?.summary,
    has_anomaly_detector: quickAgentsResults?.anomaly_detection,
    has_named_entity_recognition: quickAgentsResults?.named_entity,
    has_metadata_extractor: quickAgentsResults?.metadata_extraction,
    has_actionable_insights: quickAgentsResults?.actionable_insight,
    has_date_extraction: quickAgentsResults?.date_extraction,
    has_pii_detection: quickAgentsResults?.pii_detection,
    has_duplicate_detection: quickAgentsResults?.duplicate_detection,
    has_emotion: quickAgentsResults?.emotion,
    has_product_categorization: quickAgentsResults?.product_categorization,
    has_address_validation: quickAgentsResults?.address_validation,
    has_spam_extraction: quickAgentsResults?.spam_extraction,
    has_citation_detector: quickAgentsResults?.citation_detector,
    has_kpi_extractor: quickAgentsResults?.kpi_extractor,
    has_competitor_mention_detector: quickAgentsResults?.competitor_mention_detector,
    has_urgency_detector: quickAgentsResults?.urgency_detector,
    has_deadline_detector: quickAgentsResults?.deadline_detector,
    has_medical_term_extractor: quickAgentsResults?.medical_term_extractor
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'success':
        return 'text-green-500';
      case 'error':
        return 'text-red-500';
      default:
        return 'text-gray-400';
    }
  };

  const agents = [
    { id: 'has_tags', label: 'Tag Detection', icon: Tags },
    { id: 'has_category', label: 'Categorization', icon: List },
    { id: 'has_sentiment', label: 'Sentiment Analysis', icon: Heart },
    { id: 'has_summary', label: 'Summarization', icon: FileText },
    { id: 'has_anomaly_detector', label: 'Anomaly Detection', icon: AlertTriangle },
    { id: 'has_named_entity_recognition', label: 'Entity Recognition', icon: User },
    { id: 'has_metadata_extractor', label: 'Metadata Extraction', icon: Database },
    { id: 'has_actionable_insights', label: 'Actionable Insights', icon: Lightbulb },
    { id: 'has_date_extraction', label: 'Date Extraction', icon: Calendar },
    { id: 'has_pii_detection', label: 'PII Detection', icon: Lock },
    { id: 'has_duplicate_detection', label: 'Duplicate Detection', icon: Copy },
    { id: 'has_emotion', label: 'Emotion Detection', icon: Smile },
    { id: 'has_product_categorization', label: 'Product Categorization', icon: ShoppingCart },
    { id: 'has_address_validation', label: 'Address Validation', icon: MapPin },
    { id: 'has_spam_extraction', label: 'Spam Detection', icon: MailQuestion },
    { id: 'has_citation_detector', label: 'Citation Detection', icon: Link },
    { id: 'has_kpi_extractor', label: 'KPI Extraction', icon: ChartBar },
    { id: 'has_competitor_mention_detector', label: 'Competitor Mention Detection', icon: Users },
    { id: 'has_urgency_detector', label: 'Urgency Detection', icon: Clock },
    { id: 'has_deadline_detector', label: 'Deadline Detection', icon: CalendarClock },
    { id: 'has_medical_term_extractor', label: 'Medical Term Extraction', icon: Hospital }
  ];

  const successCount = Object.values(statusMap).filter(status => status === 'success').length;

  return (
    <div className="relative">
      <div className="flex gap-2">
        <div className="dropdown dropdown-left">
          <label
            tabIndex={0}
            className="btn btn-md btn-circle bg-base-200 hover:bg-base-300"
            aria-label="View AI Agent Results"
            role="button"
          >
            <Sparkles
              className={`h-5 w-5 ${successCount > 0 ? 'text-primary' : 'text-gray-500'}`}
              aria-hidden="true"
            />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-30 menu p-2 shadow-lg bg-base-100 rounded-box w-72 mt-2 border"
            role="listbox"
            aria-label="AI Agent Results"
          >
            {agents?.map((agent) => {
              const IconComponent = agent?.icon;
              const status = statusMap[agent?.id];
              const colorClass = getStatusColor(status);
              const statusText = status === 'success' ? 'Success' : status === 'error' ? 'Error' : 'Unknown';

              return (
                <li key={agent?.id} role="option">
                  <div className="flex items-center px-4 py-2 hover:bg-base-200 rounded-lg">
                    <div className="flex items-center flex-1 gap-3">
                      <IconComponent className={`h-5 w-5 ${colorClass}`} aria-hidden="true" />
                      <span className="text-sm font-medium">{agent?.label}</span>
                    </div>
                    <span className={`text-xs ${colorClass}`}>{statusText}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuickResultView;
