import React from 'react';
import { BookOpen, Network, RotateCcw, PieChart } from 'lucide-react';
import CopyButton from "../../../common/CopyButton";
import ResultStatus from '../../common/ResultStatus';
import AIAgentCard from '../../common/AIAgentCard';

// Helper function to safely access nested data
const getNestedData = (obj, path) => {
  return path.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
};

const TermsDisplay = ({ terms = [] }) => {
  if (!Array.isArray(terms) || terms.length === 0) return null;

  const getBadgeColor = (confidence) => {
    const score = parseFloat(confidence);
    if (isNaN(score)) return 'neutral';
    if (score >= 0.8) return 'success';
    if (score >= 0.5) return 'warning';
    return 'error';
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <BookOpen className="w-4 h-4" />
          Medical Terms Found
        </h3>
        <div className="space-y-4">
          {terms.map((term, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <span className="badge badge-neutral capitalize">
                    {term?.category || 'Uncategorized'}
                  </span>
                  <span className="badge badge-outline">
                    {term?.details?.specialty || 'General'}
                  </span>
                </div>
                <span className={`badge badge-${getBadgeColor(term?.confidence)}`}>
                  {term?.confidence ?
                    `${(term.confidence * 100).toFixed(1)}% Confidence` :
                    'No Score'}
                </span>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-medium">{term?.term || 'Unknown Term'}</p>
                  {term?.details?.common_name && (
                    <span className="text-xs text-base-content/70">
                      (Common: {term.details.common_name})
                    </span>
                  )}
                </div>
                {term?.details?.definition && (
                  <p className="text-sm text-base-content/80">
                    {term.details.definition}
                  </p>
                )}
                {term?.details?.related_terms && term.details.related_terms.length > 0 && (
                  <div>
                    <p className="text-xs text-base-content/70">Related Terms:</p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {term.details.related_terms.map((relatedTerm, idx) => (
                        <span key={idx} className="badge badge-sm badge-ghost">
                          {relatedTerm}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const RelationshipsDisplay = ({ relationships = [] }) => {
  if (!Array.isArray(relationships) || relationships.length === 0) return null;

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <Network className="w-4 h-4" />
          Term Relationships
        </h3>
        <div className="space-y-4">
          {relationships.map((relation, index) => (
            <div key={index} className="bg-base-300 rounded-lg p-3">
              <div className="space-y-2">
                <div>
                  <span className="text-xs text-base-content/70">Primary Term</span>
                  <p className="text-sm font-medium">{relation?.primary_term}</p>
                </div>
                <div>
                  <span className="text-xs text-base-content/70">Relationship Type</span>
                  <div className="mt-1">
                    <span className="badge badge-primary">
                      {relation?.relationship_type || 'Unknown'}
                    </span>
                  </div>
                </div>
                {relation?.related_terms && relation.related_terms.length > 0 && (
                  <div>
                    <span className="text-xs text-base-content/70">Related Terms:</span>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {relation.related_terms.map((term, idx) => (
                        <span key={idx} className="badge badge-sm badge-ghost">
                          {term}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetadataDisplay = ({ metadata }) => {
  if (!metadata?.extraction_metrics) return null;

  const getContextClarityColor = (clarity) => {
    switch (clarity) {
      case 'high': return 'success';
      case 'medium': return 'warning';
      case 'low': return 'error';
      default: return 'neutral';
    }
  };

  return (
    <div className="card bg-base-200 shadow-lg">
      <div className="card-body p-4">
        <h3 className="card-title text-sm flex items-center gap-2">
          <PieChart className="w-4 h-4" />
          Extraction Metrics
        </h3>
        <div className="space-y-3">
          <div>
            <p className="text-xs text-base-content/70">Confidence Score</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-primary rounded-full"
                style={{ width: `${(metadata.extraction_metrics.confidence_score || 0) * 100}%` }}
              />
            </div>
          </div>
          <div>
            <p className="text-xs text-base-content/70">Medical Accuracy</p>
            <div className="w-full h-2 bg-base-300 rounded-full">
              <div
                className="h-full bg-secondary rounded-full"
                style={{ width: `${(metadata.extraction_metrics.medical_accuracy || 0) * 100}%` }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm">Context Clarity:</span>
            <span className={`badge badge-${getContextClarityColor(metadata.extraction_metrics.context_clarity)}`}>
              {metadata.extraction_metrics.context_clarity || 'unknown'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MedicalTermExtractionResults = ({ medicalTermExtraction, onRerun }) => {
  // Helper function to get the analysis data regardless of structure
  const getAnalysisData = () => {
    const paths = [
      ['result', 'response', 'medical_term_analysis'],
      ['result', 'medical_term_analysis']
    ];

    for (const path of paths) {
      const data = getNestedData(medicalTermExtraction, path);
      if (data) return data;
    }
    return null;
  };

  // Helper function to get metadata
  const getMetadata = () => {
    const paths = [
      ['result', 'response', 'metadata'],
      ['result', 'metadata']
    ];

    for (const path of paths) {
      const data = getNestedData(medicalTermExtraction, path);
      if (data) return data;
    }
    return null;
  };

  // Helper function to get status
  const getStatus = () => {
    const paths = [
      ['result', 'response', 'status'],
      ['status']
    ];

    for (const path of paths) {
      const status = getNestedData(medicalTermExtraction, path);
      if (status) return status;
    }
    return 'unknown';
  };

  const analysisData = getAnalysisData();
  const metadata = getMetadata();

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body p-6">
        <div className="flex items-center justify-between mb-4 gap-2">
          <div>
            <p className="text-sm font-medium text-base-content/60 inline-flex items-center gap-2">
              <AIAgentCard agent_name={medicalTermExtraction?.agent_name || 'Unknown Agent'} />
            </p>
            <div className="flex items-center gap-2">
              <ResultStatus status={getStatus()} />
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <button
              onClick={() => onRerun?.('medicalTermExtraction')}
              className="btn btn-sm btn-outline btn-primary"
            >
              Rerun
              <RotateCcw className="w-4 h-4" />
            </button>
            <CopyButton
              text={JSON.stringify(medicalTermExtraction?.result || {})}
              copyKey="medical-term-extraction"
            />
          </div>
        </div>

        {analysisData && (
          <div className="space-y-4">
            <TermsDisplay terms={analysisData.terms_found} />
            <RelationshipsDisplay relationships={analysisData.term_relationships} />
            <MetadataDisplay metadata={metadata} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicalTermExtractionResults;
