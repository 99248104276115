import React from "react";
import { Lightbulb, ListChecks, Building2, Network, ArrowRight } from "lucide-react";

const AnalysisSection = ({ icon: Icon, title, items, description }) => {
  if (!Array.isArray(items) || items.length === 0) return null;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2 mb-1">
        <div className="p-2 rounded-lg bg-primary/10">
          <Icon className="w-4 h-4 text-primary" />
        </div>
        <h3 className="font-semibold">{title}</h3>
      </div>
      <div className="grid gap-2">
        {items.map((item, index) => (
          <div
            key={`${title}-${index}-${String(item).slice(0, 10)}`}
            className="flex items-start gap-2 p-3 rounded-lg bg-base-200/50 hover:bg-base-200 transition-colors"
          >
            <ArrowRight className="w-4 h-4 mt-0.5 text-primary" />
            <span className="text-sm">{String(item)}</span>
          </div>
        ))}
      </div>
      {description && (
        <p className="text-sm text-base-content/60">{description}</p>
      )}
    </div>
  );
};

const AnalysisResult = ({ analysis }) => {
  if (!analysis) return null;

  const sections = [
    {
      title: "Key Themes",
      icon: Lightbulb,
      items: analysis.key_themes,
      description: "Primary topics and themes identified in the content"
    },
    {
      title: "Main Points",
      icon: ListChecks,
      items: analysis.main_points,
      description: "Essential information and key takeaways"
    },
    {
      title: "Important Entities",
      icon: Building2,
      items: analysis.important_entities,
      description: "Organizations and key stakeholders mentioned"
    },
    {
      title: "Critical Relationships",
      icon: Network,
      items: analysis.critical_relationships,
      description: "Key connections and dependencies identified"
    }
  ];

  const hasValidSections = sections.some(section =>
    Array.isArray(section.items) && section.items.length > 0
  );

  if (!hasValidSections) return null;

  return (
    <div className="p-6 rounded-lg bg-base-100 shadow-lg border border-base-200">
      <div className="grid gap-8">
        {sections.map((section, index) => (
          <AnalysisSection
            key={`section-${index}`}
            icon={section.icon}
            title={section.title}
            items={section.items}
            description={section.description}
          />
        ))}
      </div>
    </div>
  );
};

export default AnalysisResult;
